/* eslint-disable prefer-destructuring */
import { useEffect, useState, useContext } from 'react';
import createStore from 'ctx-provider';
import addressMatch from 'utils/address-match';
import navigatorCtx from 'context/navigator';
import tokenListCtx from 'context/tokenlist';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import { PAGES_NAMES } from 'config/constants';
import { isEqual } from 'lodash';

const useSelectedToken = () => {
	const { setPage } = useContext(navigatorCtx);
	const { data: tokenList } = useContext(tokenListCtx);
	const { chainId, address } = useContext(cryptowalletCtx);
	const [selectedToken, setSelectedToken] = useState();

	const removeSelectedToken = () => {
		setSelectedToken(null);
		setPage(PAGES_NAMES.HOME);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(removeSelectedToken, [chainId, address]);

	const setTokenAddress = address => {
		if (!address) {
			removeSelectedToken();
		} else {
			const newToken = tokenList.find(t => t.address === address);
			if (newToken && (!selectedToken || !isEqual(selectedToken, newToken))) {
				// as a new Object, to force rerendering
				setSelectedToken({ ...newToken });
			} else {
				setPage(PAGES_NAMES.HOME);
			}
		}
	};

	useEffect(() => {
		if (selectedToken && tokenList && tokenList.length > 0) {
			let currentSelectedToken;
			if (tokenList && tokenList.length > 0) {
				currentSelectedToken = tokenList.find(t =>
					addressMatch(t.address, selectedToken.address),
				);
			}
			if (!currentSelectedToken) {
				removeSelectedToken();
			} else {
				setSelectedToken(currentSelectedToken);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenList]);

	return {
		selectedToken,
		setTokenAddress,
	};
};

const store = createStore(useSelectedToken);

export const { Provider } = store;
export default store.ctx;
