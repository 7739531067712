import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		position: 'fixed',
		left: 0,
		top: 0,
		paddingTop: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	logo: {
		height: '65px',
	},
}));
