import React from 'react';
import { WalletConnect, Link, IconButton } from '@itsa.io/ui';
import {
	Twitter as TwitterIcon,
	YouTube as YouTubeIcon,
} from '@itsa.io/ui/icons';
import { useIntl } from '@itsa.io/web3utils';
import { TWITTER_URL, YOUTUBE_URL } from 'config/constants';
import useStyles from 'styles/pages/AuthenticationPage';

const AuthenticationPage = () => {
	const classes = useStyles();
	const { t } = useIntl();

	const walletConnect = (
		<WalletConnect
			classes={{
				root: classes.metamaskRoot,
				title: classes.metamaskTitle,
				titleNetwork: classes.metamaskTitleNetwork,
				description: classes.metamaskDescription,
				networkIconConnected: classes.metamaskIcon,
				addressDescription: classes.metamaskAddressDescription,
				iconBox: classes.metamaskIconBox,
				iconBoxInner: classes.metamaskIconBoxInner,
				select: classes.metamaskSelect,
				selectRoot: classes.metamaskSelectRoot,
			}}
		/>
	);

	const iconsContent = (
		<div className={classes.iconButtonContainer}>
			<h6 className={classes.iconText}>
				{t('page.authenticationpage.visit_social_media')}
			</h6>
			<div className={classes.socialMedial}>
				<Link
					component={IconButton}
					className={classes.iconButton}
					href={TWITTER_URL}
					size="small"
					target="_blank"
					rel="noreferrer noopener"
				>
					<TwitterIcon className={classes.twitterIcon} />
				</Link>
				<Link
					component={IconButton}
					className={classes.iconButton}
					href={YOUTUBE_URL}
					size="small"
					target="_blank"
					rel="noreferrer noopener"
				>
					<YouTubeIcon className={classes.youtubeIcon} />
				</Link>
			</div>
		</div>
	);

	return (
		<>
			<div className={classes.root}>
				{walletConnect}
				<hr className={classes.separateLine} />
				{iconsContent}
			</div>
		</>
	);
};

export default AuthenticationPage;
