import { utils } from 'web3';
import { toBN, later } from '@itsa.io/web3utils';
import { delay } from '@itsa.io/web3utils/lib/timers';
import { getWeb3, getWeb3ws, getWeb3EnergiNode } from 'utils/get-web3';
import getBlockHeight from 'utils/get-blockheight';
import { getCurrentPayout } from 'utils/smartcontracts/energi-registry';
import {
	BN_ZERO,
	IS_ENERGI_CHAINID,
	BN_1MNRG,
	NETWORK_NAMES,
} from 'config/constants';
import getAnnouncement from 'api/energi-masternode-announcement';
import { getMasternodeStatus } from 'utils/get-masternode-status';
import addressMatch from 'utils/address-match';

const nextPayout = (address, masternodeList, currentPayout) => {
	// A: find the index of the selected MN using it's owner address
	const mnIndex = masternodeList.findIndex(e => addressMatch(e.owner, address));

	// B: calculate the sum of all masternode collaterals asides that of the selected MN
	const collateralSum = masternodeList
		.filter(({ isActive }, index) => isActive && index < mnIndex)
		.reduce((sum, obj) => sum.add(obj.collateral), toBN('0'));

	// D: Find amount paid to currently rewarded masternode with the percentage calculated
	const amountPaidToRewardedMN = currentPayout * 1000;
	const amountPaidToRewardedMnBN = toBN(
		utils.toWei(amountPaidToRewardedMN.toString(), 'ether'),
	);

	// E: deduct amount paid already to first MN from sum calculated in comment (B)
	const amountLeft = collateralSum.sub(amountPaidToRewardedMnBN);

	// F: divide by 1000, as portions and divide portions by 10 to get how many blocks required before MN is rewarded
	const portionBN = amountLeft.div(BN_1MNRG);
	const portions = parseInt(portionBN.toString(), 10);
	const numberOfBlocks = Math.ceil(portions / 10);
	return numberOfBlocks;
};
class CoinInfo {
	constructor() {
		this.callbacks = [];
		this.init();
	}

	init() {
		const instance = this; // optimize for minifying
		instance.balance = BN_ZERO;
		instance.collateral = BN_ZERO; // Energi Masternode Balance
		instance.loading = true;
		instance.blockheight = 0; // enable to catch blockheight change
		instance.masternodeList = [];
		instance.masternodeVersionRequired = null;
		instance.masternodeVersionRequired = null;
		instance.masternodeNextPayoutBlock = null;
	}

	correctBlockNr(web3) {
		const instance = this; // optimize for minifying
		const { chainId } = instance;
		if (!chainId) {
			return Promise.resolve();
		}
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async resolve => {
			let blockheight = await web3.eth.getBlockNumber();
			const initialBlockheight = blockheight;
			while (
				blockheight < instance.blockheight &&
				blockheight === initialBlockheight
			) {
				await delay(250);
				blockheight = await web3.eth.getBlockNumber();
			}
			if (blockheight < instance.blockheight) {
				blockheight = instance.blockheight;
			}
			resolve();
		});
	}

	async reloadWalletBalance() {
		const instance = this; // optimize for minifying
		const { chainId, address, masternodeList } = instance;
		if (chainId && address) {
			try {
				let web3;
				if (instance.web3) {
					// use wss instance
					web3 = instance.web3;
					await instance.correctBlockNr(web3); // make sure we read from the most recent blocknr
				} else {
					web3 = getWeb3(chainId);
				}

				let coinBalance;
				let changed;

				if (IS_ENERGI_CHAINID(chainId)) {
					const data = await Promise.all([
						web3.eth.getBalance(address),
						web3.masternode.collateralBalance(address),
						web3.masternode.masternodeInfo(address), // lookup by owner
						instance.blockheight
							? getAnnouncement(
									chainId,
									address,
									instance.blockheight - 10, // inspect last 10 blocks
							  )
							: Promise.resolve(),
					]);

					// eslint-disable-next-line prefer-destructuring
					coinBalance = data[0];
					// eslint-disable-next-line prefer-destructuring
					const collateral = data[1] ? data[1].balance : '0';
					const masternodeInfo = instance.newMasternodeInfoDef
						? instance.masternodeInfo
						: data[2]; // keep previous instance.newMasternodeInfoDef is it is there
					const masternodeAnnouncement = data[3];
					instance.newMasternodeInfoDef = false; // reset
					instance.isMasternode = !!masternodeInfo;
					instance.masternodeInfo = masternodeInfo || null;
					instance.masternodeStatus = getMasternodeStatus(
						address,
						masternodeInfo,
						masternodeList,
					);
					if (masternodeAnnouncement) {
						// masternodeInfo not there yet, but we will manually define so that the symbol appears in the webapp
						if (!masternodeInfo && masternodeAnnouncement === 'announce') {
							instance.isMasternode = true;
							instance.masternodeInfo = {
								isActive: true,
								isAlive: true,
								notAllData: true, // inform other utilities that not everything can be read
							};
							instance.masternodeStatus = 4;
						} else if (
							masternodeInfo &&
							masternodeAnnouncement === 'denounce'
						) {
							instance.masternodeInfo = null;
							instance.masternodeStatus = 0;
						}
					}

					// instance.masternodeInfo = {
					// 	isActive: true,
					// 	isAlive: true,
					// 	notAllData: true, // inform other utilities that not everything can be read
					// };
					// instance.masternodeStatus = 4;

					changed =
						instance.balance.toString() !== coinBalance ||
						instance.collateral.toString() !== collateral;
					instance.balance = toBN(coinBalance);
					instance.collateral = toBN(collateral);
				} else {
					coinBalance = await web3.eth.getBalance(address);
					changed = instance.balance.toString() !== coinBalance;
					instance.balance = toBN(coinBalance);
				}

				const prevLoading = instance.loading;
				instance.loading = false;
				if (prevLoading || changed) {
					instance.callbacks.forEach(fn => fn());
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}
	}

	async reloadMasternodeList() {
		const instance = this; // optimize for minifying
		try {
			const { chainId, address, web3Energi } = instance;
			if (address) {
				if (!instance.loadingMasternodeList) {
					instance.loadingMasternodeList = true;
					// to avoid overloading from our own EnergiNode, we will use Energi's web3 node
					instance.masternodeList =
						await web3Energi.masternode.listMasternodes();
					// note that the chainId could have been changed:
					// recheck:
					if (IS_ENERGI_CHAINID(chainId)) {
						const prevMasternodeStatus = instance.masternodeStatus;
						const prevMasternodeVersionRequired =
							instance.masternodeVersionRequired;

						// in case masternodeList already has a new masternodeInfo (which still has to be declared)
						// we want to use it already now:
						instance.newMasternodeInfoDef = false;
						if (!instance.masternodeInfo) {
							const foundMN = instance.masternodeList.find(mn =>
								addressMatch(mn.owner, address),
							);
							if (foundMN) {
								instance.newMasternodeInfoDef = true; // will be used one time inside of reloadWalletBalance
								instance.masternodeInfo = foundMN;
							}
						}

						instance.masternodeStatus = getMasternodeStatus(
							address,
							instance.masternodeInfo, // not through destrucuring `instance`, because we may have redefined it just above
							instance.masternodeList,
						);
						instance.masternodeVersionRequired = instance.masternodeList.reduce(
							(cum, item) => {
								const itemVersion = item.sWVersion;
								const splitCum = cum.split('.');
								const splitItem = itemVersion.split('.');
								if (splitCum[0] > splitItem[0]) {
									return cum;
								}
								if (splitItem[0] > splitCum[0]) {
									return itemVersion;
								}
								if (splitCum[1] > splitItem[1]) {
									return cum;
								}
								if (splitItem[1] > splitCum[1]) {
									return itemVersion;
								}
								if (splitCum[2] > splitItem[2]) {
									return cum;
								}
								if (splitItem[2] > splitCum[2]) {
									return itemVersion;
								}
								return cum;
							},
							'0.0.0',
						);

						// find next payout:
						const prevMasternodeNextPayoutBlock =
							instance.masternodeNextPayoutBlock;
						if (instance.newMasternodeInfoDef) {
							instance.masternodeNextPayoutBlock = null;
						} else {
							const currentPayout = await getCurrentPayout(chainId);
							instance.masternodeNextPayoutBlock = nextPayout(
								address,
								instance.masternodeList,
								currentPayout,
							);
						}

						if (
							instance.newMasternodeInfoDef ||
							prevMasternodeStatus !== instance.masternodeStatus ||
							prevMasternodeVersionRequired !==
								instance.masternodeVersionRequired ||
							prevMasternodeNextPayoutBlock !==
								instance.masternodeNextPayoutBlock
						) {
							instance.callbacks.forEach(fn => fn());
						}
					}
					instance.loadingMasternodeList = false;
				}
			}
		} catch (err) {
			instance.loadingMasternodeList = false;
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}

	async subscribe() {
		const instance = this; // optimize for minifying
		const { chainId, address } = instance;
		if (NETWORK_NAMES[chainId] && address) {
			instance.web3 = getWeb3ws(chainId); // reuse "web3"
			if (IS_ENERGI_CHAINID(chainId)) {
				instance.web3Energi = getWeb3EnergiNode(chainId); // reuse "web3Energi"
			}
			instance.blockheight = await getBlockHeight(chainId);
			instance.subscription = instance.web3.eth.subscribe(
				'newBlockHeaders',
				async (error, result) => {
					if (!error) {
						const { number } = result;
						if (number > instance.blockheight) {
							instance.blockheight = number; // prevent multiple calls on the same block
							instance.reloadWalletBalance();
							if (IS_ENERGI_CHAINID(chainId)) {
								if (instance._timer) {
									instance._timer.cancel();
								}
								instance._timer = later(() => {
									// On the Energi chain, we need to delay for 3 sec, because
									// our "pending-transactions-updater" needs to process announcements and denouncements first
									instance.reloadWalletBalance();
								}, 3000);
								instance.reloadMasternodeList();
							}
						}
					}
				},
			);
		}
	}

	unsubscribe() {
		const instance = this; // optimize for minifying
		if (instance.subscription) {
			instance.subscription.unsubscribe();
			delete instance.subscription;
		}
		if (instance._timer) {
			instance._timer.cancel();
		}
	}

	stop(callbackFn, chainIdSwitch) {
		const instance = this; // optimize for minifying
		const index = instance.callbacks.indexOf(callbackFn);
		if (index !== -1) {
			instance.callbacks.splice(index, 1);
		}
		if (instance.callbacks.length === 0 || chainIdSwitch) {
			instance.unsubscribe();
		}
		instance.balance = BN_ZERO;
		instance.collateral = BN_ZERO; // Energi Masternode Balance
	}

	async start(chainId, address, callback) {
		// TODO: REMOVE
		// if (chainId) {
		// 	return;
		// }
		const instance = this; // optimize for minifying
		instance.stop(null, instance.chainId !== chainId); // clear previous timer
		instance.chainId = chainId;
		instance.address = address;
		instance.callbacks.push(callback);
		instance.init();
		if (NETWORK_NAMES[chainId]) {
			// setup listener
			if (!instance.subscription) {
				await instance.subscribe();
			}
			instance.reloadWalletBalance();
		}
	}
}

const coinInfo = new CoinInfo();

export default coinInfo;
