import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	tabs: {
		position: 'fixed',
		width: '100%',
		left: 0,
		bottom: 0,
		flexGrow: 1,
		[theme.breakpoints.up('sm')]: {
			borderTopLeftRadius: theme.spacing(3),
			borderTopRightRadius: theme.spacing(3),
		},
	},
	tab: {
		fontSize: 12,
		minWidth: theme.spacing(1),
		minHeight: theme.spacing(1),
		padding: theme.spacing(1, 1.5, 0.5, 1.5),
	},
	tabActive: {},
	tokenImg: {
		width: 24,
		height: 24,
		marginBottom: '0!important',
	},
	pendingTransaction: {
		animation: `$blink 1.5s infinite`,
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.25,
		},
	},
}));
