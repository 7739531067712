import axios from 'axios';
import { isEqual } from 'lodash';
import { later } from '@itsa.io/web3utils/lib/timers';
import {
	// WRAPPED_ADDRESSES,
	API_URL,
	// CHAINS_WITH_NFT_AND_SUBSCRIPTIONS,
} from 'config/constants';

const REFRESH_TIME_MS = 10 * 60 * 1000; // every 10 mins

class MainCoinPrices {
	constructor() {
		this.callbacks = [];
		this.init();
	}

	async init() {
		const instance = this;
		instance.prices = {};
		instance.error = null;
		instance.reloadPrices();
		// setup listener
		instance.timer = later(
			() => {
				instance.reloadPrices();
			},
			0,
			REFRESH_TIME_MS, // interval
		);
	}

	async reloadPrices() {
		const instance = this; // optimize for minifying
		const { prices } = instance;

		try {
			let newPrices = {};
			const response = await axios.post(`${API_URL}/coin-price-currencies`, {
				chainids: [1, 56],
			});
			if (
				response.status === 200 &&
				response.data?.success &&
				response.data?.data
			) {
				newPrices = response.data.data;
			}
			if (!isEqual(prices, newPrices)) {
				instance.prices = newPrices;
				instance.callbacks.forEach(fn => fn(instance.prices));
			}
			instance.error = null;
		} catch (err) {
			instance.error = err.message;
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}

	unsubscribe(callbackFn) {
		const instance = this; // optimize for minifying
		const index = instance.callbacks.indexOf(callbackFn);
		if (index !== -1) {
			instance.callbacks.splice(index, 1);
		}
	}

	subscribe(callback) {
		this.callbacks.push(callback);
	}
}

const mainCoinPrices = new MainCoinPrices();

export default mainCoinPrices;
