/* eslint-disable prefer-destructuring */
import { useEffect, useState, useContext } from 'react';
import createStore from 'ctx-provider';
import tokenListCtx from 'context/tokenlist';

const useWrappedToken = () => {
	const [wrappedToken, setWrappedToken] = useState();
	const { data } = useContext(tokenListCtx);

	useEffect(() => {
		setWrappedToken(data[0]);
	}, [data]);

	return wrappedToken;
};

const store = createStore(useWrappedToken);

export const { Provider } = store;
export default store.ctx;
