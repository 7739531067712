import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'inherit',
		alignItems: 'inherit',
		justifyContent: 'inherit',
	},
	exclamationIcon: {
		'&::before': {
			position: 'absolute',
			display: 'block',
			content: '"!"',
			top: -10,
			right: -10,
			width: 20,
			height: 20,
			fontSize: 18,
			borderRadius: '50%',
			textAlign: 'center',
			color: theme.palette.secondary.main,
			border: `1px solid ${theme.palette.orange[500]}`,
			backgroundColor: theme.palette.orange[400],
			lineHeight: 1,
			zIndex: 1,
		},
	},
	exclamationBlinkBorder: {
		border: `1px solid ${alpha(theme.palette.default.main, 0.5)}`,
		animation: `$blink 3s infinite`,
	},
	'@keyframes blink': {
		'50%': {
			borderColor: theme.palette.orange[500],
		},
	},
}));
