import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	ButtonBase,
	SwipeableDrawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	IconButton,
	Link,
} from '@itsa.io/ui';
import {
	Menu as MenuIcon,
	HomeOutlined as HomeOutlinedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	EmailOutlined as EmailOutlinedIcon,
	PostAddOutlined as PostAddOutlinedIcon,
	Twitter as TwitterIcon,
	YouTube as YouTubeIcon,
	VisibilityOff as VisibilityOffIcon,
	VolumeUp as VolumeUpIcon,
	VolumeOff as VolumeOffIcon,
} from '@itsa.io/ui/icons';
// import DiscordIcon from 'assets/images/discord.svg';
import { useIntl, toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import { unmute } from 'utils/play-sound';
import isNewerVersion from 'utils/is-newer-version';
import navigatorCtx from 'context/navigator';
import expertModeCtx from 'context/expertmode';
import tokenListCtx from 'context/tokenlist';
import togglemuteCtx from 'context/togglemute';
import locktimerCtx from 'context/locktimer';
import lastblockvisibleCtx from 'context/lastblockvisible';
import gaspricevisibleCtx from 'context/gaspricevisible';
import subscriptionCtx from 'context/subscription';
import darkmodeCtx from 'context/darkmode';
import useLatestWalletVersion from 'hooks/useLatestWalletVersion';
import MasternodeIndicator from 'components/common/MasternodeIndicator';
import ExclamationIcon from 'components/common/ExclamationIcon';
import BlockHeight from 'components/common/BlockHeight';
import CurrentGasPrice from 'components/common/CurrentGasPrice';
import {
	PAGES_NAMES,
	NETWORK_NAMES,
	IS_ENERGI_CHAINID,
	// DISCORD_URL,
	TWITTER_URL,
	YOUTUBE_URL,
	MAIL_TO,
} from 'config/constants';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';

import useStyles from 'styles/components/common/SwipeableMobileMenu';

const BN_ZERO = toBN('0');

const SwipeableMobileMenu = props => {
	const propsVersion = props.pageContext.version;
	const classes = useStyles();
	const { t } = useIntl();
	const { darkmode } = useContext(darkmodeCtx);
	const { setPage } = useContext(navigatorCtx);
	const { expertMode } = useContext(expertModeCtx);
	const { muted, toggleMute } = useContext(togglemuteCtx);
	const { lock, locked } = useContext(locktimerCtx);
	const { chainId, connected } = useContext(cryptowalletCtx);
	const {
		data: tokenList,
		// error,
		isLoading: loading,
	} = useContext(tokenListCtx);
	const subscription = useContext(subscriptionCtx);
	const { lastBlockVisible } = useContext(lastblockvisibleCtx);
	const { gasPriceVisible } = useContext(gaspricevisibleCtx);
	const { subscriptionChain, hasFullAccess } = subscription;
	const isSubscriptionExpired = subscriptionChain && !hasFullAccess;
	const latestWalletVersion = useLatestWalletVersion(propsVersion);
	const [newerWalletVersion, setNewerWalletVersion] = useState(false);
	const [open, setOpen] = useState(false);
	const menuVisible = open && !locked;
	const expertModeText = expertMode && (
		<span className={classes.proMode}>PRO</span>
	);
	const networkname = NETWORK_NAMES[chainId] || '';
	let walletLogo;
	let walletNetwork;
	let iconMenuButton;
	let listItems;
	let swipeableDrawer;

	const checkWalletVersion = () => {
		const versionIsNewer = isNewerVersion(propsVersion, latestWalletVersion);
		setNewerWalletVersion(versionIsNewer);
	};

	useEffect(() => {
		if (latestWalletVersion) {
			checkWalletVersion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestWalletVersion]);

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	if (menuVisible) {
		let masternodeindicator;
		if (
			IS_ENERGI_CHAINID(chainId) &&
			!loading &&
			tokenList[0] &&
			tokenList[0].masternodeStatus > 0 &&
			tokenList[0].collateral &&
			tokenList[0].collateral.gt(BN_ZERO)
		) {
			// tokenList[0] is information about the NRG coin on the wallet
			masternodeindicator = <MasternodeIndicator coin={tokenList[0]} />;
		}
		walletLogo = (
			<div className={classes.walletLogoName}>
				{logo}
				{expertModeText}
			</div>
		);
		walletNetwork = (
			<div className={classes.walletNetworkName}>
				{networkname}
				{masternodeindicator}
			</div>
		);
	}
	const handleToggleDrawer = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setOpen(isOpen);
	};

	const closeMenu = () => {
		setOpen(false);
	};

	const handleSelectMenuItem = name => {
		switch (name) {
			case PAGES_NAMES.HOME:
				setPage(PAGES_NAMES.HOME);
				break;
			case PAGES_NAMES.SETTINGS:
				setPage(PAGES_NAMES.SETTINGS);
				break;
			case PAGES_NAMES.NEWFEATURES:
				setPage(PAGES_NAMES.NEWFEATURES);
				break;
			default:
				setPage(PAGES_NAMES.HOME);
		}
		closeMenu();
	};

	const handleToggleMute = () => {
		unmute(); // needed to enable playing sounds, even if we mute: this is more like "activating/supporting the browser" to play sounds
		toggleMute();
	};

	const handleLegal = () => {
		setPage(PAGES_NAMES.LEGAL);
	};

	const handlePrivacy = () => {
		setPage(PAGES_NAMES.PRIVACY);
	};

	const iconsContent = (
		<div className={classes.iconButtonContainer}>
			<Link
				component={IconButton}
				className={classes.iconButton}
				href={TWITTER_URL}
				target="_blank"
				rel="noreferrer noopener"
			>
				<TwitterIcon />
			</Link>
			<Link
				component={IconButton}
				className={classes.iconButton}
				href={YOUTUBE_URL}
				target="_blank"
				rel="noreferrer noopener"
			>
				<YouTubeIcon />
			</Link>
			<IconButton className={classes.iconButton} onClick={lock}>
				<VisibilityOffIcon />
			</IconButton>
			<IconButton className={classes.iconButton} onClick={handleToggleMute}>
				{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
			</IconButton>
		</div>
	);

	const legalPrivacyContent = (
		<div className={classes.legalPrivacyLinks}>
			<ButtonBase onClick={handleLegal} variant="text" size="small">
				{t('layout.left_sidebar.nav.legal')}
			</ButtonBase>
			<span>&</span>
			<ButtonBase onClick={handlePrivacy} variant="text" size="small">
				{t('layout.left_sidebar.nav.privacy')}
			</ButtonBase>
		</div>
	);

	const settingIconContent = (
		<ExclamationIcon isActivate={isSubscriptionExpired || newerWalletVersion}>
			<SettingsOutlinedIcon />
		</ExclamationIcon>
	);

	let blockheight;
	if (lastBlockVisible) {
		blockheight = <BlockHeight open={menuVisible} />;
	}

	let currentGasPrice;
	if (gasPriceVisible) {
		currentGasPrice = <CurrentGasPrice open={menuVisible} />;
	}

	if (connected) {
		const menuicon = (
			<ExclamationIcon isActivate={isSubscriptionExpired || newerWalletVersion}>
				<MenuIcon />
			</ExclamationIcon>
		);
		iconMenuButton = (
			<IconButton
				className={classes.menuButton}
				aria-label="menu"
				onClick={event => handleToggleDrawer(event, true)}
			>
				{menuicon}
			</IconButton>
		);
		listItems = (
			<>
				<ListItem button onClick={() => handleSelectMenuItem(PAGES_NAMES.HOME)}>
					<ListItemIcon>
						<HomeOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.home')} />
				</ListItem>
				<ListItem
					button
					component={Link}
					onClick={closeMenu}
					href={MAIL_TO}
					target="_blank"
					rel="noreferrer noopener"
				>
					<ListItemIcon>
						<EmailOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.email')} />
				</ListItem>
				<ListItem
					button
					onClick={() => handleSelectMenuItem(PAGES_NAMES.SETTINGS)}
				>
					<ListItemIcon>{settingIconContent}</ListItemIcon>
					<ListItemText primary={t('menu.settings')} />
				</ListItem>
				<ListItem
					button
					onClick={() => handleSelectMenuItem(PAGES_NAMES.NEWFEATURES)}
				>
					<ListItemIcon>
						<PostAddOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.new_features')} />
				</ListItem>
			</>
		);
		swipeableDrawer = (
			<SwipeableDrawer
				classes={{
					paper: classes.paperRoot,
				}}
				anchor="right"
				open={menuVisible}
				onClose={event => handleToggleDrawer(event, false)}
				onOpen={event => handleToggleDrawer(event, true)}
			>
				<div className={classes.paperContainer} role="presentation">
					{walletLogo}
					<div className={classes.walletInfo}>
						{walletNetwork}
						{blockheight}
						{currentGasPrice}
					</div>
					<List className={classes.list}>{listItems}</List>
					<Divider className={classes.divider} />
					{iconsContent}
					{legalPrivacyContent}
				</div>
			</SwipeableDrawer>
		);
	}

	return (
		<>
			{iconMenuButton}
			{swipeableDrawer}
		</>
	);
};

SwipeableMobileMenu.propTypes = {
	pageContext: PropTypes.shape({
		version: PropTypes.string.isRequired,
	}).isRequired,
};

export default SwipeableMobileMenu;
