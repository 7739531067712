/* eslint-disable react/no-danger */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Popover, Badge } from '@itsa.io/ui';
import { useIntl, formatBN } from '@itsa.io/web3utils';
import { getMasternodeStatusText } from 'utils/get-masternode-status';
import blockheightCtx from 'context/blockheight';
import { BN_1000000, BN_914 } from 'config/constants';

import { AccountTreeOutlined as AccountTreeOutlinedIcon } from '@itsa.io/ui/icons';
import useStyles from 'styles/components/common/MasternodeIndicator';

const checkWrongNodeVersion = coin => {
	if (coin?.masternodeInfo?.notAllData) {
		return false;
	}
	return (
		coin.masternodeVersionRequired &&
		coin.masternodeInfo?.sWVersion !== coin.masternodeVersionRequired &&
		coin.masternodeInfo?.sWVersion !== '0.0.0' // this version is shown when there was no heartbeat yet
	);
};

const checkNodeUpgradeRequired = coin => {
	if (
		!coin.masternodeInfo ||
		coin.masternodeInfo.notAllData ||
		!checkWrongNodeVersion(coin)
	) {
		return false;
	}
	const splitMaxVersion = coin.masternodeVersionRequired.split('.');
	const splitNodeVersion = coin.masternodeInfo.sWVersion.split('.');
	return (
		splitMaxVersion[0] !== splitNodeVersion[0] ||
		splitMaxVersion[1] !== splitNodeVersion[1]
	);
};

const getTimeLeft = (t, blocks) => {
	// assume 1 block takes 1 min
	let hours = Math.floor(blocks / 60);
	let minutes = blocks - 60 * hours;
	const days = Math.floor(hours / 24);
	hours -= days * 24;
	let daysString = '';
	if (days === 1) {
		daysString = `1 ${t('time.day')} `;
	} else if (days > 1) {
		daysString = `${days} ${t('time.days')} `;
	}
	minutes = minutes.toString().padStart(2, '0');
	return `${daysString}${hours}:${minutes} ${t('time.hrs')}`;
};

const MasternodeIndicator = ({ coin }) => {
	const classes = useStyles();
	const { t } = useIntl();
	const { blockheight } = useContext(blockheightCtx);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? 'masternode-info-popover' : undefined;
	const nodeUpgradeRequired = checkNodeUpgradeRequired(coin);
	const wrongNodeVersion = checkWrongNodeVersion(coin);

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	let dotStatus = coin.masternodeStatus;
	let statusTextStatus = coin.masternodeStatus;

	if (nodeUpgradeRequired) {
		dotStatus = 1;
		statusTextStatus = 1;
	} else if (dotStatus === 4 && wrongNodeVersion) {
		dotStatus = 3;
	}

	const statusText = nodeUpgradeRequired
		? 'masternode.upgrade_required'
		: getMasternodeStatusText(coin.masternodeStatus);

	const rowStatus = (
		<tr>
			<td>{t('masternode.status')}:</td>
			<td className={clsx(classes.status, `status${statusTextStatus}`)}>
				{t(statusText)}
			</td>
		</tr>
	);

	let rowVersion;
	if (
		coin.masternodeInfo &&
		coin.masternodeInfo.sWVersion &&
		coin.masternodeInfo.sWVersion !== '0.0.0'
	) {
		rowVersion = (
			<tr>
				<td>{t('masternode.version')}:</td>
				<td>{coin.masternodeInfo.sWVersion}</td>
			</tr>
		);
	}

	let rowUptime;
	if (
		coin.masternodeInfo &&
		coin.masternodeInfo.announcedBlock &&
		blockheight
	) {
		rowUptime = (
			<tr>
				<td>{t('time.uptime')}:</td>
				<td>
					{getTimeLeft(t, blockheight - coin.masternodeInfo.announcedBlock)}
				</td>
			</tr>
		);
	}

	let rowWrongVersion;
	if (wrongNodeVersion) {
		let className;
		let versionText;
		if (nodeUpgradeRequired) {
			className = classes.requiredVersion;
			versionText = 'masternode.required_version';
		} else {
			className = classes.recomendedVersion;
			versionText = 'masternode.available_version';
		}
		rowWrongVersion = (
			<tr className={className}>
				<td>{t(versionText)}:</td>
				<td>{coin.masternodeVersionRequired}</td>
			</tr>
		);
	}

	const rowCollateral = (
		<tr>
			<td>{t('masternode.collateral')}:</td>
			<td>
				{formatBN(coin.collateral, { assetDigits: 18, decimals: 0 })}
				<span className={classes.tokenSymbol}>MNRG</span>
			</td>
		</tr>
	);

	let nextPayoutText;
	if (typeof coin.masternodeNextPayoutBlock === 'number') {
		if (coin.masternodeNextPayoutBlock === 0) {
			nextPayoutText = t('masternode.momentarily');
		} else {
			nextPayoutText = getTimeLeft(t, coin.masternodeNextPayoutBlock);
		}
	} else {
		nextPayoutText = `${t('masternode.calculating')}...`;
	}

	const rowNextPayout = (
		<tr>
			<td>{t('masternode.next_payout')}:</td>
			<td>{nextPayoutText}</td>
		</tr>
	);

	const payoutAmount = `${formatBN(
		coin.collateral.mul(BN_914).div(BN_1000000),
		{
			assetDigits: 18,
			formatHTML: true,
		},
	)}<span class=${classes.tokenSymbol}>NRG</span>`;

	const rowPayoutAmount = (
		<tr>
			<td>{t('masternode.payout_amount')}:</td>
			<td
				className={classes.payoutAmount}
				dangerouslySetInnerHTML={{ __html: payoutAmount }}
			/>
		</tr>
	);

	return (
		<div className={classes.root}>
			<Badge
				className={clsx(classes.masternodeBadge, `status${dotStatus}`)}
				variant="dot"
				aria-owns={open ? popoverId : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<div className={classes.masternodeTextWrapper}>
					<AccountTreeOutlinedIcon className={classes.icon} />
					<div className={classes.masternodeAbrText}>MN</div>
				</div>
			</Badge>
			<Popover
				id={popoverId}
				className={classes.popover}
				classes={{
					paper: classes.popoverPaper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<table>
					<thead>
						<tr>
							<th className={classes.masternodeText} colSpan="2">
								{t('masternode.masternode')}
							</th>
						</tr>
					</thead>
					<tbody>
						{rowStatus}
						{rowUptime}
						{rowVersion}
						{rowWrongVersion}
						{rowCollateral}
						{rowNextPayout}
						{rowPayoutAmount}
					</tbody>
				</table>
			</Popover>
		</div>
	);
};

MasternodeIndicator.defaultProps = {};

MasternodeIndicator.propTypes = {
	coin: PropTypes.object.isRequired,
};

export default MasternodeIndicator;
