import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		textAlign: 'center',
		display: 'flex',
		gap: '1rem',
		'& span': {
			fontSize: '1.2rem',
		},
	},
	circularContainer: {
		display: 'inline-block',
		width: 30,
		color: theme.palette.primary.main,
	},
}));
