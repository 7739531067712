import { useEffect, useContext, useRef } from 'react';
import createStore from 'ctx-provider';
import sha256 from 'sha256';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import host from 'utils/get-host';

// first sha away from second to make it more difficult to patch the code
const singleHashedHostName = sha256(host || ''); // note that nodejs has no hostname, but we need to pass in a String

const VALID_HOSTNAMES = {};
VALID_HOSTNAMES[process.env.GATSBY_DOUBLEHASHED_HOSTNAME] = true;

const useSecuredSendTx = () => {
	const { sendTx } = useContext(cryptowalletCtx);
	const doubleHashedHostname = useRef();
	const sendTxRef = useRef();

	const securedSendTx = (...args) => {
		let response;
		if (VALID_HOSTNAMES[doubleHashedHostname.current] && sendTxRef.current) {
			response = sendTxRef.current(...args);
		}
		return response;
	};

	useEffect(() => {
		// second sha away from first to make it more difficult to patch the code
		if (!doubleHashedHostname.current) {
			doubleHashedHostname.current = sha256(singleHashedHostName);
		}
		// sendTx will change when users switches from Network
		sendTxRef.current = sendTx;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sendTx]);

	return securedSendTx;
};

const store = createStore(useSecuredSendTx);

export const { Provider } = store;
export default store.ctx;
