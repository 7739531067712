/* eslint-disable prefer-destructuring */
import { useEffect, useState, useContext, useRef } from 'react';
import createStore from 'ctx-provider';
import blockheightCtx from 'context/blockheight';
import { cryptowalletCtx, toBN } from '@itsa.io/web3utils';
import { isEqual, cloneDeep } from 'lodash';
import { ITSA_SUBSCRIPTION_SC_ADDRESSES, BN_ZERO } from 'config/constants';
import {
	hasFullAccess,
	isBoundSubscribed,
	canGetSubscription,
	canApproveBoundSubscription,
	isSubscribed,
	expiration,
	hasValidBindToSubscription,
	isBoundToSubscription,
	getMasterSubscription,
	canGetTrial,
	hasTrial,
	trialExpiration,
	hasFreeSubscription,
	trialPeriod,
	maxSubscriptionDays,
	getBoundAddresses,
	maxChildSubscriptions,
	dailyFee,
	nftIds,
	hasNftSubscription,
} from 'utils/smartcontracts/itsa-subscription';

const PASSIVE_TIME_MS = 1000 * 60; // no more often than once per minute

const EMPTY_SUBSCRIPTION = {
	hasFullAccess: false,
	isBoundSubscribed: false,
	canGetSubscription: true,
	canApproveBoundSubscription: false,
	isSubscribed: false,
	expiration: null,
	hasValidBindToSubscription: false,
	isBoundToSubscription: false,
	masterSubscription: null,
	canGetTrial: true,
	hasTrial: false,
	trialExpiration: null,
	hasFreeSubscription: false,
	trialPeriod: null,
	maxSubscriptionDays: null,
	boundAddresses: [],
	maxChildSubscriptions: null,
	dailyFee: BN_ZERO,
	nftIds: {
		silver: [],
		gold: [],
		platina: [],
	},
	hasNftSubscription: false,
};

const getSubscriptionData = async (chainId, address) => {
	let subscriptionData;
	const promises = [
		hasFullAccess(chainId, address, false),
		isBoundSubscribed(chainId, address),
		canGetSubscription(chainId, address),
		canApproveBoundSubscription(chainId),
		isSubscribed(chainId, address),
		expiration(chainId, address),
		hasValidBindToSubscription(chainId, address),
		isBoundToSubscription(chainId, address),
		getMasterSubscription(chainId, address),
		canGetTrial(chainId, address),
		hasTrial(chainId, address),
		trialExpiration(chainId, address),
		hasFreeSubscription(chainId, address),
		trialPeriod(chainId),
		maxSubscriptionDays(chainId),
		getBoundAddresses(chainId, address),
		maxChildSubscriptions(chainId),
		dailyFee(chainId),
		nftIds(chainId, address),
		hasNftSubscription(chainId, address),
	];
	try {
		const result = await Promise.all(promises);
		let expiration = result[5];
		let trialExpiration = result[11];
		if (!expiration || expiration === '0') {
			expiration = null;
		} else {
			const secs = parseInt(expiration, 10);
			expiration = new Date(Date.now() + 1000 * secs);
		}
		if (!trialExpiration || trialExpiration === '0') {
			trialExpiration = null;
		} else {
			const secs = parseInt(trialExpiration, 10);
			trialExpiration = new Date(Date.now() + 1000 * secs);
		}
		const nftIds = result[18];
		const hasNftSubscription = result[19];

		subscriptionData = {
			hasFullAccess:
				result[0] ||
				!!nftIds.silver.length ||
				!!nftIds.gold.length ||
				!!nftIds.platina.length ||
				hasNftSubscription,
			isBoundSubscribed: result[1],
			canGetSubscription: result[2],
			canApproveBoundSubscription: result[3],
			isSubscribed: result[4],
			expiration,
			hasValidBindToSubscription: result[4] ? false : result[6],
			isBoundToSubscription: result[7],
			masterSubscription: result[8],
			canGetTrial: result[9],
			hasTrial: result[10],
			trialExpiration,
			hasFreeSubscription: result[12],
			trialPeriod: result[13],
			maxSubscriptionDays: result[14],
			boundAddresses: result[15],
			maxChildSubscriptions: result[16],
			dailyFee: result[17] ? toBN(result[17]) : BN_ZERO,
			nftIds,
			hasNftSubscription,
		};
	} catch (err) {
		// no valid subscription
		subscriptionData = null;
	}
	return subscriptionData;
};

const getBestSubscriptionChain = chainSubscriptions => {
	const chainIds = Object.keys(chainSubscriptions)
		.map(chainId => parseInt(chainId, 10))
		.filter(chainId => !!chainId); // we need to end up with valid chainIds: during switching network, it could happen that there are null values

	// try to find best based upon free subscription
	let bestSubscriptionChain;
	chainIds.forEach(chainId => {
		const chainSubscription = chainSubscriptions[chainId];
		if (
			chainSubscription?.nftIds.silver.length ||
			chainSubscription?.nftIds.gold.length ||
			chainSubscription?.nftIds.platina.length
		) {
			bestSubscriptionChain = chainId;
		} else if (chainSubscription?.hasNftSubscription) {
			bestSubscriptionChain = chainId;
		} else if (chainSubscription?.hasFreeSubscription) {
			bestSubscriptionChain = chainId;
		}
	});

	// try to find best based upon subscription
	if (!bestSubscriptionChain) {
		let expirationTimeMs = 0;
		chainIds.forEach(chainId => {
			const chainSubscription = chainSubscriptions[chainId];
			if (
				chainSubscription &&
				(chainSubscription.isSubscribed ||
					chainSubscription.hasValidBindToSubscription) &&
				chainSubscription.expiration.getTime() > expirationTimeMs
			) {
				bestSubscriptionChain = chainId;
				expirationTimeMs = chainSubscription.expiration.getTime();
			}
		});
	}

	// try to find best based upon subscription
	if (!bestSubscriptionChain) {
		let expirationTimeMs = 0;
		chainIds.forEach(chainId => {
			const chainSubscription = chainSubscriptions[chainId];
			if (
				chainSubscription &&
				chainSubscription.hasTrial &&
				chainSubscription.trialExpiration.getTime() > expirationTimeMs
			) {
				bestSubscriptionChain = chainId;
				expirationTimeMs = chainSubscription.trialExpiration.getTime();
			}
		});
	}

	// see if there is a chain where the tiral has been used
	if (!bestSubscriptionChain) {
		chainIds.forEach(chainId => {
			const chainSubscription = chainSubscriptions[chainId];
			if (chainSubscription && !chainSubscription.canGetTrial) {
				bestSubscriptionChain = chainId;
			}
		});
	}
	return bestSubscriptionChain;
};

const useSubscription = () => {
	const [subscription, setSubscription] = useState(EMPTY_SUBSCRIPTION);
	const { address, chainId } = useContext(cryptowalletCtx);
	const { blockheight } = useContext(blockheightCtx);
	const busyRef = useRef(false);
	const lastSubscriptionCall = useRef();

	const syncSubscription = async () => {
		if (address && !busyRef.current) {
			try {
				busyRef.current = true;
				lastSubscriptionCall.current = Date.now();

				const chainIds = Object.keys(ITSA_SUBSCRIPTION_SC_ADDRESSES).map(
					chainId => parseInt(chainId, 10),
				);
				const chainSubscriptions = {};
				// eslint-disable-next-line no-restricted-syntax
				for (const chainId of chainIds) {
					chainSubscriptions[chainId] = await getSubscriptionData(
						chainId,
						address,
					);
				}
				const bestSubscriptionChain =
					getBestSubscriptionChain(chainSubscriptions);
				let currentSubscription;
				if (bestSubscriptionChain) {
					currentSubscription = cloneDeep(
						chainSubscriptions[bestSubscriptionChain],
					);
				} else {
					currentSubscription = EMPTY_SUBSCRIPTION;
				}
				currentSubscription.chainSubscriptions = chainSubscriptions;
				currentSubscription.subscriptionChain = bestSubscriptionChain;
				if (
					currentSubscription &&
					!isEqual(currentSubscription, subscription)
				) {
					setSubscription(currentSubscription);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
			busyRef.current = false;
		}
	};

	useEffect(() => {
		syncSubscription();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		syncSubscription();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address, chainId]);

	useEffect(() => {
		if (
			!lastSubscriptionCall.current ||
			lastSubscriptionCall.current < Date.now() - PASSIVE_TIME_MS
		) {
			syncSubscription();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockheight]);

	return subscription;
};

const store = createStore(useSubscription);

export const { Provider } = store;
export default store.ctx;
