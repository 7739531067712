/* eslint-disable prefer-destructuring */
// eslint-disable-next-line import/prefer-default-export

import ETHEREUM_ROUTER_ABI from 'config/constants/abi/EthereumRouter.json';
import ENERGI_ROUTER_ABI from 'config/constants/abi/EnergiRouter.json';
import ETHEREUM_FACTORY_ABI from 'config/constants/abi/EthereumFactory.json';
import ENERGI_FACTORY_ABI from 'config/constants/abi/EnergiFactory.json';
import ETHEREUM_PAIR_ABI from 'config/constants/abi/EthereumPair.json';
import ENERGI_PAIR_ABI from 'config/constants/abi/EnergiPair.json';
import ENERGI_MASTERNODE_TOKEN_ABI from 'config/constants/abi/EnergiMasternodeToken.json';
import ENERGI_MASTERNODE_REGISTRY_ABI from 'config/constants/abi/EnergiMasternodeRegistry.json';
import ITSA_SILVER_NFT_ABI from 'config/constants/abi/WalletSilverNft.json';
import ITSA_GOLD_NFT_ABI from 'config/constants/abi/WalletGoldNft.json';
import ITSA_PLATINA_NFT_ABI from 'config/constants/abi/WalletPlatinaNft.json';
import GENERAL_TOKENINFO from 'config/general-token-info.json';
import { utils } from 'web3';

const { toBN } = utils;
const BINANCE_FACTORY_ABI = ETHEREUM_FACTORY_ABI;
const XDAI_FACTORY_ABI = ETHEREUM_FACTORY_ABI;
const POLYGON_FACTORY_ABI = ETHEREUM_FACTORY_ABI;
const KOVAN_FACTORY_ABI = ETHEREUM_FACTORY_ABI;

const BINANCE_ROUTER_ABI = ETHEREUM_ROUTER_ABI;
const XDAI_ROUTER_ABI = ETHEREUM_ROUTER_ABI;
const MATIC_ROUTER_ABI = ETHEREUM_ROUTER_ABI;
const KOVAN_ROUTER_ABI = ETHEREUM_ROUTER_ABI;

const BINANCE_PAIR_ABI = ETHEREUM_PAIR_ABI;
const XDAI_PAIR_ABI = ETHEREUM_PAIR_ABI;
const MATIC_PAIR_ABI = ETHEREUM_PAIR_ABI;
const KOVAN_PAIR_ABI = ETHEREUM_PAIR_ABI;

export const ENVIRONMENT = process.env.GATSBY_ENV;

// const withTestnet = ENVIRONMENT === 'development' || ENVIRONMENT === 'staging';
const withTestnet = false;
const withKovanOnTestnet = false;

export const TRIALS_ENABLED = true;
export const SUBSCRIPTIONS_ENABLED = true;
export const NFT_EXTRA_ADDRESSES_ENABLED = true;

export const BN_ZERO = toBN('0');
export const BN_1 = toBN('1');
export const BN_3 = toBN('3');
export const BN_100 = toBN('100');
export const BN_914 = toBN('914');
export const BN_997 = toBN('997');
export const BN_1000 = toBN('1000');
export const BN_10000 = toBN('10000');
export const BN_100000 = toBN('100000');
export const BN_1000000 = toBN('1000000');
export const BN_1MNRG = toBN('1000000000000000000000');
export const BN_GAS_SEND_COIN = toBN('21000');
export const BN_1ETH_WEI = toBN('1000000000000000000');

export const SEVERITIES = {
	'-3': 'cancelled', // event that will be send by wallet devices whenever a transaction is cancelled
	'-2': 'signed', // event that will be send by wallet devices whenever a transaction is successfully signed
	'-1': 'cleanup', // event that will be send by wallet devices whenever a previous message should be cleaned up
	0: 'success',
	1: 'info',
	2: 'warning',
	3: 'error',
};

export const API_URL = 'https://api.itsa.io';

export const CHAINS_WITH_NFT_AND_SUBSCRIPTIONS = [1, 56 /* , 10001, 39797 */];

export const BN_GAS_TRADE = {
	1: {
		ONE_STEP: toBN('400000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('100000'), // estimated, high taken because MetaMask changes these values
	},
	56: {
		ONE_STEP: toBN('225000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('130000'), // estimated, high taken because MetaMask changes these values
	},
	137: {
		ONE_STEP: toBN('400000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('100000'), // estimated, high taken because MetaMask changes these values
	},
	10001: {
		ONE_STEP: toBN('400000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('100000'), // estimated, high taken because MetaMask changes these values
	},
	39797: {
		// ONE_STEP: toBN('1000000'), // estimated, high taken because MetaMask changes these values
		// EXTRA_STEP: toBN('800000'), // estimated, high taken because MetaMask changes these values
		ONE_STEP: toBN('892000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('670000'), // estimated, high taken because MetaMask changes these values
	},
};

export const BN_GAS_SUBSCRIBE = toBN('100000');

// export const BN_GAS_APPROVE = {
// 	39797: toBN('473229'),
// };

export const BN_GAS_WRAPPING = {
	1: {
		WRAP: toBN('67557'),
		UNWRAP: toBN('54023'),
	},
	56: {
		WRAP: toBN('43107'),
		UNWRAP: toBN('57155'),
	},
	137: {
		WRAP: toBN('67557'),
		UNWRAP: toBN('54023'),
	},
	10001: {
		WRAP: toBN('67557'),
		UNWRAP: toBN('54023'),
	},
	39797: {
		WRAP: toBN('65399'),
		UNWRAP: toBN('78731'),
	},
};

export const BN_GAS_ENERGI_COLLATERIZATION = {
	DEPOSIT: toBN('179651'),
	WITHDRAWAL: toBN('406418'),
};

export const BN_MAXUINT256 = toBN(
	'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
);
export const PAGES_NAMES = {
	HOME: 'indexPage',
	SETTINGS: 'SettingsPage',
	SUBSCRIPTION: 'SubscriptionPage',
	SENDCOIN: 'SendCoinPage',
	SENDTOKEN: 'SendTokenPage',
	WRAP: 'WrapPage',
	EXCHANGE: 'ExchangePage',
	TOKEN: 'TokenPage',
	COLLATERAL: 'CollateralPagePage',
	CUSTOMTOKENS: 'CustomTokensPage',
	NEWFEATURES: 'NewFeaturesPage',
	NFTADRESSES: 'NftAddressesPage',
	SUBSCRIPTIONADDRESSES: 'SubscriptionAddressesPage',
	BINDTOSUBSCRIPTION: 'BindToSubscriptionPage',
	TRANSFERNFT: 'TransferNft',
	LEGAL: 'Legal',
	PRIVACY: 'Privacy',
};

const TRANSFORM_SYMBOLS = {
	WBNB: 'BNB',
	WETH: 'ETH',
	WETHW: 'ETHW',
	WMATIC: 'MATIC',
	WNRG: 'NRG',
	WXDAI: 'xDAI',
};

const TRANSFORM_COINNAMES = {
	WBNB: 'BNB Coin @ BSC',
	WETH: 'Ethereum',
	WETHW: 'EthereumPOW',
	WMATIC: 'Matic',
	WNRG: 'Energi',
	WXDAI: 'xDai',
};

export const CHAIN_SYMBOLS = {
	1: 'ETH',
	56: 'BNB',
	100: 'xDAI',
	137: 'MATIC',
	10001: 'ETHW',
	39797: 'NRG',
};

// Ledger does not support thes feautures yet
export const CHAIN_HAS_PRIORITY_FEE = {
	// 1: true,
	// 10001: true, // EthereumPOW
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ITSA_SUBSCRIPTION_CHAINS = {
	1: true,
	56: true,
	137: true,
	// 10001: true,
	39797: true,
};

export const ITSA_SUBSCRIPTION_SC_ADDRESSES = {
	1: '0x03EC2f6eecC16ec48872962A046673E3b9d7BA35',
	56: '0xc6B0EC7866baFA7d746c612f74Bb1C9E31Ee61Ae',
	137: '0xfE9AEa8660a76f481bcBC5373E5211DC933B69cb',
	// 10001: '0xb8151F48e0c50451Fc6A1DdA951B365B79004054',
	39797: '0x873Ba5138e2E2FB453797B56219081264CdCCB3d',
};

export const ITSA_NFTS_SC_ADDRESSES = {
	1: {
		silver: '0xE2c36E5CF3feF912f6aFe5197b9e824846440Ec5',
		gold: '0x3DD4Af0e4D5F34E2D577F2B483095878F6573a0e',
		platina: '0x60Ae9b14C67C9F85CCeD61494d8D6C96B94Bf357',
	},
	// 10001: {
	// 	silver: '0x60Ae9b14C67C9F85CCeD61494d8D6C96B94Bf357',
	// 	gold: '0xf52f3fa4824D2c01B6b30278A3F01B31d8563588',
	// 	platina: '0x888C346BebdeC8Aa0738C8621c141775b12a151b',
	// },
};

if (ENVIRONMENT === 'development') {
	ITSA_SUBSCRIPTION_SC_ADDRESSES['49797'] =
		'0x1151eeC90A83c72aaEae637d4c4fcd553998E8E2';
	ITSA_NFTS_SC_ADDRESSES['49797'] = {
		silver: '0x60Ae9b14C67C9F85CCeD61494d8D6C96B94Bf357',
		gold: '0x339531b66C05DB84AC6F79edd329a1470922d173',
		platina: '0x95efb9BCeeF08E5A0bE76A7cdBd913F21Cbbc887',
	};
	ITSA_SUBSCRIPTION_CHAINS['49797'] = true;
}

export const ITSA_NFTS_ABIS = {
	silver: ITSA_SILVER_NFT_ABI,
	gold: ITSA_GOLD_NFT_ABI,
	platina: ITSA_PLATINA_NFT_ABI,
};

export const RPC_URLS = {
	1: process.env.GATSBY_ETHEREUM_JSON_RCP,
	// 56: 'https://itsasbreuk.bscfullnode.com/23febfa9-571d-4ec5-8a42-b06df25265a1/rpc', // fastlynode
	56: process.env.GATSBY_BSC_JSON_RCP,
	100: 'https://rpc.xdaichain.com', // xDai OR https://rpc.gnosischain.com OR https://dai.poa.network
	137: process.env.GATSBY_POLYGON_JSON_RCP,
	//	137: 'https://matic-mainnet.chainstacklabs.com', // Polygon (previous Matic)
	// 137: 'https://polygon-mainnet.infura.io/v3/ec6ed54ea1a84209a0dcd03070ebc3a7',
	// 10001: 'https://ethereumpownode.itsa.io/rpc',
	10001: process.env.GATSBY_ETHEREUMPOW_JSON_RCP,
	// 10001: 'https://54.155.221.59:8545',
	// 39797: 'https://nodeapi.energi.network', // energi
	// 39797: 'https://energinode.itsa.io/rpcbyip',
	39797: process.env.GATSBY_ENERGI_JSON_RCP,
};

export const JSON_APIS_WSS = {
	1: process.env.GATSBY_ETHEREUM_WSS,
	// 56: 'wss://itsasbreuk.bscfullnode.com/23febfa9-571d-4ec5-8a42-b06df25265a1/ws', // fastlynode
	56: process.env.GATSBY_BSC_WSS,
	100: 'wss://rpc.gnosischain.com/wss',
	137: process.env.GATSBY_POLYGON_WSS,
	// 137: 'wss://speedy-nodes-nyc.moralis.io/115ee709985509f77f622780/polygon/mainnet/ws',
	// 10001: 'wss://ethereumpownode.itsa.io/ws',
	10001: process.env.GATSBY_ETHEREUMPOW_WSS,
	// 39797: 'wss://energinode.itsa.io/wsbyip',
	39797: process.env.GATSBY_ENERGI_WSS,
};

export const RPC_URLS_ENERGI_NODE = {
	// energi its OWN nodes
	// 39797: 'https://nodeapi.energi.network', // too often a problem
	39797: process.env.GATSBY_ENERGI_JSON_RCP,
};

// ensure the addresses are lowercased, since they will be compared to later on in lowercase
export const WRAPPED_ADDRESSES = {
	1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(),
	// 56: '0x5558447b06867ffebd87dd63426d61c868c45904'.toLowerCase(), // bnb smart chain
	56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase(), // bnb smart chain
	100: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d'.toLowerCase(),
	137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'.toLowerCase(), // Polygon (previous Matic) // See DEX: https://quickswap.exchange/#/swap
	10001: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(),
	39797: '0xA55F26319462355474A9F2c8790860776a329aA4'.toLowerCase(),
};

// WETHW
// Wrapped ETHPoW (?)
// 0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990

export const STABLECOIN_REF_ADDRESSES = {
	1: '0x6b175474e89094c44da98b954eedeac495271d0f'.toLowerCase(),
	56: ''.toLowerCase(), // bnb smart chain
	100: ''.toLowerCase(), // xDai
	137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'.toLowerCase(), // USDC - Polygon (previous Matic) // See DEX: https://quickswap.exchange/#/swap
	// 39797: '0x0ee5893f434017d8881750101ea2f7c49c0eb503'.toLowerCase(),
	10001: '0x6b175474e89094c44da98b954eedeac495271d0f'.toLowerCase(),
	39797: '0x0F46Fe95a8d6573990118AEE8b7AC9A3532f5963'.toLowerCase(), // USDE
};

export const BASE_TRADE_TOKENS = {
	1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(), // WETH
	// 56: '0x5558447b06867ffebd87dd63426d61c868c45904'.toLowerCase(), // WBNB bnb smart chain
	56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase(), // WBNB bnb smart chain
	100: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d'.toLowerCase(), // WETH
	137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'.toLowerCase(), // WMATIC Polygon (previous Matic) // See DEX: https://quickswap.exchange/#/swap
	10001: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(), // WETH
	39797: '0xA55F26319462355474A9F2c8790860776a329aA4'.toLowerCase(), // WNRG
};

// const TOKEN_LISTS = {
// 	1: tokenlistEthereum,
// 	56: tokenlistBinance, // bnb smart chain
// 	100: tokenlistXDai,
// 	137: tokenlistMatic,
// 	39797: tokenlistEnergi,
// };

// export const getTokenList = chainId => cloneDeep(TOKEN_LISTS[chainId] || []);
export const MORALIS_CHAINS = {
	1: true, // Ethereum
	56: true, // Binance Smart Chain
	137: true, // Polygon (previous Matic)
};

export const NETWORK_NAMES = {
	// only networks that the wallet supports!!
	1: 'Ethereum',
	// 2: 'Ethereum Classic', // https://swap.ethereumclassic.com/#/swap
	56: 'BNB Smart Chain', // bnb smart chain
	// 100: 'xDai',
	137: 'Polygon', // Polygon (previous Matic)
	// 10001: 'Ethereum PoW', // <- can be reactivated once we have our EthereumPoW node up again
	// 39797: 'Energi',
};

export const NETWORK_ICONS = {
	// only networks that the wallet supports!!
	1: '/tokens/ETH.svg',
	56: '/tokens/BNB.svg', // bnb smart chain
	// 100: '/tokens/ETH.svg',
	137: '/tokens/MATIC.svg', // Polygon (previous Matic)
	// 10001: '/tokens/ETHW.svg', // <- can be reactivated once we have our EthereumPoW node up again
	// 39797: '/tokens/NRG.svg',
};

export const EXPLORER_URLS = {
	1: 'https://etherscan.io',
	56: 'https://www.bscscan.com', // bnb smart chain
	100: 'https://blockscout.com/xdai/mainnet', // xDai
	137: 'https://polygonscan.com', // Polygon (previous Matic)
	10001: 'https://mainnet.ethwscan.com', // EthereumPOW
	39797: 'https://explorer.energi.network', // energi
};

export const TRADES_DEADLINES_MINS = {
	1: 20,
	56: 5,
	100: 5,
	137: 5,
	10001: 20,
	39797: 10,
};

export const FACTORY_ADDRESSES = {
	1: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // Ethereum uniswap v2
	// 1: '0x1F98431c8aD98523631AE4a59f267346ea31F984', // Ethereum uniswap v3

	// Sushiswap MAY NOT work: TEST IT FIRST: on EthereumPOW, they keep the send tokens and give nothing in return!
	// 1: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac', // EthereumPOW Sushiswap v2

	56: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', // bnb smart chain Pancakeswap
	// 56: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4', // bnb smart chain Sushiswap
	100: '0xA818b4F111Ccac7AA31D0BCc0806d64F2E0737D7', // xDai
	137: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32', // Polygon (previous Matic)
	246: '0x17854c8d5a41d5A89B275386E24B2F38FD0AfbDd', // EWT https://carbonswap.exchange/#/swap
	10001: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // EthereumPOW uniswap v2

	// EthPOW Sushiswap doesn't work: they keep the send tokens and give nothing in return!
	// 10001: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac', // EthereumPOW Sushiswap v2
	39797: '0xa2dAE9e70D5A08B781DeE134617Cf5E7D23043c2', // energi
};

export const ROUTER_ADDRESSES = {
	1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // ethereum uniswap v2
	// 1: '0xE592427A0AEce92De3Edee1F18E0157C05861564', // ethereum uniswap v3

	// Sushiswap MAY NOT work: TEST IT FIRST: on EthereumPOW, they keep the send tokens and give nothing in return!
	// 11: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F', // ethereumPOW sushiswap v2

	56: '0x10ED43C718714eb63d5aA57B78B54704E256024E', // bnb smart chain Pancakeswap
	// 56: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506', // bnb smart chain Sushiswap
	100: '0x1C232F01118CB8B424793ae03F870aa7D0ac7f77', // xDai
	137: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff', // Polygon (previous Matic)
	246: '0x3b932c3f73A9Eb6836Cd31145F2D47561e21DeCB',
	10001: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // ethereumPOW uniswap v2

	// EthPOW Sushiswap doesn't work: they keep the send tokens and give nothing in return!
	// 10001: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F', // ethereumPOW sushiswap v2
	39797: '0x2D4eB90118f6FBA1191F26Eb39f7FF0573F63C22', // energi
};

export const MULTICALL_ADDRESSES = {
	// https://github.com/makerdao/multicall
	// NOTE: IF A CHAIN DOES NOT HAVE MULTICALL SC: https://tonisives.com/blog/2022/06/24/how-to-use-ethereum-multicall-on-a-local-hardhat-node/

	// 1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441', // ethereum
	// "3": "0x53c43764255c17bd724f74c4ef150724ac50a3ed",
	// "4": "0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821",
	// "5": "0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e",

	1: '0x5ba1e12693dc8f9c48aad8770482f4739beed696', // multicall version 2
	// 3: "0x5ba1e12693dc8f9c48aad8770482f4739beed696", // multicall version 2
	// 4: "0x5ba1e12693dc8f9c48aad8770482f4739beed696", // multicall version 2
	// 5: "0x5ba1e12693dc8f9c48aad8770482f4739beed696", // multicall version 2
	// 42: "0x5ba1e12693dc8f9c48aad8770482f4739beed696", // multicall version 2

	56: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c', // Bsc is deployed by community: not officlally maintained
	100: '0xb5b692a88bdfc81ca69dcb1d924f59f0413a602a', // xdai
	137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507', // polygon
	246: '', // see https://tonisives.com/blog/2022/06/24/how-to-use-ethereum-multicall-on-a-local-hardhat-node/'
	10001: '0x5ba1e12693dc8f9c48aad8770482f4739beed696', // ethereumPOW multicall version 2
	39797: '0x0c44695d3F94e3027966E273c7E09bD481098781', // energi mainnet
	// 80001: "0x08411add0b5aa8ee47563b146743c13b3556c9cc"
};

export const MASTERNODE_TOKEN_ADDRESS =
	'0x0000000000000000000000000000000000000309';

export const MASTERNODE_REGISTRY_ADDRESS =
	'0x0000000000000000000000000000000000000302';

export const MASTERNODE_TOKEN_ABI = ENERGI_MASTERNODE_TOKEN_ABI;

export const MASTERNODE_REGISTRY_ABI = ENERGI_MASTERNODE_REGISTRY_ABI;

export const FACTORY_ABIS = {
	1: ETHEREUM_FACTORY_ABI, // ethereum
	56: BINANCE_FACTORY_ABI, // bnb smart chain
	100: XDAI_FACTORY_ABI, // xDai
	137: POLYGON_FACTORY_ABI, // Polygon (previous Matic)
	10001: ETHEREUM_FACTORY_ABI, // ethereumPOW
	39797: ENERGI_FACTORY_ABI, // energi
};

export const ROUTER_ABIS = {
	1: ETHEREUM_ROUTER_ABI, // ethereum
	56: BINANCE_ROUTER_ABI, // bnb smart chain
	100: XDAI_ROUTER_ABI, // xDai
	137: MATIC_ROUTER_ABI, // Polygon (previous Matic)
	10001: ETHEREUM_ROUTER_ABI, // ethereumPOW
	39797: ENERGI_ROUTER_ABI, // energi
};

export const PAIR_ABIS = {
	1: ETHEREUM_PAIR_ABI, // ethereum
	56: BINANCE_PAIR_ABI, // bnb smart chain
	100: XDAI_PAIR_ABI, // xDai
	137: MATIC_PAIR_ABI, // Polygon (previous Matic)
	10001: ETHEREUM_PAIR_ABI, // ethereumPOW
	39797: ENERGI_PAIR_ABI, // energi
};

export const BRIDGES = {
	56: 'https://www.binance.org/en/bridge', // bnb Smart Chain
	100: 'https://dai-bridge.poa.network', // xDai
	137: 'https://wallet.matic.network/bridge', // Polygon (previous Matic) WALLET = https://wallet.matic.network/
	39797: 'https://bridge.energi.network', // energi
};

export const ROUTER_METHODS = {
	swapExactTokensForTokens: {
		1: 'swapExactTokensForTokens',
		56: 'swapExactTokensForTokens',
		100: 'swapExactTokensForTokens',
		137: 'swapExactTokensForTokens',
		10001: 'swapExactTokensForTokens',
		39797: 'swapExactTokensForTokens',
	},
	swapTokensForExactTokens: {
		1: 'swapTokensForExactTokens',
		56: 'swapTokensForExactTokens',
		100: 'swapTokensForExactTokens',
		137: 'swapTokensForExactTokens',
		10001: 'swapTokensForExactTokens',
		39797: 'swapTokensForExactTokens',
	},
	swapExactTokensForETH: {
		1: 'swapExactTokensForETH',
		56: 'swapExactTokensForETH',
		100: 'swapExactTokensForETH',
		137: 'swapExactTokensForETH',
		10001: 'swapExactTokensForETH',
		39797: 'swapExactTokensForNRG',
	},
	swapETHForExactTokens: {
		1: 'swapETHForExactTokens',
		56: 'swapETHForExactTokens',
		100: 'swapETHForExactTokens',
		137: 'swapETHForExactTokens',
		10001: 'swapETHForExactTokens',
		39797: 'swapNRGForExactTokens',
	},
	swapExactETHForTokens: {
		1: 'swapExactETHForTokens',
		56: 'swapExactETHForTokens',
		100: 'swapExactETHForTokens',
		137: 'swapExactETHForTokens',
		10001: 'swapExactETHForTokens',
		39797: 'swapExactNRGForTokens',
	},
	swapTokensForExactETH: {
		1: 'swapTokensForExactETH',
		56: 'swapTokensForExactETH',
		100: 'swapTokensForExactETH',
		137: 'swapTokensForExactETH',
		10001: 'swapTokensForExactETH',
		39797: 'swapTokensForExactNRG',
	},
};

export const BLACKLIST = {
	1: [
		'0x495c7f3a713870f68f8b418b355c085dfdc412c3',
		'0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
		'0xe31debd7abff90b06bca21010dd860d8701fd901',
		'0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
		'0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
		'0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',
		// rebass tokens
		'0x9ea3b5b4ec044b70375236a281986106457b20ef',
		'0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
		'0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
		'0xfae9c647ad7d89e738aba720acf09af93dc535f7',
		'0x7296368fe9bcb25d3ecc19af13655b907818cc09',
	],
	56: [],
	100: [],
	137: [
		'0x0000000000000000000000000000000000001010', // Matic Token seems scam token: WMATIC is at address "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
	],
	10001: [
		'0x495c7f3a713870f68f8b418b355c085dfdc412c3',
		'0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
		'0xe31debd7abff90b06bca21010dd860d8701fd901',
		'0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
		'0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
		'0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',
		// rebass tokens
		'0x9ea3b5b4ec044b70375236a281986106457b20ef',
		'0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
		'0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
		'0xfae9c647ad7d89e738aba720acf09af93dc535f7',
		'0x7296368fe9bcb25d3ecc19af13655b907818cc09',
	],
	39797: [
		'0x0bD8ddF29982Fcb8f80A0Ef18644C75Bb4F84413', // ITSAtoken mainnet
		'0xbD578Cb977E3260BAdCB9C62E6bbA1886a0002bB', // duplicate BNT token, should be hidden forever: https://git.energi.software/energi/defi/swap/info/-/issues/22
		'0xd9bAFd5e1Dd109B46207A42467be273f550FF676', // OKB temp blacklisted: remove from blacklist once it is minted
		'0xdf13537f5a8c697bdeCB4870B249a1a9158b54c6', // CRO temp blacklisted: remove from blacklist once it is minted
		'0xDb1895e9002d94247A5e0bA44df06d716da02f51', // BSV temp blacklisted: remove from blacklist once it is minted
		'0x1d2C216ee8121e6C1A20Ff9053819BeF3a64f29d', // HT temp blacklisted: remove from blacklist once it is minted
		'0x4778d9690cd7d9697107aEF080d5B908ABEbb83d', // LEO temp blacklisted: remove from blacklist once it is minted
		'0xad3EaC6a2EF827833880a10592C6E46605E4F9d6', // VGX temp blacklisted: remove from blacklist once it is minted
		'0x70ADA65dE12E5c0F6ff7E58Cad734a813cA50bf3', // AR temp blacklisted: remove from blacklist once it is minted
		'0x78C6efEB603e48C6F64EddEC7131FcA4915E2014', // MDX temp blacklisted: remove from blacklist once it is minted
		'0xD83EDa5A201532291c1fB71e6E3471b8EF3575aC', // NXM temp blacklisted: remove from blacklist once it is minted
	].map(address => address.toLowerCase()),
};

export const CURRENCIES = [
	'aud',
	'cad',
	'chf',
	'cny',
	'dkk',
	'eur',
	'gbp',
	'huf',
	'idr',
	'jpy',
	'nok',
	'pln',
	'rub',
	'sek',
	'thb',
	'try',
	'usd',
	'zar',
];

export const LISTINFOS = [
	'none',
	'balance',
	'fiat',
	'tokenprice',
	'dextokenprice', // NOT at this moment: would need extra SC calls
];

export const SOUNDS = [
	'ping',
	'pong',
	'coinCollect',
	'success',
	'sonar',
	'steelcup',
	'harp',
]; // names should reference the filenames in /src/assets/sounds

export const getMainSymbol = symbol => TRANSFORM_SYMBOLS[symbol] || symbol;

export const getMainName = (name, symbol) =>
	TRANSFORM_COINNAMES[symbol] || name;

export const URL_COINGECKO_PRICES =
	'https://api.coingecko.com/api/v3/simple/price';

export const URL_COINGECKO_LIST = 'https://api.coingecko.com/api/v3/coins/list';

export const TOKENINFO = GENERAL_TOKENINFO;

export const IS_TESTNET = {
	42: true, // kovan
	49797: true, // energi testnet
};

export const ENERGI_CHAINIDS = {
	39797: true,
	49797: true,
};

export const ETHEREUM_POW_CHAINIDS = {
	10001: true,
};

export const IS_ENERGI_CHAINID = chainId => !!ENERGI_CHAINIDS[chainId];

export const TOKEN_TEMPLATES = {
	1: {
		address: WRAPPED_ADDRESSES[1],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/ETH.svg',
		name: 'Wrapped Ether',
		symbol: 'WETH',
		visualDecimals: 4,
	},
	56: {
		address: WRAPPED_ADDRESSES[56],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/BNB.svg',
		name: 'Wrapped BNB Coin',
		symbol: 'WBNB',
		visualDecimals: 4,
	},
	100: {
		address: WRAPPED_ADDRESSES[100],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/ETH.svg',
		name: 'Wrapped Ether',
		symbol: 'WETH',
		visualDecimals: 4,
	},
	137: {
		address: WRAPPED_ADDRESSES[137],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/MATIC.svg',
		name: 'Wrapped Matic',
		symbol: 'WMATIC',
		visualDecimals: 4,
	},
	10001: {
		address: WRAPPED_ADDRESSES[10001],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/ETH.svg',
		name: 'Wrapped EtherPOW',
		symbol: 'WETHW',
		visualDecimals: 4,
	},
	39797: {
		address: WRAPPED_ADDRESSES[39797],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/NRG.svg',
		name: 'Wrapped Energi',
		symbol: 'WNRG',
		visualDecimals: 4,
	},
};

export const SC_MULTICAL = {
	// if we use the SC multical for catching up token balances
	1: true,
	56: true,
	100: true,
	137: true,
	10001: true,
	39797: true,
};

export const ALTERNATIVE_BASE_TRADE_TOKENS = {
	1: [
		{
			address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 6,
			image: '/tokens/USDC.svg',
			name: 'USD Coin',
			symbol: 'USDC',
			visualDecimals: 4,
		},
		{
			address: '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/USDT.svg',
			name: 'USD Tether',
			symbol: 'USDT',
			visualDecimals: 4,
		},
	],
	56: [
		{
			address: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // BUSD
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/BUSD.svg',
			name: 'BUSD Token',
			symbol: 'BUSD',
			visualDecimals: 4,
		},
		{
			address: '0x55d398326f99059ff775485246999027b3197955', // USDT
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/USDT.svg',
			name: 'USD Tether',
			symbol: 'USDT',
			visualDecimals: 4,
		},
	],
	100: [
		{
			address: '0x71850b7e9ee3f13ab46d67167341e4bdc905eef9', // HNY
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/HNY.svg',
			name: 'Honey',
			symbol: 'HNY',
			visualDecimals: 4,
		},
		{
			address: '0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1', // WETH
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/ETH.svg',
			name: 'Ethereum',
			symbol: 'ETH',
			visualDecimals: 4,
		},
	],
	137: [
		{
			address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 6,
			image: '/tokens/USDC.svg',
			name: 'USD Coin',
			symbol: 'USDC',
			visualDecimals: 4,
		},
		{
			address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/ETH.svg',
			name: 'Ethereum',
			symbol: 'ETH',
			visualDecimals: 4,
		},
	],
	10001: [
		{
			address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 6,
			image: '/tokens/USDC.svg',
			name: 'USD Coin',
			symbol: 'USDC',
			visualDecimals: 4,
		},
		{
			address: '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/USDT.svg',
			name: 'USD Tether',
			symbol: 'USDT',
			visualDecimals: 4,
		},
	],
};

if (withTestnet) {
	if (withKovanOnTestnet) {
		/* ******************************************************************************* */
		// add Kovan testnet
		RPC_URLS[42] =
			'https://kovan.infura.io/v3/f11d3041f5334ae593fc27b52555f3da'; // OK
		JSON_APIS_WSS[42] =
			'wss://kovan.infura.io/ws/v3/f11d3041f5334ae593fc27b52555f3da'; // OK
		WRAPPED_ADDRESSES[42] =
			'0xd0a1e359811322d97991e03f863a0c30c2cf029c'.toLowerCase(); // OK
		STABLECOIN_REF_ADDRESSES[42] =
			'0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa'.toLowerCase();
		BASE_TRADE_TOKENS[42] =
			'0xd0a1e359811322d97991e03f863a0c30c2cf029c'.toLowerCase(); // WETH
		// TOKEN_LISTS[49797] = tokenlistEnergiTestnet;
		NETWORK_NAMES[42] = 'Kovan Testnet';
		NETWORK_ICONS[42] = '/tokens/ETH.svg';

		EXPLORER_URLS[42] = 'https://kovan.etherscan.io';

		CHAIN_SYMBOLS[42] = 'KETH';

		BN_GAS_TRADE[42] = {
			ONE_STEP: toBN('400000'), // estimated, high taken because MetaMask changes these values
			EXTRA_STEP: toBN('100000'), // estimated, high taken because MetaMask changes these values
		};
		BN_GAS_WRAPPING[42] = {
			WRAP: toBN('67557'),
			UNWRAP: toBN('54023'),
		};

		TRADES_DEADLINES_MINS[42] = 5;
		FACTORY_ADDRESSES[42] = FACTORY_ADDRESSES[1];
		ROUTER_ADDRESSES[42] = ROUTER_ADDRESSES[1];
		FACTORY_ABIS[42] = KOVAN_FACTORY_ABI;
		ROUTER_ABIS[42] = KOVAN_ROUTER_ABI;
		PAIR_ABIS[42] = KOVAN_PAIR_ABI;
		// BRIDGES[42] = 'https://bridge.test.energi.network';
		MULTICALL_ADDRESSES[42] = '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a'; // OK
		BLACKLIST[42] = [];
		SC_MULTICAL[42] = true;
		ROUTER_METHODS.swapExactTokensForTokens[42] =
			ROUTER_METHODS.swapExactTokensForTokens[1];
		ROUTER_METHODS.swapTokensForExactTokens[42] =
			ROUTER_METHODS.swapTokensForExactTokens[1];
		ROUTER_METHODS.swapExactTokensForETH[42] =
			ROUTER_METHODS.swapExactTokensForETH[1];
		ROUTER_METHODS.swapETHForExactTokens[42] =
			ROUTER_METHODS.swapETHForExactTokens[1];
		ROUTER_METHODS.swapExactETHForTokens[42] =
			ROUTER_METHODS.swapExactETHForTokens[1];
		ROUTER_METHODS.swapTokensForExactETH[42] =
			ROUTER_METHODS.swapTokensForExactETH[1];
		TOKEN_TEMPLATES[42] = {
			address: WRAPPED_ADDRESSES[42],
			balance: BN_ZERO,
			contract_type: 'ERC20',
			decimals: 18,
			image: '/tokens/ETH.svg',
			name: 'Wrapped Ether',
			symbol: 'WETH',
			visualDecimals: 4,
		};
	}
	/* ******************************************************************************* */
	// add Energi testnet
	RPC_URLS[49797] = 'https://nodeapi.test.energi.network';
	JSON_APIS_WSS[49797] = 'wss://nodeapi.test.energi.network/ws';
	RPC_URLS_ENERGI_NODE[49797] = 'https://nodeapi.test.energi.network';

	WRAPPED_ADDRESSES[49797] =
		'0x184f7b12f29d675f34ff816528adbfd0e0501ad4'.toLowerCase();
	STABLECOIN_REF_ADDRESSES[49797] =
		// '0x3e798553502042088783b83e4b62e22152e8717e'.toLowerCase(); // DAI
		'0x70Db5FEE65888E91C19aBc9b5e11075c2dDa1073'.toLowerCase(); // USDE
	BASE_TRADE_TOKENS[49797] =
		'0x184f7b12f29d675f34ff816528adbfd0e0501ad4'.toLowerCase(); // WNRG
	// TOKEN_LISTS[49797] = tokenlistEnergiTestnet;
	NETWORK_NAMES[49797] = 'Energi Testnet';
	NETWORK_ICONS[49797] = '/tokens/NRG.svg';
	EXPLORER_URLS[49797] = 'https://explorer.test.energi.network';
	CHAIN_SYMBOLS[49797] = 'tNRG';
	TRADES_DEADLINES_MINS[49797] = 5;
	FACTORY_ADDRESSES[49797] = '0xb1F94d0d737755342a9d0cD84f5eBEAB4c2DaA54';
	ROUTER_ADDRESSES[49797] = '0x07CC883c0B6E45B00E91840dcCdF99D66551Fa81'; // Energiswap Router
	FACTORY_ABIS[49797] = ENERGI_FACTORY_ABI;
	ROUTER_ABIS[49797] = ENERGI_ROUTER_ABI;
	PAIR_ABIS[49797] = ENERGI_PAIR_ABI;
	BRIDGES[49797] = 'https://bridge.test.energi.network';
	MULTICALL_ADDRESSES[49797] = '0x9802d5160660622642a24beE9EF6625ed4Ad0838';
	BLACKLIST[49797] = [];
	SC_MULTICAL[49797] = true;
	ROUTER_METHODS.swapExactTokensForTokens[49797] = 'swapExactTokensForTokens';
	ROUTER_METHODS.swapTokensForExactTokens[49797] = 'swapTokensForExactTokens';
	ROUTER_METHODS.swapExactTokensForETH[49797] = 'swapExactTokensForNRG';
	ROUTER_METHODS.swapETHForExactTokens[49797] = 'swapNRGForExactTokens';
	ROUTER_METHODS.swapExactETHForTokens[49797] = 'swapExactNRGForTokens';
	ROUTER_METHODS.swapTokensForExactETH[49797] = 'swapTokensForExactNRG';
	TOKEN_TEMPLATES[49797] = {
		address: WRAPPED_ADDRESSES[49797],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/NRG.svg',
		name: 'Wrapped Energi',
		symbol: 'WNRG',
		visualDecimals: 4,
	};
	BN_GAS_TRADE[49797] = {
		// ONE_STEP: toBN('834005'),
		// EXTRA_STEP: toBN('713149'),
		ONE_STEP: toBN('11000000'), // estimated, high taken because MetaMask changes these values
		EXTRA_STEP: toBN('8600000'), // estimated, high taken because MetaMask changes these values
	};
	BN_GAS_WRAPPING[49797] = {
		WRAP: toBN('65399'),
		UNWRAP: toBN('78731'),
	};
}

export const PLACEHOLDER_ICON = '/tokens/placeholder.svg';
export const DISCORD_URL = 'https://discord.com/invite/itsawallet/';
export const TWITTER_URL = 'https://twitter.com/itsawallet';
export const YOUTUBE_URL = 'https://www.youtube.com/@itsaorganization';

export const SILVER_NFT_URL =
	'https://opensea.io/assets?search[toggles][0]=BUY_NOW&search[query]=itsa%20silver%20lifetime';
export const GOLD_NFT_URL =
	'https://opensea.io/assets?search[toggles][0]=BUY_NOW&search[query]=itsa%20gold%20lifetime';
export const PLATINUM_NFT_URL =
	'https://opensea.io/assets?search[toggles][0]=BUY_NOW&search[query]=itsa%20platinum%20lifetime';

export const MAIL_TO =
	'https://docs.google.com/forms/d/e/1FAIpQLSfZAZV0JERTdPaCrHMorXEkXVA1TRi5RMxB03nrHDQ-7kMUVA/viewform';
export const CALCULATE_MINIMUM_TRADE_AMOUNT = false; // when false, the estimated trdae amount will be calculated, ignoring slippage because of front running

// export const GAS_LIMIT_SAFETY_MULTIPLIER_HARDWARE_DEVICES = 2; // when changing, it could be hardware txs will fail

export const HEX0 = '0x0';

export const FONT_SIZE_DECIMALS = '0.75em';

export const GAS_PERCENTAGES = {
	// when going above 40%, it could be hardware txs will fail
	1: -10,
	2: -5,
	3: 0,
	4: 5,
	5: 10,
	6: 15,
	7: 20,
	8: 25,
	9: 30,
};

export const DEFAULT_EXTRA_HARDWARE_GASPRICE = 4;
export const MAX_EXTRA_GASPRICE_HARDWARE_WALLET = Object.keys(
	GAS_PERCENTAGES,
).reduce((cum, item) => Math.max(cum, item), 0);

export const SHOW_SEARCHFIELD_ON_CUSTOMTOKENS_COUNT = 5;

export const DEFAULT_LOCKTIME_ACTIVATED = true;
export const DEFAULT_LOCKTIME_MINS = 2;
export const SHOW_ABOUT_TO_SCREENLOCK_REMAINING_SEC = 60;

export const TIMEOUT_COPY_MESSAGE = 1500; // ms
export const GENERAL_MESSAGE_TIMEOUT = 4000; // ms
export const TIMEOUT_SETTINGS_MESSAGE = 20000; // ms
export const TIMEOUT_INFORM_EXCHANGE_MESSAGE = 20000; // ms
export const TIMEOUT_INTRO_MESSAGE = 30000; // ms
export const WS_TIMEOUT_SEC = 30; // sec, note that 15 sec is the default value for Web3.providers.WebsocketProvider

export const WARN_PRICEIMPACT_ABOVE = 2;

export const GAS_LIMIT_MASTERNODE_DEPOSIT = '3000000';
export const GAS_LIMIT_MASTERNODE_WITHDRAW = '3000000';
export const GAS_LIMIT_ANNOUNCE = '1000000';
export const GAS_LIMIT_DENOUNCE = '300000';

export const LIMIT_TOKENS = 500;

export const DEXES_NAMES = {
	1: 'Uniswap-v2',
	42: 'Kovan Uniswap-v2',
	56: 'PancakeSwap',
	137: 'QuickSwap',
	10001: 'Uniswap-v2 EthereumPOW',
	39797: 'EnergiSwap',
	49797: 'EnergiSwap Testnet',
};

export const DEXES_ICON = {
	1: '/exchanges/uniswap.svg',
	56: '/exchanges/pancakeswap.svg',
	137: '/exchanges/quickswap.svg',
	10001: '/exchanges/uniswap-ethw.svg',
	39797: '/exchanges/energiswap.svg',
	49797: '/exchanges/energiswap.svg',
};

export const SHOW_MAX_UNIQUE_NFTS = 10; // unique type per network: to prevent browser from having rendering problems when too many svg's need to be rendered
