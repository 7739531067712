import { useState, useContext, useEffect } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty, cryptowalletCtx } from '@itsa.io/web3utils';
import addressMatch from 'utils/address-match';
import { getTokenByAddress } from 'utils/smartcontracts/token';
import {
	customTokens as apiCustomTokens,
	requestRegisterToken,
} from 'api/customTokens';
import { merge } from 'lodash';

const localStorageCustomTokens = localStorageProperty('customtokens');

const sortFn = (a, b) => {
	const aAddressLowerCase = a.address.toLowerCase();
	const bAddressLowerCase = b.address.toLowerCase();
	if (aAddressLowerCase < bAddressLowerCase) {
		return -1;
	}
	if (aAddressLowerCase > bAddressLowerCase) {
		return 1;
	}
	return 0;
};

const apiGetTokens = async (chainid, customTokensChain) => {
	const apiTokens = apiCustomTokens(
		chainid,
		customTokensChain.map(ct => ct.address),
	);
	return apiTokens;
};

const useCustomTokens = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const [customTokens, setCustomTokens] = useState(
		localStorageCustomTokens.get() || [],
	);
	const [customTokensChain, setCustomTokensChain] = useState([]);

	const refreshSymbols = async (chainid, customtokenschain) => {
		const newCustomTokensChain = [...customtokenschain];
		const tokens = await apiGetTokens(chainid, newCustomTokensChain);
		tokens.forEach(t => {
			const newCustomToken = newCustomTokensChain.find(ct =>
				addressMatch(ct.address, t.address),
			);
			if (newCustomToken) {
				merge(newCustomToken, t);
			}
		});

		const removeAddresses = [];
		// for those tokens that have no api record: get it from either localstorage, or from SmartContract
		// eslint-disable-next-line no-restricted-syntax
		for (const customtoken of newCustomTokensChain) {
			if (!customtoken.decimals) {
				const token = await getTokenByAddress(chainId, customtoken.address);
				if (!token) {
					removeAddresses.push(customtoken.address);
				} else {
					merge(customtoken, token);
					requestRegisterToken(chainId, customtoken.address);
				}
			}
		}
		removeAddresses.forEach(address => {
			const index = newCustomTokensChain.find(ct =>
				addressMatch(ct.address, address),
			);
			if (index !== -1) {
				newCustomTokensChain.splice(index, 1);
			}
		});
		setCustomTokensChain(newCustomTokensChain);
	};

	useEffect(() => {
		if (chainId) {
			const newCustomTokensChain = customTokens.filter(
				ct => ct.chainId === chainId,
			);
			refreshSymbols(chainId, newCustomTokensChain);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, customTokens]);

	const addCustomToken = address => {
		if (chainId) {
			let customToken = customTokens.find(
				ct => addressMatch(ct.address, address) && ct.chainId === chainId,
			);
			if (!customToken) {
				customToken = {
					chainId,
					address,
				};
				const newCustomTokens = [...customTokens];
				newCustomTokens.push(customToken);
				newCustomTokens.sort(sortFn);
				setCustomTokens(newCustomTokens);
				localStorageCustomTokens.set(newCustomTokens);
			}
		}
	};

	const removeCustomToken = contact => {
		const newCustomTokens = [...customTokens];
		const customTokenIndex = newCustomTokens.findIndex(
			c => addressMatch(c.address, contact.address) && c.chainId === chainId,
		);
		if (customTokenIndex !== -1) {
			newCustomTokens.splice(customTokenIndex, 1);
			setCustomTokens(newCustomTokens);
			localStorageCustomTokens.set(newCustomTokens);
		}
	};

	return {
		addCustomToken,
		customTokens: customTokensChain,
		removeCustomToken,
	};
};

const store = createStore(useCustomTokens);

export const { Provider } = store;
export default store.ctx;
