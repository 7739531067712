/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getTokenlist = async (chainId, walletAddress) => {
	let tokens;
	try {
		const response = await axios.post(`${API_URL}/tokenlistwithbalance`, {
			chainid: chainId,
			address: walletAddress,
		});
		if (response.status === 200 && response.data?.success) {
			tokens = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		tokens = [];
	}
	return tokens;
};

export default getTokenlist;
