/* eslint-disable prefer-destructuring */
import { useEffect, useContext, useState } from 'react';
import createStore from 'ctx-provider';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import addressMatch from 'utils/address-match';
import tokenListCtx from 'context/tokenlist';
import { BASE_TRADE_TOKENS } from 'config/constants';

const useWrappedToken = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const [baseTradeToken, setBaseTradeToken] = useState();
	const { data, error, isLoading } = useContext(tokenListCtx);

	useEffect(() => {
		let newBaseTradeToken;
		if (!error && !isLoading) {
			const baseTradeTokenAddress = BASE_TRADE_TOKENS[chainId];
			newBaseTradeToken = data.find(t =>
				addressMatch(t.address, baseTradeTokenAddress),
			);
		}
		if (
			!newBaseTradeToken ||
			newBaseTradeToken.address !== baseTradeToken?.address
		) {
			setBaseTradeToken(newBaseTradeToken);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return baseTradeToken;
};

const store = createStore(useWrappedToken);

export const { Provider } = store;
export default store.ctx;
