import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		zIndex: 9996,
		maxWidth: 600,
	},
	alert: {
		width: '100%',
		fontSize: 16,
		fontWeight: 500,
		padding: theme.spacing(1.5, 2),
		borderRadius: 6,
		color: '#212121',
	},
}));
