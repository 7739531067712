import React, { useContext } from 'react';
import { useToggle } from 'react-use';
import { AppBar, Toolbar, NetworkConnectButton } from '@itsa.io/ui';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import contactsCtx from 'context/contacts';
import expertModeCtx from 'context/expertmode';
import darkmodeCtx from 'context/darkmode';
import SwipeableMobileMenu from 'components/common/SwipeableMobileMenu';
import {
	NETWORK_ICONS,
	NETWORK_NAMES,
	EXPLORER_URLS,
	SEVERITIES,
} from 'config/constants';
import useStyles from 'styles/components/layout/Header';
import useAlert from 'hooks/useAlert';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';

const Header = props => {
	const classes = useStyles();
	const { t } = useIntl();
	const alert = useAlert();
	const { darkmode } = useContext(darkmodeCtx);
	const { contacts } = useContext(contactsCtx);
	const { expertMode } = useContext(expertModeCtx);
	const [openWalletModal, setOpenWalletModal] = useToggle(false);
	const expertModeText = expertMode && (
		<span className={classes.proMode}>PRO</span>
	);
	const { chainId, disconnect } = useContext(cryptowalletCtx);
	const expectedChainIds = Object.keys(NETWORK_NAMES).map(chainid =>
		parseInt(chainid, 10),
	);
	const networkname = NETWORK_NAMES[chainId] || '';

	const handleMessage = (message, level) => {
		alert(message, SEVERITIES[level] || 'success');
	};

	const createAddressBook = () => {
		const addressbook = {};
		contacts.forEach(contact => {
			if (contact.name) {
				addressbook[contact.address.toLowerCase()] = contact.name;
			}
		});
		return addressbook;
	};

	const networkConnectButton = (
		<NetworkConnectButton
			addressBook={createAddressBook()}
			buttonOpened={openWalletModal}
			chainId={expectedChainIds}
			networkIcons={NETWORK_ICONS}
			networkNames={NETWORK_NAMES}
			explorerUrls={EXPLORER_URLS}
			classes={{
				root: classes.metamaskRoot,
			}}
			onDisconnect={disconnect}
			onMessage={handleMessage}
			onToggle={setOpenWalletModal}
			labelAddressCopied={t('metamask_box.copied')}
			// labelDisconnect={t('metamask_box.disconnect_metamask')}
			labelNetwork={`${t('metamask_box.selected_network')} ${networkname} ${t(
				'metamask_box.network',
			)}`}
		/>
	);

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	return (
		<AppBar position="fixed" className={classes.root}>
			<Toolbar className={classes.toolbar}>
				<div className={classes.walletLogoName}>
					{logo}
					{expertModeText}
				</div>
				<div className={classes.walletConnectContainer}>
					{networkConnectButton}
				</div>
				<SwipeableMobileMenu {...props} />
			</Toolbar>
		</AppBar>
	);
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
