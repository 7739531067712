import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useIntl, later, formatBN } from '@itsa.io/web3utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import gaspriceCtx from 'context/gasprice';
import useStyles from 'styles/components/common/CurrentGasPrice';

const CurrentGasPrice = ({ open }) => {
	const classes = useStyles();
	const { t } = useIntl();
	const { gasprice } = useContext(gaspriceCtx);
	const timerRef = useRef();
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		timerRef.current = later(forceUpdate, 1000, true);
		return () => {
			timerRef.current.cancel();
		};
	}, []);

	let gaspriceFormatted;
	let units;
	if (gasprice) {
		gaspriceFormatted = formatBN(gasprice, { assetDigits: 9, decimals: 0 });
		units = 'Gwei';
	}

	return (
		<div
			className={clsx(classes.root, {
				[classes.menuOpen]: open,
			})}
		>
			{t('common.gasprice')}
			<span className={classes.gasprice}>{gaspriceFormatted}</span>
			<span className={classes.units}>{units}</span>
		</div>
	);
};

CurrentGasPrice.defaultProps = {
	open: false,
};

CurrentGasPrice.propTypes = {
	open: PropTypes.bool,
};

export default CurrentGasPrice;
