import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useToggle } from 'react-use';
import { unmute } from 'utils/play-sound';
import {
	ButtonBase,
	IconButton,
	Link,
	Box,
	List,
	ListItem as MuiListItem,
	ListItemIcon,
	ListItemText as MuiListItemText,
	Drawer as MuiDrawer,
	Avatar as TokenImg,
	useTheme,
	useMediaQuery,
	NetworkConnectButton,
} from '@itsa.io/ui';
import {
	Menu as MenuIcon,
	Home as HomeIcon,
	Twitter as TwitterIcon,
	YouTube as YouTubeIcon,
	Settings as SettingsIcon,
	PostAdd as PostAddIcon,
	Email as EmailIcon,
	VisibilityOff as VisibilityOffIcon,
	VolumeUp as VolumeUpIcon,
	VolumeOff as VolumeOffIcon,
} from '@itsa.io/ui/icons';
import { useIntl, toBN, formatBN, cryptowalletCtx } from '@itsa.io/web3utils';
import { utils } from 'web3';
import contactsCtx from 'context/contacts';
import selectedtokenCtx from 'context/selectedtoken';
import navigatorCtx from 'context/navigator';
import showpossiblespamtokensCtx from 'context/showpossiblespamtokens';
import lastblockvisibleCtx from 'context/lastblockvisible';
import gaspricevisibleCtx from 'context/gaspricevisible';
import listinfoCtx from 'context/listinfo';
import currencyCtx from 'context/currency';
import expertModeCtx from 'context/expertmode';
import tokenListCtx from 'context/tokenlist';
import locktimerCtx from 'context/locktimer';
import togglemuteCtx from 'context/togglemute';
import subscriptionCtx from 'context/subscription';
import darkmodeCtx from 'context/darkmode';
import useLatestWalletVersion from 'hooks/useLatestWalletVersion';
// import DiscordIcon from 'assets/images/discord.svg';
import MasternodeIndicator from 'components/common/MasternodeIndicator';
import ExclamationIcon from 'components/common/ExclamationIcon';
import BlockHeight from 'components/common/BlockHeight';
import CurrentGasPrice from 'components/common/CurrentGasPrice';
import {
	WRAPPED_ADDRESSES,
	NETWORK_ICONS,
	NETWORK_NAMES,
	IS_ENERGI_CHAINID,
	EXPLORER_URLS,
	getMainSymbol,
	getMainName,
	// DISCORD_URL,
	TWITTER_URL,
	YOUTUBE_URL,
	MAIL_TO,
	PAGES_NAMES,
} from 'config/constants';
import { getTokenPrice, getDexTokenPrice } from 'utils/get-tokenprice';
import { toFiat, toFiatFromDexPrices } from 'utils/tokenpriceToFiat';
import addressMatch from 'utils/address-match';
import isNewerVersion from 'utils/is-newer-version';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';

import useStyles from 'styles/components/common/LeftSidebar';

const { isBN } = utils;
const BN_ZERO = toBN('0');

const isMoreThanOneThousand = (amountBN, decimals) => {
	let amount = amountBN.toString();
	amount = amount.substring(0, amount.length - decimals);
	return amount.length > 3;
};

const isMoreThanOneMil = (amountBN, decimals) => {
	let amount = amountBN.toString();
	amount = amount.substring(0, amount.length - decimals);
	return amount.length > 6;
};

const LeftSideBar = props => {
	const propsVersion = props.pageContext.version;
	const { t } = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const { darkmode } = useContext(darkmodeCtx);
	const { contacts } = useContext(contactsCtx);
	const { showPossibleSpamTokens } = useContext(showpossiblespamtokensCtx);
	const { chainId, networkConnected, disconnect, address } =
		useContext(cryptowalletCtx);
	const latestWalletVersion = useLatestWalletVersion(propsVersion);
	const { selectedToken, setTokenAddress } = useContext(selectedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { listinfo } = useContext(listinfoCtx);
	const { currency } = useContext(currencyCtx);
	const { expertMode } = useContext(expertModeCtx);
	const { lastBlockVisible } = useContext(lastblockvisibleCtx);
	const { gasPriceVisible } = useContext(gaspricevisibleCtx);
	const { lock } = useContext(locktimerCtx);
	const { muted, toggleMute } = useContext(togglemuteCtx);
	const {
		data: tokenList,
		// error,
		isLoading: loading,
	} = useContext(tokenListCtx);
	const subscription = useContext(subscriptionCtx);
	const { subscriptionChain, hasFullAccess } = subscription;
	const isSubscriptionExpired = subscriptionChain && !hasFullAccess;
	const largeScreenSize = useMediaQuery(theme.breakpoints.up('lg'));
	const expectedChainIds = Object.keys(NETWORK_NAMES).map(chainid =>
		parseInt(chainid, 10),
	);
	const [open, setOpen] = useState(false);
	const [newerWalletVersion, setNewerWalletVersion] = useState(false);
	const [openWalletModal, setOpenWalletModal] = useToggle(false);
	const [wrappedTokenAddress, setWrappedTokenAddress] = useState(
		WRAPPED_ADDRESSES[chainId]?.toLowerCase(),
	);
	const networkname = address ? NETWORK_NAMES[chainId] || '' : '';
	let walletLogo;
	let walletNetwork;
	const expertModeText = expertMode && (
		<span className={classes.proMode}>PRO</span>
	);

	const checkWalletVersion = () => {
		const versionIsNewer = isNewerVersion(propsVersion, latestWalletVersion);
		setNewerWalletVersion(versionIsNewer);
	};

	useEffect(() => {
		setWrappedTokenAddress(WRAPPED_ADDRESSES[chainId]?.toLowerCase());
	}, [chainId]);

	useEffect(() => {
		if (latestWalletVersion) {
			checkWalletVersion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestWalletVersion]);

	const handleDrawer = () => {
		setOpen(!open);
	};

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	if (open || largeScreenSize) {
		let masternodeindicator;
		if (
			IS_ENERGI_CHAINID(chainId) &&
			!loading &&
			tokenList[0] &&
			tokenList[0].masternodeStatus > 0 &&
			tokenList[0].collateral &&
			tokenList[0].collateral.gt(BN_ZERO)
		) {
			// tokenList[0] is information about the NRG coin on the wallet
			masternodeindicator = <MasternodeIndicator coin={tokenList[0]} />;
		}
		walletLogo = (
			<div className={classes.walletLogoName}>
				{logo}
				{expertModeText}
			</div>
		);
		walletNetwork = (
			<div className={classes.walletNetworkName}>
				{networkname}
				{masternodeindicator}
			</div>
		);
	}

	let tokeninfoText;

	if (
		listinfo !== 'none' &&
		listinfo !== 'balance' &&
		!loading &&
		NETWORK_NAMES[chainId] &&
		tokenList.length > 0
	) {
		tokeninfoText = t(`settings.list_info.leftmenu_${listinfo}`);
		if (
			listinfo === 'fiat' ||
			listinfo === 'tokenprice' ||
			listinfo === 'dextokenprice'
		) {
			tokeninfoText = `${tokeninfoText} ${currency.toUpperCase()}`;
		}
	}

	const menuicon = (
		<ExclamationIcon isActivate={isSubscriptionExpired || newerWalletVersion}>
			<MenuIcon />
		</ExclamationIcon>
	);

	const toolbarContent = (
		<div className={classes.toolbar}>
			<IconButton
				color="inherit"
				aria-label="open drawer"
				onClick={handleDrawer}
				className={clsx(classes.menuButton)}
			>
				{menuicon}
			</IconButton>
			{walletLogo}
		</div>
	);

	const listInfoSettingContent = (
		<Box
			className={clsx(classes.listInfoSetting, {
				[classes.listInfoSettingOpen]: open,
			})}
		>
			{tokeninfoText}
		</Box>
	);

	const handleSelectMenuItem = tokenAddress => {
		setTokenAddress(tokenAddress);
		setPage(PAGES_NAMES.TOKEN);
		if (open && !largeScreenSize) {
			setOpen(false);
		}
	};

	let wrappedToken;
	if (tokenList[0] && addressMatch(tokenList[0].address, wrappedTokenAddress)) {
		// eslint-disable-next-line prefer-destructuring
		wrappedToken = tokenList[0];
	}

	const menuitems = tokenList
		.filter(token => {
			if (!showPossibleSpamTokens && token.possibleSpam) {
				return false;
			}
			return (
				(isBN(token.balance) && token.balance.gt(BN_ZERO)) ||
				addressMatch(token.address, wrappedTokenAddress) ||
				token.hasPendingTransaction
			);
		})
		.map((token, i) => {
			let numbers;
			let amount;
			let totalBalance = isBN(token.balance) ? token.balance : toBN('0');
			const isCoin = token.address.toLowerCase() === wrappedTokenAddress;

			if (isCoin) {
				const coinBalance = isBN(token.coinBalance)
					? token.coinBalance
					: toBN('0');
				totalBalance = totalBalance.add(coinBalance);
				const hasCollateral = token.collateral?.gt(BN_ZERO);
				if (hasCollateral) {
					totalBalance = totalBalance.add(token.collateral);
				}
			}

			if (listinfo === 'balance') {
				if (i === 0 || (totalBalance && totalBalance.gt(BN_ZERO))) {
					const moreThanOneThousand = isMoreThanOneThousand(
						totalBalance,
						token.decimals,
					);
					const moreThanOneMil = isMoreThanOneMil(totalBalance, token.decimals);
					amount = formatBN(totalBalance, {
						assetDigits: token.decimals,
						// minDigits: 5,
						decimals: moreThanOneThousand ? 2 : token.visualDecimals,
						withSuffix: moreThanOneMil,
						formatHTML: true,
						onlySignificant: true,
					});
					numbers = (
						<Box
							className={classes.listNumbers}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: amount }}
						/>
					);
				}
			} else if (listinfo === 'fiat') {
				if (totalBalance && totalBalance.gt(BN_ZERO)) {
					const prices = token.prices || {};
					const fiatBN = token.calculateTotalAssetPriceFromDexPrice
						? toFiatFromDexPrices(
								totalBalance,
								token.reserves0BN,
								token.reserves1BN,
								token.currencyprices,
								currency,
								token.decimals,
								wrappedToken,
						  )
						: toFiat(totalBalance, prices, currency, token.decimals);
					amount = formatBN(fiatBN, {
						assetDigits: 18, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
						formatHTML: true,
					});
					numbers = (
						<Box
							className={classes.listNumbers}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: amount }}
						/>
					);
				}
			} else if (listinfo === 'tokenprice') {
				if (totalBalance && totalBalance.gt(BN_ZERO)) {
					amount = getTokenPrice(token, currency, {
						minDigits: 3,
						decimals: 'auto',
						formatHTML: true,
					});
					numbers = (
						<Box
							className={classes.listNumbers}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: amount }}
						/>
					);
				}
			} else if (listinfo === 'dextokenprice') {
				if (totalBalance && totalBalance.gt(BN_ZERO)) {
					amount = getDexTokenPrice(token, currency, {
						minDigits: 3,
						decimals: 'auto',
						formatHTML: true,
					});
					numbers = (
						<Box
							className={classes.listNumbers}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: amount }}
						/>
					);
				}
			}
			return (
				<MuiListItem
					button
					component="li"
					className={clsx(classes.listItem, {
						[classes.listItemOpen]: open,
					})}
					key={token.address}
					onClick={() => handleSelectMenuItem(token.address)}
					selected={token.address === selectedToken?.address}
				>
					<ListItemIcon
						className={clsx(classes.listItemIcon, {
							[classes.listItemIconOpen]: open,
						})}
					>
						<TokenImg
							className={clsx(classes.tokenImg, {
								[classes.pendingTransaction]: token.hasPendingTransaction,
							})}
							alt={getMainName(token.name, token.symbol)}
							src={token.image}
						>
							<TokenImg
								className={clsx(classes.tokenImg, classes.tokenImgUnknown, {
									[classes.pendingTransaction]: token.hasPendingTransaction,
								})}
								alt={getMainName(token.name, token.symbol)}
								src={token.logoURI}
							>
								?
							</TokenImg>
						</TokenImg>
					</ListItemIcon>
					<MuiListItemText
						className={clsx(classes.listItemText, {
							[classes.listItemTextOpen]: open,
						})}
						primary={getMainSymbol(token.symbol)}
					/>
					{numbers}
				</MuiListItem>
			);
		});

	let tokenlistMenu;
	if (networkConnected && NETWORK_NAMES[chainId]) {
		tokenlistMenu = <List className={classes.list}>{menuitems}</List>;
	}

	const createAddressBook = () => {
		const addressbook = {};
		contacts.forEach(contact => {
			if (contact.name) {
				addressbook[contact.address.toLowerCase()] = contact.name;
			}
		});
		return addressbook;
	};

	const networkConnectButton = (
		<NetworkConnectButton
			addressBook={createAddressBook()}
			buttonOpened={openWalletModal}
			chainId={expectedChainIds}
			networkIcons={NETWORK_ICONS}
			networkNames={NETWORK_NAMES}
			explorerUrls={EXPLORER_URLS}
			classes={{
				root: classes.metamaskRoot,
			}}
			onDisconnect={disconnect}
			onToggle={setOpenWalletModal}
			labelAddressCopied={t('metamask_box.copied')}
			// labelDisconnect={t('metamask_box.disconnect_metamask')}
			labelNetwork={`${t('metamask_box.selected_network')} ${networkname} ${t(
				'metamask_box.network',
			)}`}
		/>
	);

	const handleHome = () => {
		setPage(PAGES_NAMES.HOME);
	};

	const handleNewFeatures = () => {
		setPage(PAGES_NAMES.NEWFEATURES);
	};

	const handleSettings = () => {
		unmute(); // needed to enable playing sound when it is selected
		setPage(PAGES_NAMES.SETTINGS);
	};

	const handleLegal = () => {
		setPage(PAGES_NAMES.LEGAL);
	};

	const handlePrivacy = () => {
		setPage(PAGES_NAMES.PRIVACY);
	};

	const handleToggleMute = () => {
		unmute(); // needed to enable playing sounds, even if we mute: this is more like "activating/supporting the browser" to play sounds
		toggleMute();
	};

	const settingIconContent = (
		<ExclamationIcon isActivate={isSubscriptionExpired || newerWalletVersion}>
			<SettingsIcon />
		</ExclamationIcon>
	);

	const bottomNav = (
		<div className={classes.bottomNav}>
			<IconButton className={classes.iconButton} onClick={handleHome}>
				<HomeIcon />
			</IconButton>
			<IconButton className={classes.iconButton} onClick={handleSettings}>
				{settingIconContent}
			</IconButton>
			<IconButton className={classes.iconButton} onClick={handleNewFeatures}>
				<PostAddIcon />
			</IconButton>
			<Link
				component={IconButton}
				className={classes.iconButton}
				href={MAIL_TO}
				target="_blank"
				rel="noreferrer noopener"
			>
				<EmailIcon />
			</Link>
		</div>
	);

	const iconsContent = (
		<div className={classes.iconButtonContainer}>
			<Link
				component={IconButton}
				className={classes.iconButton}
				href={TWITTER_URL}
				target="_blank"
				rel="noreferrer noopener"
			>
				<TwitterIcon />
			</Link>
			<Link
				component={IconButton}
				className={classes.iconButton}
				href={YOUTUBE_URL}
				target="_blank"
				rel="noreferrer noopener"
			>
				<YouTubeIcon />
			</Link>
			<IconButton className={classes.iconButton} onClick={lock}>
				<VisibilityOffIcon />
			</IconButton>
			<IconButton className={classes.iconButton} onClick={handleToggleMute}>
				{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
			</IconButton>
		</div>
	);

	let blockheight;
	if (lastBlockVisible) {
		blockheight = <BlockHeight open={open} />;
	}

	let currentGasPrice;
	if (gasPriceVisible) {
		currentGasPrice = <CurrentGasPrice open={open} />;
	}

	const legalPrivacyContent = (
		<div className={classes.legalPrivacyLinks}>
			<ButtonBase onClick={handleLegal} variant="text" size="small">
				{t('layout.left_sidebar.nav.legal')}
			</ButtonBase>
			<span>&</span>
			<ButtonBase onClick={handlePrivacy} variant="text" size="small">
				{t('layout.left_sidebar.nav.privacy')}
			</ButtonBase>
		</div>
	);

	return (
		<MuiDrawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx(classes.paperRoot, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			{toolbarContent}
			{listInfoSettingContent}
			{tokenlistMenu}
			<div
				className={clsx(classes.bottomBoxContainer, {
					[classes.bottomBoxContainerOpen]: open,
				})}
			>
				<div className={classes.walletInfo}>
					{walletNetwork}
					{currentGasPrice}
					{blockheight}
				</div>
				<div className={classes.walletConnectContainer}>
					{networkConnectButton}
				</div>
				{bottomNav}
				{iconsContent}
				{legalPrivacyContent}
			</div>
		</MuiDrawer>
	);
};

LeftSideBar.propTypes = {
	pageContext: PropTypes.shape({
		version: PropTypes.string.isRequired,
	}).isRequired,
};

export default LeftSideBar;
