import { toBN } from '@itsa.io/web3utils';
import { BN_ZERO, BN_1ETH_WEI } from 'config/constants';

export const toFiat = (
	amount,
	fiatprices = {},
	currency = 'usd',
	tokenDecimals,
) => {
	currency = currency.toLowerCase();
	if (typeof tokenDecimals !== 'number') {
		// eslint-disable-next-line no-console
		console.error('toFiat needs tokenDecimals as an argument');
		tokenDecimals = 18;
	}
	let price = fiatprices[currency] || 0;
	price = Math.round(price * 1000000000000000);

	// in case of 18 dec, we would need to divide by '1000000000000000' (15 zeros)
	// however, if less decimals, we also need to devide by less
	let divAmount = '1';
	const amountOfZeros = 15 - 18 + tokenDecimals;
	divAmount = divAmount.padEnd(amountOfZeros + 1, '0');

	// security: we cannot create Bignumbers when there is an exponent in the string:
	if (price.toString().toLowerCase().indexOf('e') !== -1) {
		price = '0';
	}

	return toBN(price).mul(amount).div(toBN(divAmount));
};

export const toFiatFromDexPrices = (
	amount,
	reserves0BN,
	reserves1BN,
	currencyprices,
	currency = 'usd',
	tokenDecimals,
	wrappedToken,
) => {
	if (!wrappedToken) {
		return BN_ZERO;
	}
	if (typeof tokenDecimals !== 'number') {
		// eslint-disable-next-line no-console
		console.error('toFiat needs tokenDecimals as an argument');
		tokenDecimals = 18;
	}

	// eslint-disable-next-line camelcase
	const coinPrice10_18_BN = toBN(
		Math.round(wrappedToken.prices.usd * 100000000).toString(),
	).mul(toBN('10000000000'));
	const dexPriceBN = coinPrice10_18_BN.mul(reserves0BN).div(reserves1BN);

	let totalValueBN = amount.mul(dexPriceBN);

	if (currencyprices) {
		const priceLevelCurrency = toBN(
			Math.round(currencyprices[currency] * 1000000000000).toString(),
		);
		const priceLevelUSD = toBN(
			Math.round(currencyprices.usd * 1000000000000).toString(),
		);

		totalValueBN = totalValueBN.mul(priceLevelCurrency).div(priceLevelUSD);
	}
	// Note: because "toFiat" assumes that fiat always is in 18 decimals,
	// we may need to correct for the token decimals
	if (tokenDecimals !== 18) {
		totalValueBN = totalValueBN
			.mul(BN_1ETH_WEI)
			.div(toBN('1'.padEnd(tokenDecimals + 1, '0'))); // example: 6 decimals leads to: .div(toBN('1000000'))
	}
	return totalValueBN.div(BN_1ETH_WEI);
};
