import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			display: 'flex',
			flexFlow: 'column-reverse',
		},
	},
	buttonConfirm: {
		background: theme.palette.text.primary,
		color: theme.palette.background.main,
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			width: '90vw',
			marginTop: theme.spacing(0.5),
		},
	},
}));
