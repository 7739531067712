import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useIntl, later, formatBN } from '@itsa.io/web3utils';
import { utils } from 'web3';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import blockheightCtx from 'context/blockheight';
import getElapsedTime from 'utils/get-elapsed-time';
import useStyles from 'styles/components/common/BlockHeight';

const BlockHeight = ({ open }) => {
	const classes = useStyles();
	const { t } = useIntl();
	const { updated, blockheight } = useContext(blockheightCtx);
	const timerRef = useRef();
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		timerRef.current = later(forceUpdate, 1000, true);
		return () => {
			timerRef.current.cancel();
		};
	}, []);

	return (
		<div
			className={clsx(classes.root, {
				[classes.menuOpen]: open,
			})}
		>
			{t('time.last_block')}
			<span className={classes.blockheight}>
				{formatBN(utils.toBN(blockheight.toString()))}
			</span>
			<span className={classes.time}>{getElapsedTime(t, updated)}</span>
		</div>
	);
};

BlockHeight.defaultProps = {
	open: false,
};

BlockHeight.propTypes = {
	open: PropTypes.bool,
};

export default BlockHeight;
