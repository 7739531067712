import { makeStyles } from '@itsa.io/ui';

export default makeStyles((/* theme */) => ({
	root: {},
	label: {
		marginTop: '2rem',
		textTransform: 'uppercase',
		fontSize: '0.7rem',
	},
}));
