/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { defaultAbiCoder } from 'ethers/utils/abi-coder';

// Function signature for: aggregate((address,bytes)[])
export const AGGREGATE_SELECTOR = '0x252dba42';

export const strip0x = str => str.replace(/^0x/, '');

// export const encodeParameters = (types, vals) =>
// 	defaultAbiCoder.encode(types, vals);
export function encodeParameters(types, vals) {
	return defaultAbiCoder.encode(types, vals);
}

export function decodeParameters(types, vals) {
	return defaultAbiCoder.decode(types, `0x${vals.replace(/0x/i, '')}`);
}
