import { makeStyles } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '0.2em',
	},
	masternodeTextWrapper: {
		display: 'flex',
		border: `1px solid ${theme.palette.primary.main}`,
		alignItems: 'center',
		borderRadius: 10,
		padding: theme.spacing(0.3, 1.75),
		backgroundColor: theme.palette.default.main,
		marginLeft: theme.spacing(0.75),
	},
	masternodeAbrText: {
		textTransform: 'uppercase',
		letterSpacing: 1,
		fontSize: '0.9em',
	},
	masternodeText: {
		textTransform: 'uppercase',
		textAlign: 'left',
		letterSpacing: 1,
		paddingBottom: '0.5em',
		fontSize: '0.9em',
	},
	masternodeBadge: {
		'& .MuiBadge-badge': {
			borderRadius: '100%',
			marginLeft: theme.spacing(0.5),
		},
		'& .MuiBadge-dot': {
			top: 3,
			right: 3,
		},
		'&.status0': {
			'& .MuiBadge-badge': {
				color: theme.palette.red[700],
				border: `1px solid ${theme.palette.red[700]}`,
				backgroundColor: theme.palette.red[900],
			},
		},
		'&.status1': {
			'& .MuiBadge-badge': {
				color: theme.palette.red[700],
				border: `1px solid ${theme.palette.red[700]}`,
				backgroundColor: theme.palette.red[900],
			},
		},
		'&.status2': {
			'& .MuiBadge-badge': {
				color: theme.palette.red[700],
				border: `1px solid ${theme.palette.red[700]}`,
				backgroundColor: theme.palette.red[900],
			},
		},
		'&.status3': {
			'& .MuiBadge-badge': {
				color: theme.palette.orange[700],
				border: `1px solid ${theme.palette.orange[700]}`,
				backgroundColor: theme.palette.orange[900],
			},
		},
		'&.status4': {
			'& .MuiBadge-badge': {
				color: theme.palette.green[300],
				border: `1px solid ${theme.palette.green[400]}`,
				backgroundColor: theme.palette.green[400],
			},
		},
	},
	status: {
		fontWeight: 500,
		'&.status0': {
			color: theme.palette.red[700],
		},
		'&.status1': {
			color: theme.palette.red[700],
		},
		'&.status2': {
			color: theme.palette.red[700],
		},
		'&.status3': {
			color: theme.palette.orange[700],
		},
		'&.status4': {
			color: theme.palette.green[300],
		},
	},
	recomendedVersion: {
		color: theme.palette.orange[700],
		fontSize: '0.8em',
		'& td': {
			paddingBottom: '0.6em',
		},
	},
	requiredVersion: {
		color: theme.palette.red[700],
		fontSize: '0.8em',
		'& td': {
			paddingBottom: '0.6em',
		},
	},
	icon: {
		fontSize: '1rem',
		color: theme.palette.primary.main,
		marginRight: theme.spacing(0.5),
	},
	payoutAmount: {
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	tokenSymbol: {
		fontSize: '0.8em',
		marginLeft: '0.25em',
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverIconDetails: {},
	popoverPaper: {
		backgroundColor: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(2),
		marginTop: '0.8em',
	},
}));
