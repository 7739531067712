// Get location.hostname on any environment

// eslint-disable-next-line import/no-mutable-exports
let host;

// eslint-disable-next-line func-names
(function (win) {
	host = win?.location?.host;
})(typeof global !== 'undefined' ? global : /* istanbul ignore next */ this);

export default host;
