import axios from 'axios';
import { isEqual, cloneDeep } from 'lodash';
import { later } from '@itsa.io/web3utils/lib/timers';
import addressSortsBefore from 'utils/address-sorts-before';
import {
	BN_ZERO,
	getMainSymbol,
	NETWORK_NAMES,
	WRAPPED_ADDRESSES,
	ENERGI_CHAINIDS,
	STABLECOIN_REF_ADDRESSES,
	API_URL,
} from 'config/constants';
import { toBN } from '@itsa.io/web3utils';
import addressMatch from 'utils/address-match';
import DEFAULT_TOKEN_LIST_ENERGI from '@energi/energiswap-default-token-list';

const REFRESH_TIME_MS = 10 * 60 * 1000; // every 10 mins

const transformTokenData = (responseCurrencyprices, dbToken) => {
	const token = cloneDeep(dbToken);
	token.balance = token.balance ? toBN(token.balance) : BN_ZERO;
	token.image = `/tokens/${getMainSymbol(token.symbol.toUpperCase())}.svg`;
	token.visualDecimals = 4;
	token.prices = {};
	token.sortsBefore = token1 =>
		addressSortsBefore(token.address, token1.address);
	responseCurrencyprices.forEach(currencie => {
		token.prices[currencie.currency.toLowerCase()] =
			token.priceusd / currencie.priceusd;
	});
	delete token.priceusd;
	try {
		token.decimals = parseInt(token.decimals, 10);
	} catch (err) {
		token.decimals = 0;
	}
	return token;
};

const ensureUSDE = (chainId, newTokens) => {
	let usdeToken = newTokens.find(t =>
		addressMatch(t.address, STABLECOIN_REF_ADDRESSES[chainId]),
	);
	if (!usdeToken) {
		usdeToken = DEFAULT_TOKEN_LIST_ENERGI.tokens.find(
			t =>
				t.chainId === chainId &&
				addressMatch(t.address, STABLECOIN_REF_ADDRESSES[chainId]),
		);
		if (usdeToken) {
			usdeToken.address = usdeToken.address.toLowerCase();
			usdeToken.priceusd = 1;
			usdeToken.symbol = 'USDE';
			newTokens.push(usdeToken);
		}
	}
};

const removeDupeWNRG = (chainId, newTokens) => {
	// since CMC adds 2x WNRG, we may need to remove one
	const wnrgTokens = newTokens.filter(
		token => token.address === WRAPPED_ADDRESSES[chainId],
	);
	if (wnrgTokens.length > 1) {
		const dupeToken = wnrgTokens.reduce(
			(cum, item) => (cum.cmcrank > item.cmcrank ? cum : item),
			wnrgTokens[0],
		);
		const index = newTokens.indexOf(dupeToken);
		newTokens.splice(index, 1);
	}
};

class DexTokens {
	constructor() {
		this.callbacks = [];
		this.init();
	}

	async init() {
		this.tokens = [];
		this.loading = true;
		this.error = null;
	}

	isReady() {
		const instance = this;
		if (!instance.loading) {
			return Promise.resolve();
		}
		instance.resolve(); // prevent prev subscribers from never becoming resolved
		return new Promise(resolve => {
			instance.readyPromiseResolveFn = resolve;
		});
	}

	resolve() {
		const instance = this; // optimize for minifying
		if (instance.readyPromiseResolveFn) {
			instance.readyPromiseResolveFn();
			delete instance.readyPromiseResolveFn;
		}
	}

	async reloadTokens() {
		const instance = this; // optimize for minifying
		const { chainId, tokens, limit } = instance;
		if (chainId) {
			try {
				const payload = {
					chainid: chainId,
				};
				if (typeof limit === 'number') {
					payload.limit = limit;
				}
				const response = await axios.post(`${API_URL}/tokens`, payload);
				const responseCurrencyprices = await axios.post(
					`${API_URL}/currencyprices`,
				);
				if (
					response.status === 200 &&
					response.data?.success &&
					responseCurrencyprices.status === 200 &&
					responseCurrencyprices.data?.success
				) {
					const prevLoading = instance.loading;
					instance.loading = false;
					let newTokens = response.data.data;
					if (ENERGI_CHAINIDS[chainId]) {
						ensureUSDE(chainId, newTokens);
						removeDupeWNRG(chainId, newTokens);
					}
					newTokens = newTokens.map(
						transformTokenData.bind(null, responseCurrencyprices.data.data),
					);
					if (prevLoading || !isEqual(tokens, newTokens)) {
						instance.tokens = newTokens;
						instance.callbacks.forEach(fn => fn());
					}
					instance.resolve();
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}
	}

	updateLimit(limit) {
		this.limit = limit;
		this.reloadTokens();
	}

	stop(callbackFn, chainIdSwitch) {
		const instance = this; // optimize for minifying
		const index = instance.callbacks.indexOf(callbackFn);
		if (index !== -1) {
			instance.callbacks.splice(index, 1);
		}
		if (instance.callbacks.length === 0 || chainIdSwitch) {
			if (instance.timer) {
				instance.timer.cancel();
				delete instance.timer;
			}
		}
		instance.tokens = [];
		instance.resolve();
	}

	start(chainId, callback, limit) {
		if (NETWORK_NAMES[chainId]) {
			// only continue if it is a supported network
			const instance = this; // optimize for minifying
			const chainIdSwitch = instance.chainId !== chainId; // clear previous timer
			instance.chainId = chainId;
			instance.limit = limit;
			instance.callbacks.push(callback);
			if (chainIdSwitch) {
				instance.stop(null, true);
			} else if (instance.timer) {
				instance.timer.cancel();
			}
			instance.init();
			// setup listener
			const reloadTokens = instance.reloadTokens.bind(instance);
			instance.timer = later(
				reloadTokens,
				0,
				REFRESH_TIME_MS, // interval
			);
		}
	}
}

const dexTokens = new DexTokens();

export default dexTokens;
