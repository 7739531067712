// splits a large array into smaller pieces
const arrayToBatched = (array = [], maxSize) => {
	const batched = [];
	let index = 0;
	const maxIndex = array.length;
	while (index < maxIndex) {
		const endIndex = Math.min(index + maxSize, maxIndex);
		const batch = array.slice(index, endIndex);
		batched.push(batch);
		index = endIndex;
	}
	return batched;
};

module.exports = arrayToBatched;
