import React, { useState, useContext } from 'react';
import {
	Box,
	TextField as MuiTextField,
	Button,
	Modal as MuiModal,
	Fade,
} from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';
import locktimerCtx from 'context/locktimer';
import darkmodeCtx from 'context/darkmode';
import useStyles from 'styles/components/common/modals/ScreenlockModal';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';

const MAX_ATTEMPTS = 5;

const ScreenlockModal = () => {
	const { t } = useIntl();
	const classes = useStyles();
	const [userPassword, setUserPassword] = useState('');
	const [wrongPassword, setWrongPassword] = useState(false);
	const [wrongAttempts, setWrongAttempts] = useState(0);
	const { unlock, password } = useContext(locktimerCtx);
	const { darkmode } = useContext(darkmodeCtx);
	const noMoreAttempts = wrongAttempts >= MAX_ATTEMPTS;

	const handleUnlock = () => {
		const success = unlock(userPassword);
		setWrongPassword(!success);
		if (success) {
			setWrongAttempts(0);
		} else {
			setWrongAttempts(wrongAttempts + 1);
		}
	};

	const handlePasswordKeyDown = event => {
		if (event.nativeEvent.keyCode === 13) {
			handleUnlock();
		}
	};

	const handleChangePassword = event => {
		if (wrongPassword) {
			setWrongPassword(false);
		}
		setUserPassword(event.target.value);
	};

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	const header = <header className={classes.header}>{logo}</header>;

	let textFieldPassword;
	if (password) {
		textFieldPassword = (
			<MuiTextField
				className={classes.textFieldPassword}
				disabled={noMoreAttempts}
				label="Password"
				type="password"
				placeholder="Enter Password"
				variant="filled"
				autoFocus
				onKeyDown={handlePasswordKeyDown}
				onChange={handleChangePassword}
				fullWidth
			/>
		);
	}

	let textWrongPassword;
	if (wrongPassword) {
		textWrongPassword = noMoreAttempts
			? t('screenlock_modal.too_many_times_wrong_password')
			: t('screenlock_modal.wrong_password');
	}

	return (
		<MuiModal open closeAfterTransition>
			<Fade
				timeout={{
					enter: 500,
					exit: 300,
				}}
				in
			>
				<Box className={classes.container}>
					{header}
					<form
						id="lockscreen"
						className={classes.body}
						onSubmit={e => e.preventDefault()}
					>
						{textFieldPassword}
						<div className={classes.wrongPassword}>{textWrongPassword}</div>
						<Button
							className={classes.buttonConfirm}
							disabled={noMoreAttempts}
							variant="outlined"
							size="large"
							onClick={handleUnlock}
						>
							{t('screenlock_modal.unlock')}
						</Button>
					</form>
				</Box>
			</Fade>
		</MuiModal>
	);
};

export default ScreenlockModal;
