import { makeStyles, alpha } from '@itsa.io/ui';

const globalStyles = () => ({
	h1: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h1,
			fontSize: '2.5rem',
		};
	},
	h2: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h2,
			fontSize: '1.75rem',
		};
	},
	h3: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h3,
			fontSize: '1.5rem',
		};
	},
	h4: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h4,
			fontSize: '1.25rem',
		};
	},
	h5: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h5,
		};
	},
	h6: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h6,
		};
	},
});

export default makeStyles(theme => ({
	'@global': globalStyles(theme),
	root: ({ currentTheme }) => {
		return {
			display: 'flex',
			minHeight: '100vh',
			fontSize: 14,
			fontWeight: 400,
			color: currentTheme.palette.text.primary,
		};
	},
	developmentLabel: ({ currentTheme }) => {
		return {
			color: currentTheme.palette.text.primary,
			backgroundColor: `${alpha('#FACC15', 0.4)}`,
			padding: currentTheme.spacing(0.5, 1),
			position: 'fixed',
			top: 0,
			zIndex: 9000,
			right: 0,
			left: 'auto',
			[currentTheme.breakpoints.down('sm')]: {
				left: 0,
				top: 64,
				width: 163,
				right: 'auto',
				padding: currentTheme.spacing(0, 1, 0, 2),
			},
		};
	},
	container: ({ currentTheme }) => {
		return {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
			alignItems: 'center',
			padding: currentTheme.spacing(3),
		};
	},
}));
