import { getWeb3 } from 'utils/get-web3';
import { toBN } from '@itsa.io/web3utils';

const currentGasPrice = async chainId => {
	const web3 = getWeb3(chainId);
	const gas = await web3.eth.getGasPrice(); // -> current gas
	return toBN(web3.utils.toHex(gas));
};

export default currentGasPrice;
