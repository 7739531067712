import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from 'styles/components/common/ExclamationIcon';

const ExclamationIcon = ({ children, isActivate, blinkBorder, className }) => {
	const classes = useStyles();
	let blinkClassName;

	if (blinkBorder) {
		blinkClassName = classes.exclamationBlinkBorder;
	}

	return (
		<div
			className={clsx(classes.root, className, {
				[classes.exclamationIcon]: isActivate,
				[blinkClassName]: isActivate,
			})}
		>
			{children}
		</div>
	);
};

ExclamationIcon.defaultProps = {
	className: null,
	blinkBorder: false,
};

ExclamationIcon.propTypes = {
	className: PropTypes.string,
	blinkBorder: PropTypes.bool,
	isActivate: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
};

export default ExclamationIcon;
