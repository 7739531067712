import { useEffect, useState, useContext } from 'react';
import createStore from 'ctx-provider';
import blockheightCtx from 'context/blockheight';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import getCurrentGasPriceBN from 'utils/get-current-gas-price-bn';
import getHighestGas from 'utils/get-highest-gas';
import { BN_ZERO, NETWORK_NAMES } from 'config/constants';

const useGas = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const { blockheight } = useContext(blockheightCtx);
	const [gasprice, setGasprice] = useState();
	const [minRetainedWei, setMinRetainedWei] = useState(BN_ZERO);

	const setCurrentGasPrice = async () => {
		const currentGasPriceBN = await getCurrentGasPriceBN(chainId);
		setGasprice(currentGasPriceBN);
		const wei = currentGasPriceBN.mul(getHighestGas(chainId));
		setMinRetainedWei(wei);
	};

	useEffect(() => {
		if (NETWORK_NAMES[chainId]) {
			setCurrentGasPrice();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, blockheight]);

	return {
		gasprice,
		minRetainedWei,
	};
};

const store = createStore(useGas);

export const { Provider } = store;
export default store.ctx;
