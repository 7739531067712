import Web3 from 'web3';
import web3Extension from '@energi/web3-ext';
import {
	IS_ENERGI_CHAINID,
	JSON_APIS_WSS,
	RPC_URLS,
	RPC_URLS_ENERGI_NODE,
	NETWORK_NAMES,
	WS_TIMEOUT_SEC,
} from 'config/constants';

const web3Instances = {};
const web3wsInstances = {};
const web3EnergiInstances = {};
const web3Extended = {};
const web3wsExtended = {};
const web3EnergiExtended = {};

const options = {
	// Enable auto reconnection
	clientConfig: {
		keepalive: true,
		keepaliveInterval: 20000,
	},
	reconnect: {
		auto: true,
		delay: 2500,
		onTimeout: true,
	},
	timeout: 1000 * WS_TIMEOUT_SEC,
};

export const getWeb3 = chainId => {
	if (!NETWORK_NAMES[chainId]) {
		return null;
	}
	if (!web3Instances[chainId]) {
		web3Instances[chainId] = new Web3(RPC_URLS[chainId]);
	}
	if (IS_ENERGI_CHAINID(chainId) && !web3Extended[chainId]) {
		web3Extension.extend(web3Instances[chainId]);
		web3Extended[chainId] = true;
	}
	return web3Instances[chainId];
};

export const getWeb3EnergiNode = chainId => {
	if (IS_ENERGI_CHAINID(chainId)) {
		if (!web3EnergiInstances[chainId]) {
			web3EnergiInstances[chainId] = new Web3(RPC_URLS_ENERGI_NODE[chainId]);
		}
		if (!web3EnergiExtended[chainId]) {
			web3Extension.extend(web3EnergiInstances[chainId]);
			web3EnergiExtended[chainId] = true;
		}
		return web3EnergiInstances[chainId];
	}
	return null;
};

const createWebsocketInstance = chainId => {
	const wsProvider = new Web3.providers.WebsocketProvider(
		JSON_APIS_WSS[chainId],
		options,
	);
	return new Web3(wsProvider);
};

export const getWeb3ws = chainId => {
	if (!NETWORK_NAMES[chainId]) {
		return null;
	}
	if (!web3wsInstances[chainId]) {
		web3wsInstances[chainId] = createWebsocketInstance(chainId);
	}
	if (IS_ENERGI_CHAINID(chainId) && !web3wsExtended[chainId]) {
		web3Extension.extend(web3wsInstances[chainId]);
		web3wsExtended[chainId] = true;
	}
	return web3wsInstances[chainId];
};
