import axios from 'axios';
import { utils } from 'web3';
import { cloneDeep, isObject } from 'lodash';
import addressSortsBefore from 'utils/address-sorts-before';
import {
	API_URL,
	BN_ZERO,
	MORALIS_CHAINS,
	getMainSymbol,
} from 'config/constants';
import { toBN } from '@itsa.io/web3utils';

const { isAddress } = utils;

const transformTokenData = (responseCurrencyprices, dbToken) => {
	const token = cloneDeep(dbToken);
	token.balance = token.balance ? toBN(token.balance) : BN_ZERO;
	token.image = `/tokens/${getMainSymbol(token.symbol.toUpperCase())}.svg`;
	token.visualDecimals = 4;
	token.prices = {};
	token.sortsBefore = token1 =>
		addressSortsBefore(token.address, token1.address);
	responseCurrencyprices.forEach(currencie => {
		token.prices[currencie.currency.toLowerCase()] =
			token.priceusd / currencie.priceusd;
	});
	delete token.priceusd;
	try {
		token.decimals = parseInt(token.decimals, 10);
	} catch (err) {
		token.decimals = 0;
	}
	return token;
};

export const customTokens = async (chainId, tokens, fullData) => {
	let newTokens;
	if (!chainId) {
		// eslint-disable-next-line no-console
		console.error('reloadTokens called without chainId');
		return [];
	}
	try {
		const response = await axios.post(`${API_URL}/tokens`, {
			chainid: chainId,
			tokenaddresses: tokens,
			pricesnotrequired: true,
		});
		let responseCurrencyprices;
		if (fullData) {
			responseCurrencyprices = await axios.post(`${API_URL}/currencyprices`);
		}
		if (
			response.status === 200 &&
			response.data?.success &&
			(!fullData ||
				(responseCurrencyprices.status === 200 &&
					responseCurrencyprices.data?.success))
		) {
			newTokens = response.data.data;
			if (fullData) {
				newTokens = newTokens.map(
					transformTokenData.bind(null, responseCurrencyprices.data.data),
				);
			}
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		newTokens = [];
	}
	return newTokens;
};

export const requestRegisterToken = async (chainId, tokenAddress) => {
	// only register non moralis chainid tokens
	if (!MORALIS_CHAINS[chainId]) {
		if (!chainId) {
			// eslint-disable-next-line no-console
			console.error('requestRegisterToken called without chainId');
			return;
		}
		if (isObject(tokenAddress)) {
			tokenAddress = tokenAddress.address;
		}
		if (!tokenAddress) {
			// eslint-disable-next-line no-console
			console.error('requestRegisterToken called without a tokenAddress');
			return;
		}
		if (!isAddress(tokenAddress)) {
			// eslint-disable-next-line no-console
			console.error(
				'requestRegisterToken called without valid tokenAddress',
				tokenAddress,
			);
			return;
		}
		try {
			await axios.post(`${API_URL}/requestregistertoken`, {
				chainid: chainId,
				tokenaddress: tokenAddress.toLowerCase(),
			});
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}
};
