import {
	getItsaSubscriptionContract,
	getSilverNftContract,
	getGoldNftContract,
	getPlatinaNftContract,
} from 'utils/get-contract';
import { utils } from 'web3';
import { toBN } from '@itsa.io/web3utils';
import {
	ITSA_SUBSCRIPTION_SC_ADDRESSES,
	BN_GAS_SUBSCRIBE,
} from 'config/constants';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import { getWeb3 } from 'utils/get-web3';

const errorMessages = {
	ITSA_SUBSCRIPTION_CONTRACT_EXCEPTION: 'ItsaSubscription Contract Exception',
	NRDAYS_NOT_A_NUMBER: 'nrOfDays not a number type',
	NRDAYS_BELOW_ONE: 'nrOfDays below 1',
	NRDAYS_NOT_WHOLE_NR: 'nrOfDays not whole number',
	INVALID_SMARTCONTRACT_ADDRESS:
		'Invalid ItsaSubscription SmartContract Address',
};

const stringToNrs = text => parseInt(text, 10);

/**
 * Generates an Exception thrown by a claim error
 *
 * @method SubscriptionException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const SubscriptionException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'SubscriptionException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.ITSA_SUBSCRIPTION_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

// First the NFT methods
export const silverNftIds = async (chainId, address) => {
	const contract = getSilverNftContract(chainId);
	const ids = contract && (await contract.methods.idsOf(address).call());
	if (!ids) {
		return [];
	}
	return ids.split(',').map(stringToNrs);
};

export const goldNftIds = async (chainId, address) => {
	const contract = getGoldNftContract(chainId);
	const ids = contract && (await contract.methods.idsOf(address).call());
	if (!ids) {
		return [];
	}
	return ids.split(',').map(stringToNrs);
};

export const platinaNftIds = async (chainId, address) => {
	const contract = getPlatinaNftContract(chainId);
	const ids = contract && (await contract.methods.idsOf(address).call());
	if (!ids) {
		return [];
	}
	return ids.split(',').map(stringToNrs);
};

export const nftIds = async (chainId, address) => {
	const nfts = await Promise.all([
		silverNftIds(chainId, address),
		goldNftIds(chainId, address),
		platinaNftIds(chainId, address),
	]);
	return {
		silver: nfts[0],
		gold: nfts[1],
		platina: nfts[2],
	};
};

export const hasGoldNftSubscription = async (chainId, address) => {
	const contract = getGoldNftContract(chainId);
	return contract && contract.methods.hasSubscription(address).call();
};

export const hasPlatinaNftSubscription = async (chainId, address) => {
	const contract = getPlatinaNftContract(chainId);
	return contract && contract.methods.hasSubscription(address).call();
};

export const hasNftSubscription = async (chainId, address) => {
	const nfts = await Promise.all([
		hasGoldNftSubscription(chainId, address),
		hasPlatinaNftSubscription(chainId, address),
	]);
	return nfts[0] || nfts[1];
};

// Now the ItsaSubscription methods

export const hasFullAccess = async (chainId, address, alsoCheckNfts) => {
	const contract = getItsaSubscriptionContract(chainId);
	let fullAccess = contract && contract.methods.hasFullAccess(address).call();
	if (alsoCheckNfts && !fullAccess) {
		fullAccess = await hasNftSubscription(chainId, address);
		if (!fullAccess) {
			const nftids = await nftIds(chainId, address);
			fullAccess =
				!!nftids.silver.length ||
				!!nftids.gold.length ||
				!!nftids.platina.length;
		}
	}
	return fullAccess;
};

export const isBoundSubscribed = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.isBoundSubscribed(address).call();
};

export const canGetSubscription = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.canGetSubscription(address).call();
};

// returns boolean
export const canApproveBoundSubscription = async chainId => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.canApproveBoundSubscription().call();
};

// returns boolean
export const canSetMultiApproveBoundSubscriptions = async (
	chainId,
	address,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	return (
		contract &&
		contract.methods.canSetMultiApproveBoundSubscriptions(address).call()
	);
};

// returns boolean
export const isSubscribed = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.isSubscribed(address).call();
};

// returns bigNumber
export const expiration = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.expiration(address).call();
};

export const hasValidBindToSubscription = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return (
		contract && contract.methods.hasValidBindToSubscription(address).call()
	);
};
export const isBoundToSubscription = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.isBoundToSubscription(address).call();
};
export const getMasterSubscription = async (chainId, boundAddress) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	let masterSubscription = await contract.methods
		.getMasterSubscription(boundAddress)
		.call();
	if (masterSubscription === '0x0000000000000000000000000000000000000000') {
		masterSubscription = null;
	}
	return masterSubscription;
};

export const canGetTrial = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.canGetTrial(address).call();
};

// returns boolean
export const hasTrial = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.hasTrial(address).call();
};

// returns bigNumber
export const trialExpiration = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.trialExpiration(address).call();
};

// returns boolean
export const hasFreeSubscription = async (chainId, address) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.hasFreeSubscription(address).call();
};

// returns bigNumber
export const trialPeriod = async chainId => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	let trialPeriod = await contract.methods.trialPeriod().call();
	try {
		trialPeriod = parseInt(trialPeriod, 10);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		trialPeriod = 0;
	}
	return trialPeriod;
};

// returns bigNumber
export const maxSubscriptionDays = async chainId => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	let maxSubscriptionDays = await contract.methods.maxSubscriptionDays().call();
	try {
		maxSubscriptionDays = parseInt(maxSubscriptionDays, 10);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		maxSubscriptionDays = 0;
	}
	return maxSubscriptionDays;
};

// returns boolean
export const isApprovedBindToSubscription = async (
	chainId,
	masterAddress,
	approveAddress,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	return (
		contract &&
		contract.methods
			.isApprovedBindToSubscription(masterAddress, approveAddress)
			.call()
	);
};

// returns Array of addresses
export const getBoundAddresses = async (chainId, masterAddress) => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.getBoundAddresses(masterAddress).call();
};

// returns bigNumber
export const maxChildSubscriptions = async chainId => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	let maxChildSubscriptions = await contract.methods
		.maxChildSubscriptions()
		.call();
	try {
		maxChildSubscriptions = parseInt(maxChildSubscriptions, 10);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		maxChildSubscriptions = 0;
	}
	return maxChildSubscriptions;
};

// returns bigNumber
export const dailyFee = async chainId => {
	const contract = getItsaSubscriptionContract(chainId);
	return contract && contract.methods.dailyFee().call();
};

// payable
export const subscribe = async (
	chainId,
	nrOfDays,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nrOfDays !== 'number') {
		throw new SubscriptionException(errorMessages.NRDAYS_NOT_A_NUMBER);
	}
	const days = Math.round(nrOfDays);
	if (days < 1) {
		throw new SubscriptionException(errorMessages.NRDAYS_BELOW_ONE);
	}
	if (days !== nrOfDays) {
		throw new SubscriptionException(errorMessages.NRDAYS_NOT_WHOLE_NR);
	}
	const dailyfee = await dailyFee(chainId); // returns a string type
	const amount = toBN(dailyfee).mul(toBN(days.toString()));
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods.subscribe().encodeABI();
	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
		value: utils.toHex(amount.toString()),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId].subscribe,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const trial = async (
	chainId,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods.trial().encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId].trial,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const bindToSubscription = async (
	chainId,
	subscriptionAddress,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.bindToSubscription(subscriptionAddress)
		.encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId].bindtosubscription,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const removeBoundSubscription = async (
	chainId,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.removeBoundSubscription()
		.encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId].removeboundsubscription,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const approveBoundSubscription = async (
	chainId,
	approveAddress,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.approveBoundSubscription(approveAddress)
		.encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId].approveboundsubscription,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const removeBoundSubscriptionApproval = async (
	chainId,
	approvedAddress,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.removeBoundSubscriptionApproval(approvedAddress)
		.encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId]
			.removeboundsubscriptionapproval,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const setApprovedMultipleBoundSubscriptions = async (
	chainId,
	approveAddresses,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const contract = getItsaSubscriptionContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.setApprovedMultipleBoundSubscriptions(approveAddresses)
		.encodeABI();

	const rawTx = {
		to: ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		data: transactionData,
		from: sender,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new SubscriptionException(
			errorMessages.INVALID_SMARTCONTRACT_ADDRESS,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.itsasubscription[chainId]
			.setapprovedmultipleboundsubscriptions,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const enoughFundsToSubscribe = async (chainId, nrOfDays, balance) => {
	if (typeof nrOfDays !== 'number') {
		throw new SubscriptionException(errorMessages.NRDAYS_NOT_A_NUMBER);
	}
	const days = Math.round(nrOfDays);
	if (days < 1) {
		throw new SubscriptionException(errorMessages.NRDAYS_BELOW_ONE);
	}
	const dailyfee = await dailyFee(chainId); // returns a string type
	const needed = toBN(dailyfee)
		.mul(toBN(days.toString()))
		.add(BN_GAS_SUBSCRIBE);
	return balance.gte(needed);
};

export const enoughGasForTrial = balance => balance.gte(BN_GAS_SUBSCRIBE);
