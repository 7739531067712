import React from 'react';
import { Slider } from '@itsa.io/ui';
import PropTypes from 'prop-types';
import { useIntl } from '@itsa.io/web3utils';
import useStyles from 'styles/components/common/GasSlider';

const marks = [
	{ value: 1, label: '-10%', aria: '10 percent below current gasprice' },
	{ value: 2, label: '', aria: '5 percent below current gasprice' },
	{ value: 3, label: '0%', aria: 'current gasprice' },
	{ value: 4, label: '', aria: '5 percent above current gasprice' },
	{ value: 5, label: '+10%', aria: '10 percent above current gasprice' },
	{ value: 6, label: '', aria: '15 percent above current gasprice' },
	{ value: 7, label: '+20%', aria: '20 percent above current gasprice' },
	{ value: 8, label: '', aria: '25 percent above current gasprice' },
	{ value: 9, label: '+30%', aria: '30 percent above current gasprice' },
];

const valuetext = index => {
	return marks[index - 1].aria;
};

const GasSlider = ({ disabled, onChange, value, className }) => {
	const classes = useStyles();
	const { t } = useIntl();

	return (
		<div className={className}>
			<div className={classes.label}>{t('gas_slider.gasprice_label')}</div>
			<Slider
				aria-label="Gas price"
				defaultValue={2}
				disabled={disabled}
				getAriaValueText={valuetext}
				onChange={onChange}
				min={1}
				marks={marks}
				max={9}
				step={1}
				value={value}
			/>
		</div>
	);
};

GasSlider.defaultProps = {
	className: null,
	disabled: false,
};

GasSlider.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
	className: PropTypes.string,
};

export default GasSlider;
