import coinCollect from 'assets/sounds/coin-collect.mp3';
import success from 'assets/sounds/success.mp3';
import harp from 'assets/sounds/harp.mp3';
import ping from 'assets/sounds/ping.mp3';
import pong from 'assets/sounds/pong.mp3';
import sonar from 'assets/sounds/sonar.mp3';
import steelcup from 'assets/sounds/steelcup.mp3';

let unmuteFn;
let playSoundFn;
let unmuted;

export const unmute = () => {
	if (unmuteFn && !unmuted) {
		// only needed once
		unmuted = true;
		unmuteFn();
	}
};

export const playSound = name => {
	if (playSoundFn && unmuted) {
		playSoundFn(name);
	}
};

const AUDIO_PLAYERS = {};

// eslint-disable-next-line func-names
(function (win) {
	if (win.Audio) {
		AUDIO_PLAYERS.coinCollect = new win.Audio(coinCollect);
		AUDIO_PLAYERS.success = new win.Audio(success);
		AUDIO_PLAYERS.harp = new win.Audio(harp);
		AUDIO_PLAYERS.ping = new win.Audio(ping);
		AUDIO_PLAYERS.pong = new win.Audio(pong);
		AUDIO_PLAYERS.sonar = new win.Audio(sonar);
		AUDIO_PLAYERS.steelcup = new win.Audio(steelcup);
	}

	unmuteFn = () => {
		const items = Object.keys(AUDIO_PLAYERS);
		// eslint-disable-next-line no-return-assign
		items.forEach(item => (AUDIO_PLAYERS[item].mute = false));
	};

	playSoundFn = name => {
		if (win.Audio) {
			const audioPlayer = AUDIO_PLAYERS[name];
			// set audio to start position:
			audioPlayer.currentTime = 0;
			audioPlayer.play();
		}
	};
})(typeof global !== 'undefined' ? global : /* istanbul ignore next */ this);
