const isNewerVersion = (currentVersion, newestVersion) => {
	if (typeof currentVersion !== 'string' || typeof newestVersion !== 'string') {
		return false;
	}
	const currentVersionSplitted = currentVersion.split('.');
	const newestVersionSplitted = newestVersion.split('.');
	if (
		currentVersionSplitted.length !== 3 ||
		newestVersionSplitted.length !== 3
	) {
		return false;
	}
	if (newestVersionSplitted[0] > currentVersionSplitted[0]) {
		return true;
	}
	if (newestVersionSplitted[0] < currentVersionSplitted[0]) {
		return false;
	}
	if (newestVersionSplitted[1] > currentVersionSplitted[1]) {
		return true;
	}
	if (newestVersionSplitted[1] < currentVersionSplitted[1]) {
		return false;
	}
	if (newestVersionSplitted[2] > currentVersionSplitted[2]) {
		return true;
	}
	return false;
};

export default isNewerVersion;
