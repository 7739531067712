import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/common/Alert';
import useStyles from 'styles/components/common/modals/AlertModal';

const AlertModal = ({ handleClose, message, severity }) => {
	const classes = useStyles();

	return (
		<Alert className={classes.alert} severity={severity} onClose={handleClose}>
			{message}
		</Alert>
	);
};

AlertModal.defaultProps = {
	handleClose: null,
	severity: 'success',
};

AlertModal.propTypes = {
	handleClose: PropTypes.func,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
	message: PropTypes.string.isRequired,
};

export default AlertModal;
