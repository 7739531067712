import React from 'react';
import PropTypes from 'prop-types';
import { CombinedProviders } from 'ctx-provider';
import { CryptoWalletProvider } from '@itsa.io/web3utils';
import { Provider as PageProvider } from 'context/page';
import { Provider as DarkModeProvider } from 'context/darkmode';
import { Provider as DisclaimerModalProvider } from 'context/disclaimermodal';
import { Provider as PromoFormProvider } from 'context/promoform';
import { Provider as WrappedTokenProvider } from 'context/wrappedtoken';
import { Provider as BaseTradeTokenProvider } from 'context/basetradetoken';
import { Provider as SelectedTokenProvider } from 'context/selectedtoken';
import { Provider as PendingTransactionsProvider } from 'context/pendingtransactions';
import { Provider as BlockheightProvider } from 'context/blockheight';
import { Provider as GasPriceProvider } from 'context/gasprice';
import { Provider as MainCoinPricesProvider } from 'context/maincoinprices';
import { Provider as SubscriptionProvider } from 'context/subscription';
import { Provider as TokenlistProvider } from 'context/tokenlist';
import { Provider as NavigatorProvider } from 'context/navigator';
import { Provider as CurrencyProvider } from 'context/currency';
import { Provider as ListInfoProvider } from 'context/listinfo';
import { Provider as CustomTokensProvider } from 'context/customtokens';
import { Provider as ContactsProvider } from 'context/contacts';
import { Provider as SoundProvider } from 'context/sound';
import { Provider as ShowPossibleSpamTokensProvider } from 'context/showpossiblespamtokens';
import { Provider as ExpertModeProvider } from 'context/expertmode';
import { Provider as SlippageProvider } from 'context/slippage';
import { Provider as FeedbackProvider } from 'context/feedback';
import { Provider as TogglemuteProvider } from 'context/togglemute';
import { Provider as LockTimerProvider } from 'context/locktimer';
import { Provider as LastBlockVisibleProvider } from 'context/lastblockvisible';
import { Provider as GasPriceVisibleProvider } from 'context/gaspricevisible';
import { Provider as MaxTokensProvider } from 'context/maxtokens';
import { Provider as LatestSystemMessageProvider } from 'context/latestsystemmessage';
import { Provider as ExtraGaspriceHardwareWalletProvider } from 'context/extragaspricehardwarewallet';
import { Provider as NftCardProvider } from 'context/nftcard';
import { Provider as SecuredSendTxProvider } from 'context/securedsendtx';

import { NETWORK_NAMES } from 'config/constants';

const GlobalContext = ({ pageProps, children }) => {
	const providers = [
		{
			provider: CryptoWalletProvider,
			args: {
				validChainIds: Object.keys(NETWORK_NAMES).map(chainid =>
					parseInt(chainid, 10),
				),
			},
		},
		NavigatorProvider, // must be before SelectedTokenProvider
		{
			provider: PageProvider,
			args: pageProps,
		},
		SecuredSendTxProvider,
		BlockheightProvider, // must be before TokenlistProvider
		GasPriceProvider, // must be before TokenlistProvider
		MainCoinPricesProvider,
		SubscriptionProvider,
		CustomTokensProvider, // must be before the other TokenlistProvider
		ContactsProvider,
		NftCardProvider,
		LastBlockVisibleProvider,
		GasPriceVisibleProvider,
		MaxTokensProvider,
		SoundProvider, // must be before TokenlistProvider
		ShowPossibleSpamTokensProvider, // must be before TokenlistProvider
		TogglemuteProvider, // must be before TokenlistProvider
		PendingTransactionsProvider, // must be before TokenlistProvider
		ListInfoProvider, // must be before TokenlistProvider
		TokenlistProvider, // must be before the other tokenproviders
		LatestSystemMessageProvider,
		DisclaimerModalProvider,
		PromoFormProvider,
		WrappedTokenProvider,
		BaseTradeTokenProvider,
		SelectedTokenProvider,
		DarkModeProvider,
		CurrencyProvider,
		ExpertModeProvider,
		ExtraGaspriceHardwareWalletProvider,
		SlippageProvider,
		LockTimerProvider,
		FeedbackProvider,
	];

	return (
		<CombinedProviders providers={providers}>{children}</CombinedProviders>
	);
};

GlobalContext.propTypes = {
	pageProps: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default GlobalContext;
