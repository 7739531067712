import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	menuButton: {},
	paperRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: 222,
	},
	paperContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	metamaskRoot: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		maxWidth: 400,
		flexDirection: 'column',
		textAlign: 'center',
		borderRadius: 6,
		padding: theme.spacing(3),
		backgroundColor: theme.palette.secondary.main,
	},
	metamaskTitle: {},
	metamaskCopied: {
		padding: theme.spacing(0.5),
	},
	metamaskIcon: {
		marginBottom: theme.spacing(1),
	},
	metamaskIconTitle: {
		fontWeight: 500,
	},
	metamaskTitleNetwork: {
		color: theme.palette.secondary.contrastText,
	},
	metamaskDescription: {
		color: theme.palette.secondary.contrastText,
	},
	metamaskAddressDescription: {
		fontSize: 12,
		fontWeight: 500,
		color: alpha(theme.palette.secondary.contrastText, 0.7),
		[theme.breakpoints.up('lg')]: {
			fontSize: 14,
		},
	},
	metamaskIconBox: {
		width: '100%',
		maxWidth: 300,
	},
	metamaskIconBoxInner: {
		color: alpha(theme.palette.secondary.contrastText, 0.7),
		'&:hover': {
			color: theme.palette.secondary.contrastText,
		},
		'&:hover > span': {
			textDecoration: 'underline',
		},
	},
	metamaskSelect: {
		maxWidth: 230,
	},
	metamaskSelectRoot: {
		color: alpha(theme.palette.secondary.contrastText, 0.7),
	},
	logo: {
		height: '26px',
		marginLeft: theme.spacing(2),
	},
	walletInfo: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: theme.spacing(2.8),
		marginBottom: theme.spacing(2.5),
		gap: theme.spacing(1),
	},
	walletLogoName: {
		display: 'flex',
		marginTop: theme.spacing(2.5),
		marginBottom: theme.spacing(2.5),
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.5em',
		},
	},
	walletNetworkName: {
		position: 'relative',
		display: 'flex',
		textTransform: 'uppercase',
		alignItems: 'center',
		fontWeight: 400,
		letterSpacing: 1,
		fontSize: '0.9em', // NO rem -> needs to be effected by parent element
	},
	proMode: {
		position: 'relative',
		top: -1,
		fontSize: 12,
		fontWeight: 500,
		letterSpacing: 'normal',
		color: `${theme.palette.red[400]}!important`,
		marginLeft: theme.spacing(1),
		marginTop: 'auto',
		[theme.breakpoints.up('lg')]: {
			fontSize: 12,
			top: -2,
		},
	},
	iconButtonContainer: {
		display: 'flex',
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	iconButton: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
	},
	divider: {
		backgroundColor: theme.palette.border.dark,
	},
	discordIcon: {
		width: 20,
		height: 20,
	},
	legalPrivacyLinks: {
		display: 'flex',
		fontSize: 14,
		fontWeight: 400,
		alignItems: 'center',
		justifyContent: 'center',
		columnGap: 4,
		borderTop: `1px solid ${theme.palette.border.dark}`,
		marginTop: 'auto',
		padding: theme.spacing(1.8, 0),
		'& > button:hover': {
			textDecoration: 'underline',
		},
	},
}));
