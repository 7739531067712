import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageMaxAllTokens = localStorageProperty('maxAllTokens', {
	simpleType: true,
	encoded: false,
});

const storageMaxAllTokens = localStorageMaxAllTokens.get();

const DEFAULT_MAX_ALL_TOKENS =
	typeof storageMaxAllTokens === 'boolean' ? storageMaxAllTokens : false;

const useMaxTokens = () => {
	const [allTokens, toggleMaxTokens] = useToggle(DEFAULT_MAX_ALL_TOKENS);

	useEffect(() => {
		localStorageMaxAllTokens.set(allTokens);
	}, [allTokens]);

	return {
		allTokens,
		toggleMaxTokens,
	};
};

const store = createStore(useMaxTokens);

export const { Provider } = store;
export default store.ctx;
