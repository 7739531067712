import { getWeb3 } from 'utils/get-web3';
import { utils } from 'web3';
import { CHAIN_HAS_PRIORITY_FEE } from 'config/constants';

const { toBN } = utils;

const setGasLimitAndPrice = async (
	chainId,
	rawTx,
	gasLimitBN,
	currentGasPrice,
	extraPercentageGas,
	isHardwareDevice,
) => {
	// in case we don't have a plugin like MetaMask to sign our transactions,
	// we need to manually set the gasPrice:
	if (isHardwareDevice) {
		// const gasLimitBN = gasUnitsBN
		// 	.mul(toBN((100 + GAS_LIMIT_HARDWARE_DEVICES_EXTRA_PERCENTAGE).toString()))
		// 	.div(toBN('100'));
		rawTx.gasLimit = utils.toHex(gasLimitBN);
		if (extraPercentageGas) {
			extraPercentageGas = Math.round(extraPercentageGas);
		} else {
			extraPercentageGas = 0;
		}
		if (CHAIN_HAS_PRIORITY_FEE[chainId]) {
			// Ethereum/EthereumPOW network:
			const maxPriorityFeePerGasGWEI = utils.toWei(
				extraPercentageGas.toString(),
				'gwei',
			);
			rawTx.maxPriorityFeePerGas = utils.toHex(maxPriorityFeePerGasGWEI);
			const web3 = getWeb3(chainId);
			const estimatedGas = await web3.eth.estimateGas(rawTx);
			rawTx.maxFeePerGas = utils.toHex(
				toBN(estimatedGas.toString()).add(toBN(maxPriorityFeePerGasGWEI)),
			);
		} else {
			// non Ethereum/EthereumPOW network:
			const gasPriceBN = currentGasPrice
				.mul(toBN((100 + extraPercentageGas).toString()))
				.div(toBN('100'));
			rawTx.gasPrice = utils.toHex(gasPriceBN);
		}
	}
};

export default setGasLimitAndPrice;
