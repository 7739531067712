import { toBN } from '@itsa.io/web3utils';
import {
	BN_GAS_TRADE,
	BN_GAS_WRAPPING,
	BN_GAS_SEND_COIN,
	BN_GAS_ENERGI_COLLATERIZATION,
	IS_ENERGI_CHAINID,
} from 'config/constants';

const MAX_TRADE_STEPS = 3;

const getHighestGas = chainId => {
	let gasLimitBN = BN_GAS_TRADE[chainId].ONE_STEP.add(
		toBN(MAX_TRADE_STEPS.toString()).mul(BN_GAS_TRADE[chainId].EXTRA_STEP),
	);

	let nextGasBN = BN_GAS_WRAPPING[chainId].WRAP;
	if (nextGasBN.gt(gasLimitBN)) {
		gasLimitBN = nextGasBN;
	}

	nextGasBN = BN_GAS_WRAPPING[chainId].UNWRAP;
	if (nextGasBN.gt(gasLimitBN)) {
		gasLimitBN = nextGasBN;
	}

	nextGasBN = BN_GAS_SEND_COIN;
	if (nextGasBN.gt(gasLimitBN)) {
		gasLimitBN = nextGasBN;
	}

	if (IS_ENERGI_CHAINID(chainId)) {
		nextGasBN = BN_GAS_ENERGI_COLLATERIZATION.DEPOSIT;
		if (nextGasBN.gt(gasLimitBN)) {
			gasLimitBN = nextGasBN;
		}

		nextGasBN = BN_GAS_ENERGI_COLLATERIZATION.WITHDRAWAL;
		if (nextGasBN.gt(gasLimitBN)) {
			gasLimitBN = nextGasBN;
		}
	}
	return gasLimitBN;
};

export default getHighestGas;
