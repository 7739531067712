import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert as MuiAlert } from '@itsa.io/ui';
import { Warning as WarningIcon } from '@material-ui/icons';

import useStyles from 'styles/components/common/modals/AboutToScreenlockModal';

const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AboutToScreenlockModal = ({ show, message }) => {
	const classes = useStyles();

	return (
		<Snackbar
			className={classes.root}
			open={show}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				className={classes.alert}
				severity="warning"
				icon={<WarningIcon fontSize="inherit" />}
			>
				{show && message}
			</Alert>
		</Snackbar>
	);
};

AboutToScreenlockModal.defaultProps = {
	show: false,
};

AboutToScreenlockModal.propTypes = {
	show: PropTypes.bool,
	message: PropTypes.string.isRequired,
};

export default AboutToScreenlockModal;
