const getElapsedTime = (t, time) => {
	if (!t || !time) {
		return '';
	}
	const timeMS = time.getTime();
	const now = Date.now();
	const secondBlocks = Math.round(Math.max(0, now - timeMS) / 1000);
	const minutesBlocks = Math.floor(secondBlocks / 60);
	let seconds = secondBlocks - 60 * minutesBlocks;
	let hours = Math.floor(minutesBlocks / 60);
	let minutes = minutesBlocks - 60 * hours;
	const days = Math.floor(hours / 24);
	hours -= days * 24;
	let daysString = '';
	if (days === 1) {
		daysString = `1 ${t('time.day')} `;
	} else if (days > 1) {
		daysString = `${days} ${t('time.days')} `;
	}
	let hourString = '';
	if (hours > 0) {
		hourString = `${hours}:`;
		minutes = minutes.toString().padStart(2, '0');
	}
	seconds = seconds.toString().padStart(2, '0');
	return `${daysString}${hourString}${minutes}:${seconds}`;
};

export default getElapsedTime;
