import { getMasternodeRegistryContract } from 'utils/get-contract';
import { utils } from 'web3';
import { MASTERNODE_REGISTRY_ADDRESS, HEX0 } from 'config/constants';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import { getWeb3 } from 'utils/get-web3';
import { parseEnode } from 'utils/key-parser';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';

const errorMessages = {
	INVALID_SMARTCONTRACT_ADDRESS: 'Invalid Wrap SmartContract Address',
	NO_VALID_DATA: 'No valid data',
	NO_VALID_ENODE: 'No valid Enode',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method MasternodeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const MasternodeException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'MasternodeException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.MASTERNODE_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

export const getCurrentPayout = async chainId => {
	let currentPayouts;
	try {
		const masternodeRegistryContract = getMasternodeRegistryContract(chainId);
		currentPayouts = await masternodeRegistryContract.methods
			.current_payouts()
			.call();
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		currentPayouts = 0;
	}
	return currentPayouts;
};

export const announce = async (
	chainId,
	owner,
	enode,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const masternodeRegistryContract = getMasternodeRegistryContract(chainId);

	if (!owner || !enode) {
		throw new MasternodeException(errorMessages.NO_VALID_DATA);
	}
	const enodeObj = parseEnode(enode);
	if (!enodeObj) {
		throw new MasternodeException(errorMessages.NO_VALID_ENODE);
	}
	const { masternodeAddress, ip4Address, enodeInfo } = enodeObj;
	const transactionData = masternodeRegistryContract.methods
		.announce(masternodeAddress, ip4Address, enodeInfo)
		.encodeABI();

	const rawTx = {
		to: MASTERNODE_REGISTRY_ADDRESS,
		data: transactionData,
		from: owner,
		value: HEX0,
		// gasLimit: utils.toHex(
		// 	toBN(GAS_LIMIT_ANNOUNCE)
		// 		.mul(toBN((100 + extraPercentageGas).toString()))
		// 		.div(toBN('100'))
		// 		.toString(),
		// ),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new MasternodeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId].announce,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};

export const denounce = async (
	chainId,
	owner,
	masternodeAddress,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const masternodeRegistryContract = getMasternodeRegistryContract(chainId);

	if (!owner || !masternodeAddress) {
		throw new MasternodeException(errorMessages.NO_VALID_DATA);
	}
	const transactionData = masternodeRegistryContract.methods
		.denounce(masternodeAddress)
		.encodeABI();

	const rawTx = {
		to: MASTERNODE_REGISTRY_ADDRESS,
		data: transactionData,
		from: owner,
		value: HEX0,
		// gasLimit: utils.toHex(
		// 	toBN(GAS_LIMIT_DENOUNCE)
		// 		.mul(toBN((100 + extraPercentageGas).toString()))
		// 		.div(toBN('100'))
		// 		.toString(),
		// ),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new MasternodeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId].denounce,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};
