/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getAnnouncement = async (chainId, address, blocknumber) => {
	let announcement;
	try {
		const response = await axios.post(`${API_URL}/masternodeannouncement`, {
			chainid: chainId,
			walletaddress: address,
			blocknumber,
		});
		if (response.status === 200 && response.data?.success) {
			announcement = response.data.status || null;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		announcement = null;
	}
	return announcement;
};

export default getAnnouncement;
