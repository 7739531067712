import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	backdrop: {
		// backgroundColor: `${alpha(theme.palette.background, 0.9)}!important`,
	},
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '95vw',
		maxWidth: 650,
		maxHeight: '95vh',
		transform: 'translate(-50%, -50%)',
		outline: 0,
		background: theme.palette.background.light,
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 0,
		padding: theme.spacing(5, 0, 3, 0),
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
	logo: {
		height: '40px',
		[theme.breakpoints.down('sm')]: {
			height: '35px',
		},
	},
	wrongPassword: {
		height: '2em',
		lineHeight: '2em',
		fontSize: '0.8rem',
		alignSelf: 'flex-start',
		color: theme.palette.red[600],
		paddingLeft: theme.spacing(1.75),
	},
	title: {
		position: 'relative',
		fontSize: 20,
		fontWeight: 500,
		margin: 0,
		textAlign: 'center',
		zIndex: 2,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 16,
		textAlign: 'center',
		padding: theme.spacing(4, 3),
	},
	boxes: {
		maxWidth: 550,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'left',
	},
	walletLogoName: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		'& > span': {
			color: theme.palette.orange[500],
		},
		textTransform: 'uppercase',
		letterSpacing: 2,
		fontSize: '1.2em', // NO rem -> needs to be effected by parent element
		marginTop: '8px',
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.5em',
		},
	},
	buttonConfirm: {
		fontSize: 16,
		// borderWidth: 1,
		// color: theme.palette.common.black,
		// backgroundColor: theme.palette.blue[800],
		marginTop: theme.spacing(3),
		padding: theme.spacing(1, 5),
		'&:hover': {
			// backgroundColor: theme.palette.blue[800],
		},
	},
	textFieldPassword: {
		'& .MuiInputBase-input': {
			fontSize: 18,
			fontWeight: 400,
		},
	},
}));
