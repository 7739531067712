import 'styles/main.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Container,
	CssBaseline,
	MuiThemeProvider,
	Backdrop,
	Seo,
	useMediaQuery,
	useTheme,
	usesMobileAppWallet,
} from '@itsa.io/ui';
import {
	useIntl,
	cryptowalletCtx,
	isMobile,
	later,
	walletEvents,
	localStorageProperty,
} from '@itsa.io/web3utils';
import GlobalContext from 'components/layout/GlobalContext';
import navigatorCtx from 'context/navigator';
import Header from 'components/layout/Header';
import LeftSidebar from 'components/common/LeftSidebar';
import PromoPage from 'components/pages/PromoPage';
import TabsMenu from 'components/common/TabsMenu';
import OfflineModal from 'components/common/modals/OfflineModal';
import AboutToScreenlockModal from 'components/common/modals/AboutToScreenlockModal';
import DisclaimerModal from 'components/common/modals/DisclaimerModal';
import ScreenlockModal from 'components/common/modals/ScreenlockModal';
import WalletConnect from 'components/common/WalletConnect';
import ReadingWallet from 'components/common/ReadingWallet';
import usePageMeta from 'hooks/usePageMeta';
import useOnline from 'hooks/useOnline';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import darkmodeCtx from 'context/darkmode'; // in this stage, it is not yet bacome part of the ContextProvider
import feedbackCtx from 'context/feedback';
import promoformCtx from 'context/promoform';
import locktimerCtx from 'context/locktimer';
import latestsystemmessageCtx from 'context/latestsystemmessage';
import useStyles from 'styles/components/layout/PrimaryLayout';
import useAlert from 'hooks/useAlert';
import {
	SEVERITIES,
	ENVIRONMENT,
	SHOW_ABOUT_TO_SCREENLOCK_REMAINING_SEC,
	TIMEOUT_INTRO_MESSAGE,
	PAGES_NAMES,
} from 'config/constants';

const TIMEOUT = isMobile ? 60000 : 1500; // on mobile it takes a lot of time to read walletinfo
const ALERTS = {};

const lsIntroduction = localStorageProperty('introduction', {
	simpleType: true, // we use a boolean
	encoded: false,
});

const PrimaryLayout = props => {
	return (
		<GlobalContext pageProps={props}>
			<LayoutInner {...props} />
		</GlobalContext>
	);
};

// CAUTIOUS: this is `LAYOUT` and Gatsby will use SSR during production, which combines classes
// therefore, only in `LAYOUT`, we CANNOT dynamically change the css
const LayoutInner = props => {
	const { t } = useIntl();
	const theme = useTheme();
	const alert = useAlert();
	const { initialized, connected, activated } = useContext(cryptowalletCtx);
	const [showWalletConnectByTimeOut, setShowWalletConnectByTimeOut] =
		useState(false);
	const needsWalletConnect =
		!usesMobileAppWallet &&
		(!connected || (connected && !activated && showWalletConnectByTimeOut));
	const online = useOnline();
	const { darkmode } = useContext(darkmodeCtx);
	const { feedbackMsg, feedbackHasBackdrop } = useContext(feedbackCtx);
	const { remainingSecs, locked } = useContext(locktimerCtx);
	const latestsystemmessage = useContext(latestsystemmessageCtx);
	const { setPage } = useContext(navigatorCtx);
	const {
		visible: promoformVisible,
		close: promoformClose,
		initialChainId: promoformInitialChainId,
	} = useContext(promoformCtx);
	const { pageTitle, pageDescription } = usePageMeta();
	const smallScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const classes = useStyles({ currentTheme });
	const timer = useRef();
	const timer2 = useRef();
	const nextMessage = useRef(false);
	const suppressServerMsgRef = useRef(false);
	const promoformVisibleRef = useRef(promoformVisible);
	let header;
	let leftSidebar;
	let tabsContent;
	let pageContent;
	let walletconnect;

	// console.debug(
	// 	needsWalletConnect,
	// 	usesMobileAppWallet,
	// 	connected,
	// 	activated,
	// 	showWalletConnectByTimeOut,
	// );

	if (needsWalletConnect) {
		walletconnect = <WalletConnect {...props} />;
	} else if (!initialized) {
		if (isMobile) {
			pageContent = <ReadingWallet />;
		}
	} else {
		if (smallScreenSize) {
			header = <Header {...props} />;
			tabsContent = <TabsMenu />;
		} else {
			leftSidebar = <LeftSidebar {...props} />;
		}
		pageContent = props.children;
	}

	let promopage;
	if (promoformVisible) {
		// NOTE: we do not want to "always" render promopage, because it would lead into too many rerendering when not needed
		promopage = (
			<PromoPage
				initialChainId={promoformInitialChainId}
				handleClose={promoformClose}
			/>
		);
	}

	const setWalletListeners = () => {
		const levels = Object.keys(SEVERITIES);
		levels.forEach(level => {
			const eventName = SEVERITIES[level];
			walletEvents.on(eventName, (message, needsToSign) => {
				if (message) {
					const cleanup = level === '-1';
					const isSigned = level === '-2';
					const isCancelled = level === '-3';
					if (isSigned) {
						// no message, but goto tokenpage
						if (promoformVisibleRef.current) {
							promoformClose();
						} else {
							setPage(PAGES_NAMES.TOKEN);
						}
					} else if (cleanup) {
						const alertPromise = ALERTS[message];
						if (alertPromise) {
							alertPromise.close();
							delete ALERTS[message];
						}
					} else if (isCancelled) {
						// eslint-disable-next-line no-console
						// console.debug('cancelled');
					} else {
						const alertPromise = alert(message, {
							severity: SEVERITIES[level] || 'success',
							noDupes: true,
							sender: 'cryptowallet',
							match: message,
							timeout: 0,
							needsToSign,
						});
						ALERTS[message] = alertPromise;
					}
				}
			});
		});
	};

	const removeWalletListeners = () => {
		const levels = Object.keys(SEVERITIES);
		levels.forEach(level => {
			const eventName = SEVERITIES[level];
			walletEvents.removeAllListeners(eventName);
		});
	};

	const handleIntroductionMsg = () => {
		const introductionVal = lsIntroduction.get();
		const needsIntroduction =
			typeof introductionVal === 'boolean' ? introductionVal : true;
		if (needsIntroduction) {
			suppressServerMsgRef.current = true;
			alert(t('layout.intro_message'), {
				severity: 'info',
				timeout: TIMEOUT_INTRO_MESSAGE,
			});
			lsIntroduction.set(false);
			timer2.current = later(() => {
				suppressServerMsgRef.current = false;
			}, 60000);
		}
	};

	useEffect(() => {
		promoformVisibleRef.current = promoformVisible;
	}, [promoformVisible]);

	useEffect(() => {
		// first time should be ignored: that will be called with the initial value; not by making any changes
		if (nextMessage.current) {
			if (!suppressServerMsgRef.current) {
				alert(latestsystemmessage, {
					severity: 'info',
					timeout: TIMEOUT_INTRO_MESSAGE,
				});
			}
		} else {
			nextMessage.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestsystemmessage]);

	useEffect(() => {
		const htmlNode = document.documentElement;
		// by timer: goto cryptowallet page, but only if not native mobile app
		if (!usesMobileAppWallet) {
			timer.current = later(() => {
				setShowWalletConnectByTimeOut(true);
			}, TIMEOUT);
		}
		htmlNode.setAttribute('data-visible', 'true');
		setWalletListeners();
		handleIntroductionMsg();
		return () => {
			if (timer.current) {
				timer.current.cancel();
			}
			if (timer2.current) {
				timer2.current.cancel();
			}
			removeWalletListeners();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let developmentLabel;
	if (ENVIRONMENT === 'development') {
		developmentLabel = (
			<div className={classes.developmentLabel}>Development</div>
		);
	}

	let screenlockModal;
	if (locked) {
		screenlockModal = <ScreenlockModal />;
	}

	return (
		<MuiThemeProvider theme={currentTheme}>
			<CssBaseline />
			<Seo title={pageTitle} description={pageDescription} />
			<Backdrop className={classes.backdrop} open={!!feedbackHasBackdrop} />
			{header}
			{developmentLabel}
			{screenlockModal}

			<div
				className={clsx(classes.root, {
					[classes.allItemsHidden]: locked,
				})}
			>
				{leftSidebar}
				<Container
					className={classes.container}
					component="main"
					maxWidth={false}
				>
					{promopage}
					<DisclaimerModal />
					{walletconnect}
					{pageContent}
					{feedbackMsg}
					<OfflineModal
						show={!online}
						message={t(
							online ? 'offline_modal.online' : 'offline_modal.offline',
						)}
					/>
					<AboutToScreenlockModal
						show={remainingSecs < SHOW_ABOUT_TO_SCREENLOCK_REMAINING_SEC}
						message={t('screenlock_modal.screen_about_to_lock', {
							values: {
								remaining: `${remainingSecs} ${
									remainingSecs === 1
										? t('screenlock_modal.second')
										: t('screenlock_modal.seconds')
								}`,
							},
						})}
					/>
				</Container>
				{tabsContent}
			</div>
		</MuiThemeProvider>
	);
};

PrimaryLayout.defaultProps = {
	pageContext: {},
};

PrimaryLayout.propTypes = {
	children: PropTypes.node.isRequired,
	pageContext: PropTypes.object,
};

LayoutInner.propTypes = {
	location: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default PrimaryLayout;
