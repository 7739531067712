import { formatBN, toBN } from '@itsa.io/web3utils';
import { cloneDeep } from 'lodash';
import { toFiat } from 'utils/tokenpriceToFiat';

export const getTokenPrice = (token, currency, options) => {
	if (!options) {
		options = {};
	} else {
		options = cloneDeep(options); // don't change the input parameter
	}
	const prices =
		(options.useDexPrices && token.dexPrices
			? token.dexPrices
			: token.prices) || {};
	// options.assetDigits = token.decimals;
	options.assetDigits = 18; // stablecoin

	if (options.decimals === 'auto') {
		options.decimals = prices[currency] >= 1000 ? 0 : 2;
	}

	let valueBN = toFiat(
		toBN((10 ** token.decimals).toString()),
		prices,
		currency,
		token.decimals,
	);

	// Correction in case we need to take a priceImpactPercent into account:
	if (options.priceImpactPercent) {
		let multiplier = 100 + options.priceImpactPercent; // example: 102.5 for 2.5%
		multiplier *= 10000000; // example: 1025000000 for 2.5%
		multiplier = Math.round(multiplier).toString(); // example: "10250000" for 2.5%
		valueBN = valueBN.mul(toBN(multiplier.toString())).div(toBN('1000000000'));
	}
	return formatBN(valueBN, options);
};

export const getDexTokenPrice = (token, currency, options) => {
	if (!options) {
		options = {};
	} else {
		options = cloneDeep(options); // don't change the input parameter
	}
	options.useDexPrices = true;
	return getTokenPrice(token, currency, options);
};
