import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageShowPossibleSpamTokens = localStorageProperty(
	'showPossibleSpamTokens',
	{
		simpleType: true,
		encoded: false,
	},
);

const storageShowPossibleSpamTokens = localStorageShowPossibleSpamTokens.get();

const DEFAULT_SHOW_POSSIBLE_SPAM_TOKENS =
	typeof storageShowPossibleSpamTokens === 'boolean'
		? storageShowPossibleSpamTokens
		: false;

const useShowPossibleSpamTokens = () => {
	const [showPossibleSpamTokens, toggleShowPossibleSpamTokens] = useToggle(
		DEFAULT_SHOW_POSSIBLE_SPAM_TOKENS,
	);

	useEffect(() => {
		localStorageShowPossibleSpamTokens.set(showPossibleSpamTokens);
	}, [showPossibleSpamTokens]);

	return {
		showPossibleSpamTokens,
		toggleShowPossibleSpamTokens,
	};
};

const store = createStore(useShowPossibleSpamTokens);

export const { Provider } = store;
export default store.ctx;
