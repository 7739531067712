import React, { useContext, useEffect, useRef } from 'react';
import {
	Cancel as CancelIcon,
	CheckCircle as CheckCircleIcon,
	Info as InfoIcon,
	Warning as WarningIcon,
} from '@material-ui/icons';
import { managedPromise } from '@itsa.io/web3utils';
import ConfirmModal from 'components/common/modals/ConfirmModal';
import feedbackCtx from 'context/feedback';

const icons = {
	error: CancelIcon,
	success: CheckCircleIcon,
	warning: WarningIcon,
	info: InfoIcon,
};

const useConfirm = () => {
	const { add, remove } = useContext(feedbackCtx);
	const promises = useRef([]);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			promises.current.forEach(managedPromise => {
				if (managedPromise.isPending()) {
					managedPromise.close();
				}
			});
		};
	}, []);

	const cleanup = promise => {
		const index = promises.current.indexOf(promise);
		if (index !== -1) {
			promises.current.splice(index, 1);
		}
	};

	/**
	 * Shows a confirmation modal with yes and no buttons.
	 * @param {string|Node} message Modal content
	 * @param {string=} title Modal title
	 * @param {('success'|'error'|'warning'|'info')=} severity Modal severity
	 * @returns {Promise<bool>} Boolean indicating whether yes or no was clicked
	 */
	const confirm = (message, severityOrOptions = '') => {
		const promise = managedPromise();
		let id;

		if (typeof severityOrOptions === 'string') {
			severityOrOptions = {
				severity: severityOrOptions,
			};
		}
		if (!severityOrOptions.severity) {
			severityOrOptions.severity = 'success';
		}

		const fulfill = value => {
			remove(id);
			promise.fulfill(value);
			cleanup(promise);
		};

		const onYes = () => {
			fulfill(true);
		};

		const onNo = () => {
			fulfill(false);
		};

		const onClose = () => {
			// onNo();
		};

		const Icon = icons[severityOrOptions.severity];

		const content = (
			<ConfirmModal
				icon={Icon}
				message={message}
				onYes={onYes}
				onNo={onNo}
				severity={severityOrOptions.severity}
			>
				{message}
			</ConfirmModal>
		);

		id = add({ content, onClose, backdrop: true }, severityOrOptions);

		promise.close = onNo;
		promises.current.push(promise);

		return promise;
	};

	return confirm;
};

export default useConfirm;
