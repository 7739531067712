import { useState, useEffect, useContext, useRef } from 'react';
import createStore from 'ctx-provider';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import useTokenlistMoralis from 'hooks/useTokenlistMoralis';
import useTokenlistNetwork from 'hooks/useTokenlistNetwork';
import { MORALIS_CHAINS, NETWORK_NAMES } from 'config/constants';

const useTokenList = () => {
	const { connected, chainId, address } = useContext(cryptowalletCtx);
	const {
		start: moralisStart,
		stop: moralisStop,
		data: moralisData,
		error: moralisError,
		isLoading: moralisIsLoading,
		setAddress: moralisSetAddress,
		setChainId: moralisSetChainId,
	} = useTokenlistMoralis();
	const {
		start: networkStart,
		stop: networkStop,
		data: networkData,
		error: networkError,
		isLoading: networkIsLoading,
		setAddress: networkSetAddress,
		setChainId: networkSetChainId,
	} = useTokenlistNetwork();
	const moralisChain = !!MORALIS_CHAINS[chainId];
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const listenersSet = useRef(false);
	const listenersIsMoralis = useRef(false);

	const setupListener = () => {
		const listenersActive = address && NETWORK_NAMES[chainId];
		if (!listenersActive && !listenersSet.current) {
			return;
		}
		listenersSet.current = true;
		listenersIsMoralis.current = !!MORALIS_CHAINS[chainId];
		setData([]);
		if (!listenersActive) {
			networkStop();
			moralisStop();
			setError(false);
			setIsLoading(false);
		} else if (moralisChain) {
			setIsLoading(true);
			networkStop();
			moralisStart(chainId, address);
		} else {
			setIsLoading(true);
			moralisStop();
			networkStart(chainId, address);
		}
	};

	useEffect(() => {
		return () => {
			networkStop();
			moralisStop();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			listenersSet.current &&
			listenersIsMoralis.current === !!MORALIS_CHAINS[chainId]
		) {
			if (moralisChain) {
				if (typeof moralisSetAddress === 'function') {
					moralisSetAddress(address);
				}
			} else if (typeof networkSetAddress === 'function') {
				networkSetAddress(address);
			}
		} else {
			setupListener();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address]);

	useEffect(() => {
		if (
			listenersSet.current &&
			listenersIsMoralis.current === !!MORALIS_CHAINS[chainId]
		) {
			if (moralisChain) {
				if (typeof moralisSetChainId === 'function') {
					moralisSetChainId(chainId);
				}
			} else if (typeof networkSetChainId === 'function') {
				networkSetChainId(chainId);
			}
		} else {
			setupListener();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId]);

	useEffect(() => {
		if (connected && NETWORK_NAMES[chainId] && !moralisChain) {
			setData(networkData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkData]);

	useEffect(() => {
		if (!moralisChain) {
			setError(networkError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkError]);

	useEffect(() => {
		if (!moralisChain) {
			setIsLoading(networkIsLoading);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkIsLoading]);

	useEffect(() => {
		if (moralisChain) {
			setData(moralisData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moralisData]);

	useEffect(() => {
		if (moralisChain) {
			setError(moralisError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moralisError]);

	useEffect(() => {
		if (moralisChain) {
			setIsLoading(moralisIsLoading);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moralisIsLoading]);

	const getTokenInfo = tokenAddress => {
		if (isLoading || typeof tokenAddress !== 'string') {
			return null;
		}
		return data[tokenAddress.toLowerCase()];
	};

	return {
		data,
		error,
		isLoading,
		getTokenInfo,
	};
};

const store = createStore(useTokenList);

export const { Provider } = store;
export default store.ctx;
