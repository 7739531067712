import { useEffect, useState, useContext } from 'react';
import createStore from 'ctx-provider';
import { LISTINFOS, ETHEREUM_POW_CHAINIDS } from 'config/constants';
import { localStorageProperty, cryptowalletCtx } from '@itsa.io/web3utils';

const localStorageListinfo = localStorageProperty('listinfo', {
	simpleType: true,
	encoded: false,
});

const useTokenList = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const [listinfoSettings, setListinfoSettings] = useState(
		localStorageListinfo.get() || 'balance',
	);
	const getSafeListinfo = (chainid, listitem) => {
		// tokenprices on EthereumPOW are not valid, since they cannot be traded on centralised exchanges.
		// therefore, we ALWAYS will use dexPrices in case tokenprice is selected
		return ETHEREUM_POW_CHAINIDS[chainid] && listitem === 'tokenprice'
			? 'dextokenprice'
			: listitem;
	};
	const [listinfo, setListinfoState] = useState(
		getSafeListinfo(chainId, listinfoSettings),
	);

	useEffect(() => {
		const safeListinfoSettings = getSafeListinfo(chainId, listinfoSettings);
		setListinfoState(safeListinfoSettings);
	}, [listinfoSettings, chainId]);

	const setListinfo = listitem => {
		if (typeof listitem === 'string') {
			listitem = listitem.toLowerCase();
			if (LISTINFOS.includes(listitem)) {
				setListinfoSettings(listitem);
				localStorageListinfo.set(listitem);
			}
		}
	};

	return {
		listinfo,
		listinfoSettings,
		setListinfo,
	};
};

const store = createStore(useTokenList);

export const { Provider } = store;
export default store.ctx;
