import 'styles/main.css';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Container, CssBaseline, MuiThemeProvider, Seo } from '@itsa.io/ui';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import darkmodeCtx, { Provider as DarkModeProvider } from 'context/darkmode'; // in this stage, it is not yet bacome part of the ContextProvider
import useStyles from 'styles/components/layout/SecondaryLayout';
import usePageMeta from 'hooks/usePageMeta';
import { ENVIRONMENT } from 'config/constants';

const SecondaryLayout = props => {
	return (
		<DarkModeProvider>
			<LayoutInner {...props} />
		</DarkModeProvider>
	);
};

SecondaryLayout.defaultProps = {
	pageContext: {},
};

SecondaryLayout.propTypes = {
	children: PropTypes.node.isRequired,
	pageContext: PropTypes.object,
};

// CAUTIOUS: this is `LAYOUT` and Gatsby will use SSR during production, which combines classes
// therefore, only in `LAYOUT`, we CANNOT dynamically change the css
const LayoutInner = ({ children }) => {
	const { darkmode } = useContext(darkmodeCtx);
	const { pageTitle, pageDescription } = usePageMeta();
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const classes = useStyles({ currentTheme });

	let developmentLabel;
	if (ENVIRONMENT === 'development') {
		developmentLabel = (
			<div className={classes.developmentLabel}>Development</div>
		);
	}

	return (
		<MuiThemeProvider theme={currentTheme}>
			<CssBaseline />
			<Seo title={pageTitle} description={pageDescription} />
			{developmentLabel}
			<Container
				className={clsx(classes.root, classes.container)}
				component="main"
				maxWidth={false}
			>
				{children}
			</Container>
		</MuiThemeProvider>
	);
};
LayoutInner.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SecondaryLayout;
