import { useEffect, useState } from 'react';
import latestVersion from 'api/latest-version';
import { later } from '@itsa.io/web3utils/lib/timers';
import { unregisterServiceworkers } from '@itsa.io/web3utils';

const UPDATE_INTERVAL = 1 * 60 * 1000; // every 1 mins

const subscribers = [];

let timer;

let prevVersion = '';

const updateVersion = async currentVersion => {
	const version = await latestVersion();
	if (version !== prevVersion) {
		prevVersion = version;
		if (currentVersion !== version) {
			// remove serviceworker, because we don't want the next reload to use the cached (prev) version of the wallet
			await unregisterServiceworkers();
		}
		subscribers.forEach(cb => cb(version));
	}
};

const subscribe = (currentVersion, cb) => {
	subscribers.push(cb);
	if (subscribers.length === 1) {
		timer = later(
			updateVersion.bind(null, currentVersion),
			100,
			UPDATE_INTERVAL,
		);
	}
};

const unsubscribe = cb => {
	const index = subscribers.findIndex(cbFn => cbFn === cb);
	if (index !== -1) {
		subscribers.splice(index, 1);
	}
	if (subscribers.length === 0 && timer) {
		timer.cancel();
		prevVersion = '';
	}
};

const useLatestWalletVersion = currentVersion => {
	const [walletversion, setWalletversion] = useState(prevVersion);

	useEffect(() => {
		subscribe(currentVersion, setWalletversion);
		return () => {
			unsubscribe(setWalletversion);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return walletversion;
};

export default useLatestWalletVersion;
