import { utils } from 'web3';

const { toBN } = utils;
export const GAS_LIMIT_HARDWARE_DEVICES_EXTRA_PERCENTAGE = 2;

export const BN_GAS_UNIT_PRICES = {
	cointransfer: {
		1: toBN('21000'),
		42: toBN('21000'),
		56: toBN('21000'),
		137: toBN('21000'),
		10001: toBN('21000'),
		39797: toBN('21000'),
		49797: toBN('21000'),
	},
	tokentransfer: {
		1: toBN('63164'),
		42: toBN('63164'),
		56: toBN('63164'),
		137: toBN('63164'),
		10001: toBN('63164'),
		39797: toBN('63164'),
		49797: toBN('63164'),
	},
	tokenapproval: {
		1: toBN('52581'),
		42: toBN('52581'),
		56: toBN('52581'),
		137: toBN('52581'),
		10001: toBN('52581'),
		39797: toBN('52581'),
		49797: toBN('52581'),
	},
	swapexactcoinfortokens: {
		1: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		42: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		56: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		137: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		10001: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		39797: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('549919'),
			extrastep: toBN('0'),
		},
	},
	swapcoinforexacttokens: {
		1: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		42: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		56: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		137: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		10001: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		39797: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('557710'),
			extrastep: toBN('0'),
		},
	},
	swapexacttokensforcoin: {
		1: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		42: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		56: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		137: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		10001: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		39797: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('570527'),
			extrastep: toBN('0'),
		},
	},
	swaptokensforexactcoin: {
		1: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		42: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		56: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		137: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		10001: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		39797: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('570488'),
			extrastep: toBN('0'),
		},
	},
	swapexacttokensfortokens: {
		1: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		42: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		56: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		137: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		10001: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		39797: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
		49797: {
			onestep: toBN('525824'),
			extrastep: toBN('438189'),
		},
	},
	swaptokensforexacttokens: {
		1: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		42: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		56: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		137: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		10001: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		39797: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
		49797: {
			onestep: toBN('525968'),
			extrastep: toBN('438248'),
		},
	},
	itsasubscription: {
		1: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		42: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		56: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		137: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		10001: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		39797: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
		49797: {
			subscribe: toBN('48997'),
			trial: toBN('50567'),
			bindtosubscription: toBN('28537'),
			removeboundsubscription: toBN('13015'),
			approveboundsubscription: toBN('50719'),
			removeboundsubscriptionapproval: toBN('45165'),
			setapprovedmultipleboundsubscriptions: toBN('143762'),
		},
	},
	wrapping: {
		1: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		42: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		56: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		137: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		10001: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		39797: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
		49797: {
			wrap: toBN('43291'),
			unwrap: toBN('52051'),
		},
	},
	masternode: {
		39797: {
			deposit: toBN('103425'),
			withdraw: toBN('59882'),
			announce: toBN('347145'),
			denounce: toBN('97010'),
		},
		49797: {
			deposit: toBN('103425'),
			withdraw: toBN('59882'),
			announce: toBN('347145'),
			denounce: toBN('97010'),
		},
	},
	silvernft: {
		1: {
			transferFrom: toBN('42347'),
		},
		56: {
			transferFrom: toBN('42347'),
		},
		137: {
			transferFrom: toBN('42347'),
		},
		10001: {
			transferFrom: toBN('42347'),
		},
		39797: {
			transferFrom: toBN('42347'),
		},
		49797: {
			transferFrom: toBN('42347'),
		},
	},
	goldnft: {
		1: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
		56: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
		137: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
		10001: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
		39797: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
		49797: {
			subscribeAddress: toBN('76059'),
			unsubscribeAddress: toBN('52055'),
			resetSubscriptions: toBN('103185'),
			setAllSubscriptions: toBN('419076'),
			transferFrom: toBN('54157'),
		},
	},
	platinanft: {
		1: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
		56: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
		137: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
		10001: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
		39797: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
		49797: {
			subscribeAddress: toBN('76075'),
			unsubscribeAddress: toBN('192779'),
			resetSubscriptions: toBN('468765'),
			setAllSubscriptions: toBN('1925717'),
			transferFrom: toBN('53506'),
		},
	},
};

export const BN_GAS_LIMIT_UNIT_PRICES = {
	cointransfer: {
		1: toBN('31500'),
		42: toBN('31500'),
		56: toBN('31500'),
		137: toBN('31500'),
		10001: toBN('31500'),
		39797: toBN('27000'),
		49797: toBN('27000'),
	},
	tokentransfer: {
		1: toBN('95000'),
		42: toBN('95000'),
		56: toBN('95000'),
		137: toBN('95000'),
		10001: toBN('95000'),
		39797: toBN('95000'),
		49797: toBN('90000'),
	},
	tokenapproval: {
		1: toBN('85000'),
		42: toBN('85000'),
		56: toBN('85000'),
		137: toBN('85000'),
		10001: toBN('85000'),
		39797: toBN('85000'),
		49797: toBN('78871'),
	},
	swapexactcoinfortokens: {
		1: {
			onestep: toBN('891439'),
			extrastep: toBN(''),
		},
		42: {
			onestep: toBN('891439'),
			extrastep: toBN(''),
		},
		56: {
			onestep: toBN('891439'),
			extrastep: toBN(''),
		},
		137: {
			onestep: toBN('891439'),
			extrastep: toBN(''),
		},
		10001: {
			onestep: toBN('891439'),
			extrastep: toBN(''),
		},
		39797: {
			onestep: toBN('891439'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('891439'),
			extrastep: toBN('0'),
		},
	},
	swapcoinforexacttokens: {
		1: {
			onestep: toBN('897466'),
			extrastep: toBN(''),
		},
		42: {
			onestep: toBN('897466'),
			extrastep: toBN(''),
		},
		56: {
			onestep: toBN('897466'),
			extrastep: toBN(''),
		},
		137: {
			onestep: toBN('897466'),
			extrastep: toBN(''),
		},
		10001: {
			onestep: toBN('897466'),
			extrastep: toBN(''),
		},
		39797: {
			onestep: toBN('897466'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('897466'),
			extrastep: toBN('0'),
		},
	},
	swapexacttokensforcoin: {
		1: {
			onestep: toBN('980000'),
			extrastep: toBN(''),
		},
		42: {
			onestep: toBN('980000'),
			extrastep: toBN(''),
		},
		56: {
			onestep: toBN('980000'),
			extrastep: toBN(''),
		},
		137: {
			onestep: toBN('980000'),
			extrastep: toBN(''),
		},
		10001: {
			onestep: toBN('980000'),
			extrastep: toBN(''),
		},
		39797: {
			// onestep: toBN('950000'),
			onestep: toBN('980000'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('980000'),
			extrastep: toBN('0'),
		},
	},
	swaptokensforexactcoin: {
		1: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		42: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		56: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		137: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		10001: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		39797: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
		49797: {
			onestep: toBN('917419'),
			extrastep: toBN('0'),
		},
	},
	swapexacttokensfortokens: {
		1: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		42: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		56: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		137: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		10001: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		39797: {
			// onestep: toBN('833683'),
			// extrastep: toBN('688820'),
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		49797: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
	},
	swaptokensforexacttokens: {
		1: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		42: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		56: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		137: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		10001: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		39797: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
		49797: {
			onestep: toBN('900000'),
			extrastep: toBN('690000'),
		},
	},
	itsasubscription: {
		1: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		42: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		56: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		137: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		10001: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		39797: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
		49797: {
			subscribe: toBN('92180'),
			trial: toBN('75850'),
			bindtosubscription: toBN('76825'),
			removeboundsubscription: toBN('51430'),
			approveboundsubscription: toBN('110002'),
			removeboundsubscriptionapproval: toBN('100135'),
			setapprovedmultipleboundsubscriptions: toBN('258687'),
		},
	},
	wrapping: {
		1: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		42: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		56: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		137: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		10001: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		39797: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
		49797: {
			wrap: toBN('65400'),
			unwrap: toBN('75000'),
		},
	},
	masternode: {
		39797: {
			// deposit: toBN('202507'),
			// withdraw: toBN('171211'),
			// announce: toBN('557106'),
			// denounce: toBN('304917'),
			// depositWhileAnnounced: toBN('202507').add(toBN('557106')),
			// withdrawWhileAnnounced: toBN('171211').add(toBN('557106')),
			// withdrawWhileAnnouncedAndDenounce: toBN('171211').add(toBN('304917')),
			deposit: toBN('250000'),
			withdraw: toBN('200000'),
			depositWhileAnnounced: toBN('1450000'),
			withdrawWhileAnnounced: toBN('1400000'),
			withdrawWhileAnnouncedAndDenounce: toBN('900000'),
			announce: toBN('1200000'),
			denounce: toBN('700000'),
		},
		49797: {
			deposit: toBN('250000'),
			withdraw: toBN('200000'),
			depositWhileAnnounced: toBN('1450000'),
			withdrawWhileAnnounced: toBN('1400000'),
			withdrawWhileAnnouncedAndDenounce: toBN('900000'),
			announce: toBN('1200000'),
			denounce: toBN('700000'),
		},
	},
	silvernft: {
		1: {
			transferFrom: toBN('99940'),
		},
		56: {
			transferFrom: toBN('99940'),
		},
		137: {
			transferFrom: toBN('99940'),
		},
		10001: {
			transferFrom: toBN('99940'),
		},
		39797: {
			transferFrom: toBN('99940'),
		},
		49797: {
			transferFrom: toBN('99940'),
		},
	},
	goldnft: {
		1: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
		56: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
		137: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
		10001: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
		39797: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
		49797: {
			subscribeAddress: toBN('148396'),
			unsubscribeAddress: toBN('112390'),
			resetSubscriptions: toBN('186973'),
			setAllSubscriptions: toBN('671946'),
			transferFrom: toBN('117655'),
		},
	},
	platinanft: {
		1: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
		56: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
		137: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
		10001: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
		39797: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
		49797: {
			subscribeAddress: toBN('148420'),
			unsubscribeAddress: toBN('323476'),
			resetSubscriptions: toBN('735343'),
			setAllSubscriptions: toBN('2973955'),
			transferFrom: toBN('116679'),
		},
	},
};
