import { later } from '@itsa.io/web3utils/lib/timers';

const PINGTIME_FROM_SERVER = 30000;
const TIMEOUT = PINGTIME_FROM_SERVER + 10000;

let socketConnection;
let timer;

class Client {
	constructor(chainId, address, onData, onClose, onPing, onError) {
		if (
			typeof chainId !== 'number' ||
			typeof address !== 'string' ||
			typeof onData !== 'function' ||
			typeof onClose !== 'function' ||
			typeof onPing !== 'function' ||
			typeof onError !== 'function'
		) {
			// eslint-disable-next-line no-console
			console.error(
				'Client Class Error: last four arguments should be a functions',
			);
		} else {
			this.onData = onData;
			this.onClose = onClose;
			this.onError = onError;
			this.onPing = onPing;
			this.chainId = chainId;
			this.address = address;
			this.setupConnection();
		}
	}

	destroy() {
		this.connection._destroyed = true;
		this.connection.close();
	}

	isDestroyed() {
		return !!this.connection?._destroyed;
	}

	setupConnection() {
		this.connection = new window.WebSocket('wss://socketserver.itsa.io/');

		const { connection } = this;

		// eslint-disable-next-line no-console
		connection.onerror = err => {
			if (!this.isDestroyed()) {
				this.onError(err.message);
			}
		};

		connection.onopen = async () => {
			const data = {
				action: 'REGISTER_ACCOUNT',
				chainId: this.chainId,
				address: this.address,
			};
			this.sendData(data);
		};

		connection.onclose = () => {
			if (!this.isDestroyed()) {
				connection._destroyed = true;
				this.onClose();
			}
		};

		connection.onmessage = event => {
			const { data } = event;
			let dataObject;
			try {
				dataObject = JSON.parse(data);
				if (dataObject.status === 'OK') {
					if (dataObject.message === 'Balances') {
						this.onData('new', dataObject.data);
					} else if (dataObject.message === 'BalancesUpdate') {
						this.onData('update', dataObject.data);
					} else if (dataObject.message === 'PING') {
						if (!this.isDestroyed()) {
							this.onPing();
						}
					}
				} else {
					// eslint-disable-next-line no-console
					console.error(dataObject.error);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		};
	}

	sendData(data) {
		const { connection } = this;
		if (connection && !this.isDestroyed()) {
			connection.pongTime = Date.now(); // update
			connection.send(JSON.stringify(data));
		}
	}

	setAddress(address) {
		this.address = address;
		const data = {
			action: 'SWITCH_ADDRESS',
			address: this.address,
		};
		this.sendData(data);
	}

	setChainId(chainId) {
		this.chainId = chainId;
		const data = {
			action: 'SWITCH_CHAINID',
			chainId: this.chainId,
		};
		this.sendData(data);
	}
}

const createMoralisSocketConnection = (chainId, address, onData, onError) => {
	let client;
	let createNewClient;

	const onClose = () => {
		if (createNewClient) {
			createNewClient();
		}
	};

	const onPing = () => {
		if (client && !client.isDestroyed()) {
			const data = {
				action: 'PONG',
			};
			try {
				client.sendData(data);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('ERROR PONG', err);
			}
		}
	};

	const cancel = () => {
		if (timer) {
			timer.cancel();
		}
		if (client) {
			client.destroy();
		}
	};

	const setAddress = address => {
		client.setAddress(address);
	};

	const setChainId = chainId => {
		client.setChainId(chainId);
	};

	const getAddress = () => client.address;

	const getChainId = () => client.chainId;

	const isDestroyed = () => {
		return !!client?.isDestroyed();
	};

	createNewClient = () => {
		if (client) {
			client.destroy();
		}
		// create new client
		client = new Client(chainId, address, onData, onClose, onPing, onError);
	};

	timer = later(
		() => {
			if (
				client.isDestroyed() ||
				Date.now() - TIMEOUT > client.connection.pongTime
			) {
				createNewClient();
			}
		},
		PINGTIME_FROM_SERVER,
		true,
	);

	createNewClient();

	return {
		cancel,
		setChainId,
		setAddress,
		getAddress,
		getChainId,
		isDestroyed,
	};
};

const moralisSocketConnection = (chainId, address, onData, onError) => {
	if (!socketConnection || socketConnection.isDestroyed()) {
		socketConnection = createMoralisSocketConnection(
			chainId,
			address,
			onData,
			onError,
		);
	}
	return socketConnection;
};

export default moralisSocketConnection;
