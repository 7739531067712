import { useState } from 'react';
import createStore from 'ctx-provider';

const useNftCard = () => {
	const [selectedNftCard, setSelectedNftCard] = useState();

	return {
		selectedNftCard,
		setSelectedNftCard,
	};
};

const store = createStore(useNftCard);

export const { Provider } = store;
export default store.ctx;
