import { createTheme } from '@itsa.io/ui';

export default createTheme({
	typography: {
		fontFamily: [
			'Poppins',
			'Lato',
			'Open Sans',
			'Roboto',
			'system-ui',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'"Noto Sans"',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
			'"Noto Color Emoji"',
		].join(','),
	},
});
