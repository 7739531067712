import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@itsa.io/ui';
import useStyles from 'styles/components/common/YesNoButton';

const YesNoButton = ({ onYes, onNo }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Button
				className={classes.buttonConfirm}
				variant="outlined"
				size="large"
				onClick={onNo}
			>
				No
			</Button>
			<Button
				className={classes.buttonConfirm}
				variant="outlined"
				size="large"
				onClick={onYes}
			>
				Yes
			</Button>
		</div>
	);
};

YesNoButton.propTypes = {
	onNo: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
};

export default YesNoButton;
