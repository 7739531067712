/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getCurrencyPrices = async () => {
	let currencyPrices;
	try {
		const uri = 'currencyprices';
		const response = await axios.post(`${API_URL}/${uri}`);
		if (response.status === 200 && response.data?.success) {
			currencyPrices = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		currencyPrices = [];
	}
	return currencyPrices;
};

export default getCurrencyPrices;
