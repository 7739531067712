import getSDK from 'utils/get-sdk';
import { toBN } from '@itsa.io/web3utils';
import { getTokenContract } from 'utils/get-contract';
import { utils } from 'web3';
import { BN_MAXUINT256, HEX0, BN_ZERO } from 'config/constants';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import { getWeb3 } from 'utils/get-web3';

const { isBN, isAddress } = utils;

const errorMessages = {
	TOKEN_CONTRACT_EXCEPTION: 'Token Contract Exception',
	NOT_ENOUGH_BALANCE: 'Not enough balance',
	INVALID_SMARTCONTRACT_ADDRESS: 'Invalid Smartcontract Address',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method MasternodeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const TokenException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'TokenException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.TOKEN_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

export const transfer = async (
	chainId,
	tokenAddress,
	from,
	recipient,
	amount,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const tokenContract = getTokenContract(chainId, tokenAddress);
	// return tokenContract.methods.transfer(recipient, amount).call();
	if (isBN(amount)) {
		amount = amount.toString();
	}

	if (amount.toString() === '0') {
		throw new TokenException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const transactionData = tokenContract.methods
		.transfer(recipient, amount)
		.encodeABI();

	const rawTx = {
		to: tokenAddress,
		data: transactionData,
		from,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TokenException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.tokentransfer[chainId],
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const getToken = (chainId, token) => {
	const { Token } = getSDK(chainId);
	return new Token(
		chainId,
		token.address,
		token.decimals,
		token.symbol,
		token.name,
	);
};

export const getTokenByAddress = async (chainId, tokenAddress) => {
	if (!chainId || !isAddress(tokenAddress)) {
		return null;
	}
	const token = {
		address: tokenAddress,
	};
	try {
		const tokenContract = getTokenContract(chainId, tokenAddress);
		token.name = await tokenContract.methods.name().call();
		token.symbol = await tokenContract.methods.symbol().call();
		token.decimals = await tokenContract.methods.decimals().call();
	} catch (err) {
		return null;
	}
	return getToken(chainId, token);
};

export const getTokenBalance = async (chainId, tokenAddress, walletAddress) => {
	let balance;
	if (!chainId || !isAddress(tokenAddress)) {
		return BN_ZERO;
	}
	try {
		const tokenContract = getTokenContract(chainId, tokenAddress);
		balance = await tokenContract.methods.balanceOf(walletAddress).call();
	} catch (err) {
		balance = '0';
	}
	return toBN(balance);
};

export const enougBalance = (allowance, amountToSpend) =>
	!!allowance && !!amountToSpend && toBN(amountToSpend).lte(toBN(allowance));

// check the currentTokenAllowance the tokenCashier has approval to spend for
export const currentTokenAllowance = async (
	chainId,
	tokenAddress,
	walletAddress,
	spenderAddress,
) => {
	const contract = getTokenContract(chainId, tokenAddress);
	return contract.methods
		.allowance(walletAddress, spenderAddress)
		.call({ from: walletAddress });
};

export const hasApproval = async (
	chainId,
	tokenAddress,
	walletAddress,
	spenderAddress,
	amountToSpend, // in wei
) => {
	if (!spenderAddress) {
		// eslint-disable-next-line no-console
		console.error('Error in hasApproval: no spenderAddress defined');
		return false;
	}
	const allowance = await currentTokenAllowance(
		chainId,
		tokenAddress,
		walletAddress,
		spenderAddress,
	);
	return enougBalance(allowance, amountToSpend);
};

export const approve = async (
	chainId,
	tokenAddress,
	walletAddress,
	spenderAddress,
	amountToSpend,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const tokenContract = getTokenContract(chainId, tokenAddress);
	// return tokenContract.methods.transfer(recipient, amount).call();
	if (isBN(amountToSpend)) {
		amountToSpend = amountToSpend.toString();
	}

	if (amountToSpend.toString() === '0') {
		throw new TokenException(errorMessages.NOT_ENOUGH_BALANCE);
	}
	const transactionData = tokenContract.methods.approve(
		spenderAddress,
		amountToSpend,
	);
	const data = transactionData.encodeABI();

	const rawTx = {
		from: walletAddress,
		to: tokenAddress,
		data,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TokenException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.tokenapproval[chainId],
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const approveMax = async (
	chainId,
	tokenAddress,
	walletAddress,
	spenderAddress,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) =>
	approve(
		chainId,
		tokenAddress,
		walletAddress,
		spenderAddress,
		BN_MAXUINT256,
		sendTx,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);
