import createStore from 'ctx-provider';
import usePageMeta from 'hooks/usePageMeta';

const usePage = pageProps => {
	const { pageTitle, pageDescription } = usePageMeta();

	return {
		...pageProps,
		pageTitle,
		pageDescription,
	};
};

const store = createStore(usePage);

export const { Provider } = store;
export default store.ctx;
