import { useContext } from 'react';
import { capitalize, lowercase } from 'lodash';
import { useIntl } from '@itsa.io/web3utils';
import navigatorCtx from 'context/navigator';

/**
 * Gets page title and description from translation
 * based on fixed page name.
 *
 * @returns {object}
 */

const HOMEPAGE = 'indexpage';

const usePageMeta = () => {
	const { t } = useIntl();
	const { page } = useContext(navigatorCtx);
	const walletName = t('common.walletname');
	const pageName = lowercase(page).toLowerCase() || HOMEPAGE;
	const pageTranslationName = pageName.replace(/\s/g, '') || HOMEPAGE;

	const pageTitle = t(`page.${pageTranslationName}.page_title`, {
		def: capitalize(pageName) || walletName,
	});

	const pageDescription = t(`page.${pageName}.heading`, {
		def: walletName,
	});

	return {
		pageTitle,
		pageDescription,
	};
};

export default usePageMeta;
