import { useEffect, useState } from 'react';
import createStore from 'ctx-provider';

const usePromoForm = () => {
	const [visible, setVisible] = useState(false);
	const [initialChainId, setInitialChainId] = useState();

	const open = chainId => {
		if (chainId) {
			setInitialChainId(chainId);
		}
		setVisible(true);
	};

	const close = () => {
		setVisible(false);
	};

	useEffect(() => {
		if (visible) {
			window.scrollTo(0, 0);
		}
	}, [visible]);

	return {
		visible,
		initialChainId,
		open,
		close,
	};
};

const store = createStore(usePromoForm);

export const { Provider } = store;
export default store.ctx;
