import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Avatar as TokenImg } from '@itsa.io/ui';
import { toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import { utils } from 'web3';
import tokenListCtx from 'context/tokenlist';
import selectedtokenCtx from 'context/selectedtoken';
import navigatorCtx from 'context/navigator';
import addressMatch from 'utils/address-match';
import {
	PAGES_NAMES,
	WRAPPED_ADDRESSES,
	getMainSymbol,
	getMainName,
} from 'config/constants';
import useStyles from 'styles/components/common/TabsMenu';

const { isBN } = utils;
const BN_ZERO = toBN('0');

const TabsMenus = () => {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const { chainId } = useContext(cryptowalletCtx);
	const { data: tokenList } = useContext(tokenListCtx);
	const { selectedToken, setTokenAddress } = useContext(selectedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const [wrappedTokenAddress, setWrappedTokenAddress] = useState(
		WRAPPED_ADDRESSES[chainId]?.toLowerCase(),
	);

	useEffect(() => {
		setWrappedTokenAddress(WRAPPED_ADDRESSES[chainId]?.toLowerCase());
	}, [chainId]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleSelectMenuItem = tokenAddress => {
		setTokenAddress(tokenAddress);
		setPage(PAGES_NAMES.TOKEN);
	};

	const tabItems = tokenList
		.filter(
			token =>
				(isBN(token.balance) && token.balance.gt(BN_ZERO)) ||
				addressMatch(token.address, wrappedTokenAddress) ||
				token.hasPendingTransaction,
		)
		.map(token => {
			return (
				<Tab
					className={clsx(classes.tab)}
					icon={
						<TokenImg
							className={clsx(classes.tokenImg, {
								[classes.pendingTransaction]: token.hasPendingTransaction,
							})}
							alt={getMainName(token.name, token.symbol)}
							src={token.image}
						>
							<TokenImg
								className={clsx(classes.tokenImg, {
									[classes.pendingTransaction]: token.hasPendingTransaction,
								})}
								alt={getMainName(token.name, token.symbol)}
								src={token.logoURI}
							>
								?
							</TokenImg>
						</TokenImg>
					}
					label={getMainSymbol(token.symbol)}
					key={token.address}
					onClick={() => handleSelectMenuItem(token.address)}
					selected={token.address === selectedToken?.address}
				/>
			);
		});

	return (
		<Tabs
			className={classes.tabs}
			value={value}
			onChange={handleChange}
			variant="scrollable"
			scrollButtons="auto"
		>
			{tabItems}
		</Tabs>
	);
};

export default TabsMenus;
