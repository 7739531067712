import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		color: theme.palette.text.secondary,
		display: 'none',
		gap: theme.spacing(0.5),
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
		},
	},
	menuOpen: {
		display: 'flex',
	},
	gasprice: {
		// color: theme.palette.text.primary,
	},
}));
