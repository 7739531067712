import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/common/Alert';
import YesNoButton from 'components/common/YesNoButton';
import useStyles from 'styles/components/common/modals/ConfirmModal';

const ConfirmModal = ({ handleClose, message, onNo, onYes, severity }) => {
	const classes = useStyles();

	return (
		<Alert
			action={<YesNoButton onNo={onNo} onYes={onYes} />}
			className={classes.confirm}
			onClose={handleClose}
			severity={severity}
		>
			{message}
		</Alert>
	);
};

ConfirmModal.defaultProps = {
	handleClose: null,
	severity: 'success',
};

ConfirmModal.propTypes = {
	handleClose: PropTypes.func,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
	message: PropTypes.string.isRequired,
	onNo: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
};

export default ConfirmModal;
