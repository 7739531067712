import Web3 from 'web3';
import { getWeb3 } from 'utils/get-web3';
import {
	WRAPPED_ADDRESSES,
	FACTORY_ADDRESSES,
	ROUTER_ADDRESSES,
	MASTERNODE_TOKEN_ADDRESS,
	MASTERNODE_REGISTRY_ADDRESS,
	MASTERNODE_TOKEN_ABI,
	MASTERNODE_REGISTRY_ABI,
	FACTORY_ABIS,
	ROUTER_ABIS,
	PAIR_ABIS,
	ITSA_SUBSCRIPTION_SC_ADDRESSES,
	ITSA_NFTS_SC_ADDRESSES,
	ITSA_NFTS_ABIS,
} from 'config/constants';
import ERC20_ABI from 'config/constants/abi/ERC20.json';
import WRAP_ABI from 'config/constants/abi/Wrap.json';
import ITSA_SUBSCRIPTION_ABI from 'config/constants/abi/ItsaSubscription.json';

const CACHED_CONTRACTS = {};

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
	try {
		return Web3.utils.isAddress(value);
	} catch {
		return false;
	}
}

// account is optional
export function getContract(chainId, address, ABI, prependKey) {
	let contract;
	// if (!isAddress(address) || address === AddressZero) {
	if (!isAddress(address)) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	try {
		let key = `${chainId}#${address}`;
		if (prependKey) {
			key += `#${prependKey}`;
		}
		if (!CACHED_CONTRACTS[key]) {
			const web3 = getWeb3(chainId);
			CACHED_CONTRACTS[key] = new web3.eth.Contract(ABI, address);
		}
		contract = CACHED_CONTRACTS[key];
	} catch (err) {
		throw new Error(err);
	}
	return contract;
}

export function getItsaSubscriptionContract(chainId) {
	if (!ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId]) {
		return null;
	}
	return getContract(
		chainId,
		ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId],
		ITSA_SUBSCRIPTION_ABI,
	);
}

export function getSilverNftContract(chainId) {
	if (!ITSA_NFTS_SC_ADDRESSES[chainId]) {
		return null;
	}
	return getContract(
		chainId,
		ITSA_NFTS_SC_ADDRESSES[chainId].silver,
		ITSA_NFTS_ABIS.silver,
	);
}

export function getGoldNftContract(chainId) {
	if (!ITSA_NFTS_SC_ADDRESSES[chainId]) {
		return null;
	}
	return getContract(
		chainId,
		ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		ITSA_NFTS_ABIS.gold,
	);
}

export function getPlatinaNftContract(chainId) {
	if (!ITSA_NFTS_SC_ADDRESSES[chainId]) {
		return null;
	}
	return getContract(
		chainId,
		ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		ITSA_NFTS_ABIS.platina,
	);
}

export function getWrappedContract(chainId) {
	return getContract(chainId, WRAPPED_ADDRESSES[chainId], WRAP_ABI, 'key-wrap');
}

export function getFactoryContract(chainId) {
	return getContract(
		chainId,
		FACTORY_ADDRESSES[chainId],
		FACTORY_ABIS[chainId],
	);
}

export function getPairContract(chainId, pairAddress) {
	return getContract(chainId, pairAddress, PAIR_ABIS[chainId]);
}

export function getRouterContract(chainId) {
	return getContract(chainId, ROUTER_ADDRESSES[chainId], ROUTER_ABIS[chainId]);
}

export function getTokenContract(chainId, tokenAddress) {
	return getContract(chainId, tokenAddress, ERC20_ABI);
}

export function getMasternodeTokenContract(chainId) {
	return getContract(chainId, MASTERNODE_TOKEN_ADDRESS, MASTERNODE_TOKEN_ABI);
}

export function getMasternodeRegistryContract(chainId) {
	return getContract(
		chainId,
		MASTERNODE_REGISTRY_ADDRESS,
		MASTERNODE_REGISTRY_ABI,
	);
}
