import { useState } from 'react';
import createStore from 'ctx-provider';

const DEFAULT_PAGE = 'indexpage';

const useTokenList = () => {
	const [page, setPage] = useState(DEFAULT_PAGE);

	return {
		page,
		setPage,
	};
};

const store = createStore(useTokenList);

export const { Provider } = store;
export default store.ctx;
