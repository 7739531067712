import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		position: 'absolute',
		display: 'grid',
		placeContent: 'center',
		maxWidth: 1000,
		width: '100%',
		left: 0,
		right: 0,
		margin: '0 auto',
		padding: theme.spacing(0, 3),
		textAlign: 'center',
		userSelect: 'none',
		zIndex: 9999,
		'& > *': {
			position: 'relative',
			zIndex: 9999,
		},
		'&::before': {
			position: 'fixed',
			content: '""',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			userSelect: 'none',
			background: theme.palette.background.light,
			backdropFilter: 'blur(5px)',
			zIndex: 9999,
		},
	},
	logo: {
		height: '65px',
	},
	walletLogoName: {
		marginBottom: theme.spacing(5),
	},
	radioControl: {
		margin: theme.spacing(12, 'auto', 0, 'auto'),
	},
	gaspriceSlider: {
		width: '20%',
		marginLeft: '40%',
	},
	underline: {
		position: 'relative',
		display: 'inline',
		'&::before': {
			position: 'absolute',
			content: '""',
			left: 0,
			bottom: '-5px',
			width: '100%',
			height: 2,
			backgroundColor: theme.palette.orange[500],
		},
	},
	title: {},
	promoText: {
		maxWidth: 770,
		margin: theme.spacing(2, 'auto'),
		fontSize: '1rem',
		fontWeight: 500,
	},
	buttonVerticalGroup: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing(2),
		width: '100%',
		maxWidth: 500,
		margin: '0 auto',
		justifyContent: 'space-evenly',
		marginTop: theme.spacing(5),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			columnGap: theme.spacing(2),
		},
	},
	percentageButton: {
		display: 'block',
		width: '100%',
	},
	promoFeatures: {
		marginTop: theme.spacing(6),
		textAlign: 'left',
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(15),
		},
	},
	promoFeaturesTitle: {
		fontSize: '1.8rem',
		fontWeight: '900',
		textAlign: 'center',
	},
	promoFeatureText: {
		fontSize: '1.4rem',
		fontWeight: 400,
		textAlign: 'center',
		margin: theme.spacing(1, 0, 0, 0),
	},
	promoFeatureList: {
		listStyle: 'none',
		marginTop: theme.spacing(4),
		padding: 0,
		[theme.breakpoints.up('sm')]: {
			display: 'grid',
			gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
			gap: theme.spacing(4),
		},
	},
	promoFeatureItem: {
		display: 'flex',
		marginTop: theme.spacing(2),
	},
	iconWrap: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(3),
	},
	checkCircleIcon: {
		fontSize: 33,
		color: theme.palette.yellow[500],
	},
	promoFeatureTitle: {
		fontSize: '1.1rem',
		fontWeight: 500,
	},
	promoFeaturetText: {
		fontSize: '1rem',
		color: theme.palette.primary.main,
		margin: 0,
	},
	buttonIcon: {
		marginTop: theme.spacing(3),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover $icon': {
			borderColor: theme.palette.secondary.dark,
			backgroundColor: theme.palette.default.light,
		},
		'&.Mui-disabled $icon': {
			opacity: 0.25,
		},
		fontSize: 14,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
			textTransform: 'uppercase',
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(0),
		},
	},
	buttonIconActive: {
		backgroundColor: 'transparent',
		'& $icon': {
			borderColor: theme.palette.secondary.dark,
			backgroundColor: theme.palette.default.light,
		},
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 60,
		height: 60,
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${theme.palette.default.light}`,
		borderRadius: 12,
		padding: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	iconImgUnknown: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.secondary.light,
	},
	switchWrap: {
		display: 'flex',
		placeContent: 'center',
		gap: theme.spacing(4),
		marginTop: theme.spacing(4),
	},
	switchType: {
		fontSize: '1.2rem',
		fontWeight: '400',
	},
	switch: () => {
		return {
			width: 42,
			height: 26,
			padding: 0,
			'& .MuiSwitch-switchBase': {
				padding: 0,
				margin: 2,
				transitionDuration: '300ms',
				'&.Mui-checked': {
					transform: 'translateX(16px)',
					color: '#fff',
					'& + .MuiSwitch-track': {
						backgroundColor: theme.palette.orange[700],
						opacity: 1,
						border: 0,
					},
					'&.Mui-disabled + .MuiSwitch-track': {
						opacity: 0.5,
					},
				},
				'&.Mui-focusVisible .MuiSwitch-thumb': {
					color: '#33cf4d',
					border: '6px solid #fff',
				},
				'&.Mui-disabled .MuiSwitch-thumb': {
					color: theme.palette.grey[100],
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.7,
				},
			},
			'& .MuiSwitch-thumb': {
				boxSizing: 'border-box',
				width: 22,
				height: 22,
				backgroundColor: theme.palette.primary.light,
			},
			'& .MuiSwitch-track': {
				borderRadius: 26 / 2,
				backgroundColor: theme.palette.orange[700],
				opacity: 1,
				transition: theme.transitions.create(['background-color'], {
					duration: 500,
				}),
			},
		};
	},
	priceWrap: {
		marginTop: theme.spacing(2),
	},
	price: {
		fontSize: '1.5rem',
		fontWeight: 500,
	},
	nftWrapper: {
		marginTop: theme.spacing(3),
	},
	nftTitle: {
		fontSize: '1.25rem',
		fontWeight: 500,
		margin: 0,
	},
	pricefiat: {
		fontSize: '1.25rem',
		fontWeight: 300,
	},
	currency: {
		fontSize: '1rem',
		textTransform: 'uppercase',
		marginLeft: '0.15rem',
		fontWeight: 300,
	},
	noFees: {
		fontSize: '0.85rem',
		fontWeight: 400,
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(-4),
		right: theme.spacing(0),
		color: theme.palette.common.white,
	},
	openseaIcon: {
		color: theme.palette.text.primary,
		height: 48,
	},
	buttonLink: {
		'&:hover': {
			textDecoration: 'none',
		},
	},
}));
