// import * as sdkUniswap from '@uniswap/v2-sdk';
import * as sdkUniswap from '@uniswap/sdk';
import * as sdkPancakeswap from '@pancakeswap/sdk';
import * as sdkEnergi from '@energi/energiswap-sdk';
import * as sdkPolygon from 'quickswap-sdk';

const SDKs = {
	1: sdkUniswap,
	42: sdkUniswap,
	56: sdkPancakeswap,
	100: sdkUniswap,
	137: sdkPolygon,
	10001: sdkUniswap,
	39797: sdkEnergi,
	49797: sdkEnergi,
};

const getSDK = chainId => SDKs[chainId];

export default getSDK;
