import { cloneDeep } from 'lodash';
// import { later } from '@itsa.io/web3utils';
import tokensMulticall from 'utils/smartcontracts/tokens-multicall';
import dexTokens from 'api/dexTokens';
import addressMatch from 'utils/address-match';
import { WRAPPED_ADDRESSES, TOKEN_TEMPLATES } from 'config/constants';

class Tokens {
	constructor() {
		const instance = this; // optimize for minifying
		instance.callbacks = [];
		instance.followNr = 0;
		instance.restartFn = instance.restart.bind(instance);
		instance.init();
	}

	init(emitEmptyTokenlist) {
		const instance = this; // optimize for minifying
		instance.tokens = [];
		instance.error = null;
		instance.loading = true;
		if (emitEmptyTokenlist) {
			instance.callbacks.forEach(fn => fn());
		}
		if (
			instance.chainId &&
			(instance.chainId !== dexTokens.chainId || !dexTokens.chainId)
		) {
			dexTokens.start(instance.chainId, instance.restartFn);
		}
	}

	ensureWrappedToken = () => {
		const { chainId, tokens } = this;
		const wrappedAddress = WRAPPED_ADDRESSES[chainId];
		// TODO: do wee need to deepClone tokens?
		const wrappedToken = tokens.find(t =>
			addressMatch(t.address, wrappedAddress),
		);
		if (!wrappedToken && TOKEN_TEMPLATES[chainId]) {
			const token = cloneDeep(TOKEN_TEMPLATES[chainId]);
			tokens.push(token);
		}
	};

	multicallCallback = (followNr, err, newData) => {
		const instance = this; // optimize for minifying
		if (followNr === instance.followNr) {
			// only process the latest callback
			if (err) {
				instance.error = err;
				instance.tokens = [];
			} else {
				instance.error = null;
				instance.tokens = newData;
				instance.ensureWrappedToken();
			}
			instance.loading = false;
			instance.callbacks.forEach(fn => fn());
		}
	};
	/*
	async extractData(web3, txHash, followNr) {
		// dexTokens.tokens
		const instance = this; // optimize for minifying
		const { tokens } = instance;

		console.debug('CALLBACK');
		instance.callbacks.forEach(fn => fn());

	}

	async subscribePendingTxs() {
		const instance = this; // optimize for minifying
		try {
			if (instance.chainId) {
				const cbFn = instance.extractData.bind(instance);
				instance.subscriptionTimer = later(cbFn, 0, 1000);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}

	unsubscribePendingTxs() {
		const instance = this; // optimize for minifying
		if (instance.subscriptionTimer) {
			instance.subscriptionTimer.cancel();
			instance.subscriptionTimer = null;
		}
	}
	*/

	stop(callbackFn) {
		const instance = this; // optimize for minifying
		const index = instance.callbacks.indexOf(callbackFn);
		tokensMulticall.stopWatcher();
		if (index !== -1) {
			instance.callbacks.splice(index, 1);
		}
		instance.tokens = [];
		if (instance.callbacks.length === 0) {
			dexTokens.stop(instance.restartFn);
		}
	}

	restart() {
		const instance = this; // optimize for minifying
		if (instance.chainId && instance.address) {
			instance.start();
		}
	}

	async start(chainId, address, callback) {
		const instance = this; // optimize for minifying
		let emitEmptyTokenlist = false;
		tokensMulticall.stopWatcher();
		// instance.unsubscribePendingTxs();
		if (chainId) {
			if (instance.chainId && instance.chainId !== chainId) {
				emitEmptyTokenlist = true;
			}
			instance.chainId = chainId;
		}
		if (address) {
			if (instance.address && instance.address !== address) {
				emitEmptyTokenlist = true;
			}
			instance.address = address;
		}
		if (callback) {
			instance.callbacks.push(callback);
		}

		instance.init(emitEmptyTokenlist);
		if (instance.chainId && instance.address) {
			await dexTokens.isReady();
			instance.followNr += 1;
			tokensMulticall.startWatcher(
				instance.chainId,
				instance.address,
				dexTokens.tokens,
				instance.multicallCallback.bind(instance, instance.followNr), // make sure only the latest callback will process the data
			);
			// instance.subscribePendingTxs(); // make sure only the latest callback will process the data
		}
	}
}

const tokens = new Tokens();

export default tokens;
