import { makeStyles, alpha } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

const drawerWidth = 222;
export default makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer,
	},
	metamaskRoot: {
		display: 'flex',
		alignItems: 'center',
		width: '95vw',
		maxWidth: 550,
		maxHeight: '95vh',
		overflowY: 'auto',
		flexDirection: 'column',
		textAlign: 'center',
		background: theme.palette.background.light,
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
		padding: theme.spacing(3),
	},
	metamaskTitle: {},
	metamaskCopied: {
		padding: theme.spacing(0.5),
	},
	metamaskIcon: {
		marginBottom: theme.spacing(1),
	},
	metamaskIconTitle: {
		fontWeight: 500,
	},
	metamaskTitleNetwork: {
		color: theme.palette.primary.main,
	},
	metamaskDescription: {
		color: theme.palette.primary.main,
	},
	metamaskAddressDescription: {
		fontSize: 16,
		fontWeight: 500,
		// color: alpha(theme.palette.primary.main, 0.8),
		[theme.breakpoints.up('lg')]: {
			fontSize: 14,
		},
	},
	metamaskIconBox: {
		width: '100%',
		maxWidth: 300,
	},
	metamaskIconBoxInner: {
		// color: alpha(theme.palette.primary.main, 0.7),
		'&:hover': {
			// color: theme.palette.primary.main,
		},
		'&:hover > span': {
			textDecoration: 'underline',
		},
	},
	metamaskSelect: {
		maxWidth: 230,
	},
	walletConnectContainer: {
		width: '100%',
		padding: theme.spacing(0, 2),
		'&>button': {
			width: '100%',
			border: `1px solid ${theme.palette.border.light}`,
			borderRadius: 12,
			backgroundColor: alpha(theme.palette.secondary.light, 0.08),
			'&:hover': {
				backgroundColor: alpha(theme.palette.secondary.light, 0.2),
			},
			'&>span.MuiButton-label': {
				display: 'flex',
				justifyContent: 'space-between',
				fontSize: '1em',
			},
			'& .MuiButton-startIcon, .MuiButton-endIcon': {
				margin: 0,
			},
		},
	},
	startIconClasses: {
		padding: theme.spacing(4),
	},
	logo: {
		position: 'relative',
		left: -6,
		height: '22px',
		[theme.breakpoints.up('lg')]: {
			height: '26px',
		},
	},
	walletInfo: {
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing(3),
		gap: theme.spacing(1),
	},
	walletLogoName: {
		display: 'flex',
		marginRight: 'auto',
		marginTop: theme.spacing(2.5),
		marginBottom: 'auto',
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.5em',
		},
	},
	walletNetworkName: {
		position: 'relative',
		display: 'flex',
		textTransform: 'uppercase',
		alignItems: 'center',
		fontWeight: 400,
		letterSpacing: 1,
		fontSize: '0.9em', // NO rem -> needs to be effected by parent element
	},
	bottomBoxContainer: {
		display: 'none',
		marginTop: 'auto',
		flexDirection: 'column',
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
		},
	},
	bottomBoxContainerOpen: {
		display: 'flex',
	},
	iconButtonContainer: {
		display: 'flex',
		margin: theme.spacing(0, 2, 1, 2),
	},
	bottomNav: {
		margin: theme.spacing(1, 2),
	},
	legalPrivacyLinks: {
		display: 'flex',
		fontSize: 14,
		fontWeight: 400,
		alignItems: 'center',
		justifyContent: 'center',
		columnGap: 4,
		borderTop: `1px solid ${theme.palette.border.dark}`,
		padding: theme.spacing(1.8, 0),
		'& > button:hover': {
			textDecoration: 'underline',
		},
	},
	iconButton: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
	},
	discordIcon: {
		width: 20,
		height: 20,
	},
	menuButton: {
		display: 'block',
		margin: theme.spacing(1, 'auto'),
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	paperRoot: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(11),
		border: 'none',
		overflowY: 'hidden',
		zIndex: theme.zIndex.drawer + 1,
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(11),
			paddingBottom: theme.spacing(11),
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0),
		},
	},
	drawer: {
		position: 'fixed',
		display: 'none',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		height: 'auto',
		zIndex: theme.zIndex.drawer,
		[theme.breakpoints.up('md')]: {
			display: 'block',
			width: theme.spacing(9),
		},
		[theme.breakpoints.up('lg')]: {
			display: 'block',
			width: drawerWidth,
		},
	},
	drawerOpen: {
		width: drawerWidth,
		overflowX: 'hidden',
		transition: theme.transitions.create(['width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create(['width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 0,
		[theme.breakpoints.up('md')]: {
			width: theme.spacing(9),
		},
		[theme.breakpoints.up('lg')]: {
			width: drawerWidth,
		},
	},
	toolbar: {
		display: 'none',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(0, 3),
		},
	},
	list: {
		overflowX: 'hidden',
		overflowY: 'auto',
		paddingTop: '1px',
	},
	listInfoSetting: {
		display: 'none',
		height: '1rem',
		alignSelf: 'flex-end',
		paddingRight: theme.spacing(3),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
	},
	listInfoSettingOpen: {
		display: 'block',
	},
	listItem: {
		flexDirection: 'column',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		'&:first-child': {
			borderBottom: `1px solid ${theme.palette.border.dark}`,
		},
		'&:last-child': {
			borderBottom: `1px solid ${theme.palette.border.dark}`,
		},
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
		'& span.bn-suffix': {
			marginLeft: '0.1rem',
		},
		[theme.breakpoints.up('lg')]: {
			flexDirection: 'row',
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
	},
	listItemOpen: {
		flexDirection: 'row',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	listItemIcon: {
		minWidth: 'auto',
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			minWidth: 33,
		},
	},
	pendingTransaction: {
		animation: `$blink 1.5s infinite`,
	},
	pendingApproval: {
		transition: theme.transitions.create(['width', 'height', 'padding'], {
			easing: theme.transitions.easing.easeInOut,
			duration: '1s',
		}),
		border: 'solid 1px #ff0',
		borderRadius: '100%',
		height: 28,
		width: 28,
		padding: 4,
	},
	listItemIconOpen: {
		minWidth: 33,
	},
	circularContainer: {
		width: '100%',
		textAlign: 'center',
		marginTop: theme.spacing(10),
	},
	tokenImg: {
		width: 24,
		height: 24,
		padding: 0,
	},
	tokenImgUnknown: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.secondary.light,
	},
	hidden: {
		display: 'none',
	},
	proMode: {
		position: 'relative',
		top: -1,
		fontSize: 12,
		fontWeight: 500,
		letterSpacing: 'normal',
		color: `${theme.palette.red[400]}!important`,
		marginLeft: theme.spacing(1),
		marginTop: 'auto',
		[theme.breakpoints.up('lg')]: {
			fontSize: 12,
			top: -2,
		},
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.25,
		},
	},
}));
