const colors = {
	black: '#000000',
	white: '#FFFFFF',
	grey: {
		50: '#f1f1f1',
		100: '#d7d7d7',
		200: '#909090',
		300: '#646464',
		400: '#424242',
		500: '#303030',
		600: '#1d1d1d',
		700: '#181818',
		800: '#141414',
		900: '#0b0b0b',
	},
	yellow: {
		500: '#f0cc15',
		600: '#f3b333',
	},
	green: {
		50: '#eaf5ea',
		100: '#c9e7cb',
		200: '#a6d7a8',
		300: '#82c785',
		400: '#67bb6a',
		500: '#4caf50',
		600: '#45a849',
		700: '#3c9f40',
		800: '#339637',
		900: '#248627',
	},
	red: {
		50: '#fee8e7',
		100: '#fcc7c3',
		200: '#faa19b',
		300: '#f77b72',
		400: '#f65f54',
		500: '#f44336',
		600: '#f33d30',
		700: '#f13429',
		800: '#ef2c22',
		900: '#ec1e16',
	},
	orange: {
		50: '#fff8e1',
		100: '#ffecb5',
		200: '#ffe083',
		300: '#ffd451',
		400: '#ffca2c',
		500: '#ffc107',
		600: '#ffbb06',
		700: '#ffb305',
		800: '#ffab04',
		900: '#ff9e02',
	},
	blue: {
		50: '#e4f2fe',
		100: '#bce0fb',
		200: '#90cbf9',
		300: '#64b6f7',
		400: '#42a6f5',
		500: '#2196f3',
		600: '#1d8ef1',
		700: '#1883ef',
		800: '#1479ed',
		900: '#0b68ea',
	},
};

export default colors;
