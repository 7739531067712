import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		maxWidth: 600,
		zIndex: 9999,
		[theme.breakpoints.up('sm')]: {
			width: '100%',
		},
	},
	confirm: {
		width: '100%',
		padding: theme.spacing(1.5, 2),
		borderRadius: 6,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& .MuiAlert-action': {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				marginRight: 0,
			},
		},
	},
}));
