import { useState } from 'react';
import createStore from 'ctx-provider';
import { CURRENCIES } from 'config/constants';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageCurrency = localStorageProperty('currency', {
	simpleType: true,
	encoded: false,
});

const useTokenList = () => {
	const [currency, setCurrencyState] = useState(
		localStorageCurrency.get() || 'usd',
	);

	const setCurrency = c => {
		if (typeof c === 'string') {
			c = c.toLowerCase();
			if (CURRENCIES.includes(c)) {
				setCurrencyState(c);
				localStorageCurrency.set(c);
			}
		}
	};

	return {
		currency,
		setCurrency,
	};
};

const store = createStore(useTokenList);

export const { Provider } = store;
export default store.ctx;
