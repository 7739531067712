/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getMarketInfo = async (chainId, tokenlist) => {
	let marketinfo;
	try {
		const response = await axios.post(`${API_URL}/marketinfo`, {
			chainid: chainId,
			tokenlist,
		});
		if (response.status === 200 && response.data?.success) {
			marketinfo = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		marketinfo = {};
	}
	return marketinfo;
};

export default getMarketInfo;
