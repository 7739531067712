import React from 'react';
import { CircularProgress } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';
import useStyles from 'styles/components/common/ReadingWallet';

const WalletConnect = () => {
	const classes = useStyles();
	const { t } = useIntl();

	return (
		<div className={classes.root}>
			<span>{t('layout.reading_wallet')}</span>
			<div className={classes.circularContainer}>
				<CircularProgress size={30} />
			</div>
		</div>
	);
};
export default WalletConnect;
