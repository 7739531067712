import axios from 'axios';
import { isEqual, cloneDeep } from 'lodash';
import { later } from '@itsa.io/web3utils/lib/timers';
import { NETWORK_NAMES, API_URL } from 'config/constants';
import { toBN } from '@itsa.io/web3utils';

const REFRESH_TIME_MS = 1000; // every 1 second: TODO use websocket connection to get push notifications

const transformData = pendingTransaction => {
	const transaction = cloneDeep(pendingTransaction);
	if (transaction.data) {
		const keys = Object.keys(transaction.data);
		keys.forEach(key => {
			if (
				transaction.data.bignumbers &&
				transaction.data.bignumbers.includes(key)
			) {
				transaction.data[key] = toBN(transaction.data[key]);
			}
		});
	}
	if (typeof transaction.value !== 'string') {
		transaction.value = '';
	}
	if (transaction.value === '') {
		transaction.value = '0';
	}
	transaction.value = toBN(transaction.value);
	transaction.time = new Date(transaction.time);
	return transaction;
};

class DexTokens {
	constructor() {
		this.callbacks = [];
		this.init();
	}

	async init() {
		this.pendingTransactions = [];
		this.pendingTransactionsPrevBeforeMapping = [];
		this.loading = true;
		this.error = null;
	}

	isReady() {
		const instance = this;
		if (!instance.loading) {
			return Promise.resolve();
		}
		instance.resolve(); // prevent prev subscribers from never becoming resolved
		return new Promise(resolve => {
			instance.readyPromiseResolveFn = resolve;
		});
	}

	resolve() {
		const instance = this; // optimize for minifying
		if (instance.readyPromiseResolveFn) {
			instance.readyPromiseResolveFn();
			delete instance.readyPromiseResolveFn;
		}
	}

	async reloadPendingTransactions() {
		const instance = this; // optimize for minifying
		const { chainId, address } = instance;

		try {
			const response = await axios.post(`${API_URL}/pendingtransactions`, {
				chainid: chainId,
				walletaddress: address,
			});

			if (response.status === 200 && response.data?.success) {
				const prevLoading = instance.loading;
				instance.loading = false;
				let newPendingTransactions = response.data.data;
				const dataChanged = !isEqual(
					instance.pendingTransactionsPrevBeforeMapping,
					newPendingTransactions,
				);
				instance.pendingTransactionsPrevBeforeMapping = cloneDeep(
					newPendingTransactions,
				);
				if (prevLoading || dataChanged) {
					newPendingTransactions = newPendingTransactions.map(transformData);
					instance.pendingTransactions = newPendingTransactions;
					instance.callbacks.forEach(fn => fn());
				}
				instance.resolve();
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}

	stop(callbackFn, chainIdSwitch) {
		const instance = this; // optimize for minifying
		const index = instance.callbacks.indexOf(callbackFn);
		if (index !== -1) {
			instance.callbacks.splice(index, 1);
		}
		if (instance.callbacks.length === 0 || chainIdSwitch) {
			if (instance.timer) {
				instance.timer.cancel();
				delete instance.timer;
			}
		}
		instance.tokens = [];
		instance.resolve();
	}

	start(chainId, address, callback) {
		if (NETWORK_NAMES[chainId] && address) {
			// only continue if it is a supported network
			const instance = this; // optimize for minifying
			const chainIdSwitch = instance.chainId !== chainId; // clear previous timer
			const addressSwitch = instance.address !== address; // clear previous timer
			instance.chainId = chainId;
			instance.address = address;
			instance.callbacks.push(callback);
			if (chainIdSwitch || addressSwitch) {
				instance.stop(null, true);
			} else if (instance.timer) {
				instance.timer.cancel();
			}
			instance.init();
			const reloadPendingTransactions =
				instance.reloadPendingTransactions.bind(instance);
			// setup listener
			instance.timer = later(
				reloadPendingTransactions,
				0,
				REFRESH_TIME_MS, // interval
			);
		}
	}
}

const dexTokens = new DexTokens();

export default dexTokens;
