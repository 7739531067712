import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		boxShadow: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	toolbar: {
		display: 'flex',
		columnGap: theme.spacing(1),
	},
	menuButton: {},
	walletLogoName: {
		display: 'flex',
		marginRight: 'auto',
	},
	navIconButton: {},
	walletConnectContainer: {
		'&>button': {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			border: `1px solid ${theme.palette.secondary.light}`,
			borderRadius: 12,
			padding: theme.spacing(0.5, 1.5),
			'&>span.MuiButton-label': {
				display: 'flex',
				justifyContent: 'space-between',
				columnGap: theme.spacing(1),
			},
			'& .MuiButton-startIcon, .MuiButton-endIcon': {
				marginRight: 0,
			},
			'& .MuiButton-endIcon': {
				marginLeft: theme.spacing(2),
			},
		},
	},
	metamaskRoot: {
		display: 'flex',
		alignItems: 'center',
		width: '95vw',
		maxWidth: 550,
		maxHeight: '95vh',
		overflowY: 'auto',
		flexDirection: 'column',
		textAlign: 'center',
		borderRadius: 6,
		padding: theme.spacing(3),
		backgroundColor: theme.palette.default.main,
		border: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
	},
	metamaskTitle: {},
	metamaskCopied: {
		padding: theme.spacing(0.5),
	},
	metamaskIcon: {
		marginBottom: theme.spacing(1),
	},
	metamaskIconTitle: {
		fontWeight: 500,
	},
	metamaskTitleNetwork: {
		color: theme.palette.primary.main,
	},
	metamaskDescription: {
		color: theme.palette.primary.main,
	},
	metamaskAddressDescription: {
		fontSize: 12,
		fontWeight: 500,
		color: alpha(theme.palette.primary.main, 0.7),
		[theme.breakpoints.up('lg')]: {
			fontSize: 14,
		},
	},
	metamaskIconBox: {
		width: '100%',
		maxWidth: 300,
	},
	metamaskIconBoxInner: {
		color: alpha(theme.palette.primary.main, 0.7),
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&:hover > span': {
			textDecoration: 'underline',
		},
	},
	metamaskSelect: {
		maxWidth: 230,
	},
	metamaskSelectRoot: {
		color: alpha(theme.palette.primary.main, 0.7),
	},
	logo: {
		height: 18,
		[theme.breakpoints.up('sm')]: {
			height: 26,
		},
	},
	proMode: {
		position: 'relative',
		top: -1,
		fontSize: 10,
		fontWeight: 500,
		letterSpacing: 'normal',
		color: `${theme.palette.red[400]}!important`,
		marginLeft: theme.spacing(0.5),
		marginTop: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 12,
			top: -2,
		},
	},
}));
