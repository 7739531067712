import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { toBN, formatBN } from '@itsa.io/web3utils';
import { cloneDeep, isEqual } from 'lodash';
import {
	getMainSymbol,
	WRAPPED_ADDRESSES,
	BN_ZERO,
	ETHEREUM_POW_CHAINIDS,
	LIMIT_TOKENS,
	API_URL,
} from 'config/constants';
import soundCtx from 'context/sound';
import maxtokensCtx from 'context/maxtokens';
import togglemuteCtx from 'context/togglemute';
import gaspriceCtx from 'context/gasprice';
import customtokensCtx from 'context/customtokens';
import pendingtransactionsCtx from 'context/pendingtransactions';
import listinfoCtx from 'context/listinfo';
import blockheightCtx from 'context/blockheight';
import getBlockHeight from 'utils/get-blockheight';
import { getTokenBalance } from 'utils/smartcontracts/token';
import { getReserves } from 'utils/smartcontracts/pair';
import dexTokens from 'api/dexTokens';
import tokens from 'api/tokens';
import coinInfo from 'api/coinInfo';
import addressMatch from 'utils/address-match';
import { playSound } from 'utils/play-sound';
import addressSortsBefore from 'utils/address-sorts-before';

const sortFn = (wrappedTokenAddress, a, b) => {
	if (a.address.toLowerCase() === wrappedTokenAddress) {
		return -1;
	}
	if (b.address.toLowerCase() === wrappedTokenAddress) {
		return 1;
	}
	if (a.symbol < b.symbol) {
		return -1;
	}
	if (a.symbol > b.symbol) {
		return 1;
	}
	return 0;
};

const transformTokenData = dbToken => {
	const token = cloneDeep(dbToken);
	token.image = `/tokens/${getMainSymbol(token.symbol.toUpperCase())}.svg`;
	return token;
};

const transformCustomTokenData = async (
	chainId,
	walletAddress,
	responseCurrencyprices,
	customToken,
) => {
	const token = cloneDeep(customToken);
	if (!token.symbol) {
		token.symbol = 'UNDEF';
	}
	if (token.balance) {
		token.balance = toBN(token.balance);
	} else {
		token.balance = await getTokenBalance(
			chainId,
			token.address,
			walletAddress,
		);
	}
	token.image = `/tokens/${getMainSymbol(token.symbol.toUpperCase())}.svg`;
	token.visualDecimals = 4;
	token.prices = {};
	if (!token.priceusd) {
		token.priceusd = 0;
	}

	// eslint-disable-next-line func-names
	token.sortsBefore = function (token1) {
		return addressSortsBefore(this.address, token1.address);
	};

	responseCurrencyprices.forEach(currencyprice => {
		token.prices[currencyprice.currency.toLowerCase()] =
			token.priceusd / currencyprice.priceusd;
	});
	delete token.priceusd;
	try {
		token.decimals = parseInt(token.decimals, 10);
	} catch (err) {
		token.decimals = 0;
	}
	return token;
};

const withoutUSD = list => {
	return list.map(item => {
		return {
			address: item.address,
			balance: item.balance.toString(),
			coinBalance: item.coinBalance ? item.coinBalance.toString() : null,
			collateral: item.collateral ? item.collateral.toString() : null,
		};
	});
};

const combineDexPrices = async (
	chainId,
	list,
	responseCurrencyprices,
	currentBlockHeight,
) => {
	// because token appears in the list, we will calculate the dex price
	const wrappedToken = list[0];
	if (wrappedToken) {
		const currencyprices = {};
		responseCurrencyprices.forEach(responseCurrencyprice => {
			currencyprices[responseCurrencyprice.currency.toLowerCase()] =
				responseCurrencyprice.priceusd;
		});
		// eslint-disable-next-line camelcase
		const coinPrice10_18_BN = toBN(
			Math.round(wrappedToken.prices.usd * 100000000).toString(),
		).mul(toBN('10000000000'));
		// eslint-disable-next-line no-restricted-syntax
		for (const token of list) {
			if (token.balance.gt(BN_ZERO)) {
				// console.debug(token);
				if (!addressMatch(wrappedToken.address, token.address)) {
					const reserves = await getReserves(
						chainId,
						wrappedToken,
						token,
						true,
						currentBlockHeight,
					);
					if (reserves[0] && reserves[1]) {
						token.currencyprices = currencyprices; // we may need this to calculate toFiatFromDexPrices later on
						token.calculateTotalAssetPriceFromDexPrice =
							ETHEREUM_POW_CHAINIDS[chainId]; // because ethereumPOW its tokens cannot be traded on centralised exchanges ATM
						token.reserves0BN = toBN(reserves[0]);
						token.reserves1BN = toBN(reserves[1]);
						const dexPrice = coinPrice10_18_BN
							.mul(token.reserves0BN)
							.div(token.reserves1BN);
						const dexPriceUSDString = formatBN(dexPrice, {
							withThousandSeparator: false,
							assetDigits: 18,
							decimals: 4,
							locale: 'en-US', // we need dot as decimal separator
						});
						let dexPriceUSD = parseFloat(dexPriceUSDString);
						// in case we introduced an exponential, then we will have to ignore the dexprice
						const dexPriceUSDString2 = dexPriceUSD.toString().toLowerCase();
						const hasExponential = dexPriceUSDString2.indexOf('e') !== -1;
						if (hasExponential) {
							dexPriceUSD = 0;
						}
						token.dexPrices = {};
						responseCurrencyprices.forEach(currencyprice => {
							token.dexPrices[currencyprice.currency.toLowerCase()] =
								dexPriceUSD / currencyprice.priceusd;
						});
					}
				}
			}
		}
	}
};

const combinePendingTransactions = (
	chainId,
	newData,
	pendingtransactions,
	wrappedTokenAddress,
) => {
	if (
		!newData ||
		!newData.length ||
		!pendingtransactions ||
		!pendingtransactions.length
	) {
		if (newData && newData[0]) {
			newData.forEach(token => {
				token.hasPendingTransaction = false;
				token.isBeingApproved = false;
			});
			newData[0].hasPendingTransaction = false;
			newData[0].hasPendingEnergiMasternodeCollateralChange = false;
			newData[0].isEnergiMasternodeAnnouncing = false;
			newData[0].isEnergiMasternodeDenouncing = false;
			newData[0].isGoingToGetFullAccess = false;
			newData[0].isChangingBoundSubscription = false;
			newData[0].isChangingBoundWalletList = false;
			newData[0].isTransferringNftOwnership = {};
			newData[0].isChangingNftUnlockedWallets = {};
		}
		return;
	}
	const impactedTokens = {};
	const tokensApprovals = {};
	let coinImpact = false;
	let energiMasternodeCollateralChange = false;
	let energiMasternodeAnnouncing = false;
	let energiMasternodeDenouncing = false;
	let gettingSubscriptionOrTrial = false;
	let boundSubscription = false;
	let boundWalletList = false;
	const nftUnlockedWallets = {};
	const transferringNftOwnership = {};
	pendingtransactions.forEach(pt => {
		const { data } = pt;
		const { type, nftType, nftId, tokenId } = data;
		if (pt.coinimpact) {
			coinImpact = true;
			if (
				type === 'energiMasternodeDeposit' ||
				type === 'energiMasternodeWithdraw'
			) {
				energiMasternodeCollateralChange = true;
			} else if (type === 'energiMasternodeAnnounce') {
				energiMasternodeAnnouncing = true;
			} else if (type === 'energiMasternodeDenounce') {
				energiMasternodeDenouncing = true;
			} else if (
				type === 'itsaSubscriptionTrial' ||
				type === 'itsaSubscriptionSubscription'
			) {
				gettingSubscriptionOrTrial = true;
			} else if (
				type === 'itsaSubscriptionBindToSubscription' ||
				type === 'itsaSubscriptionRemoveBoundSubscription'
			) {
				boundSubscription = true;
			} else if (
				type === 'itsaSubscriptionSetApprovedMultipleBoundSubscriptions' ||
				type === 'itsaSubscriptionApproveBoundSubscription' ||
				type === 'itsaSubscriptionRemoveBoundSubscriptionApproval'
			) {
				boundWalletList = true;
			} else if (type === 'itsaNftTransfer') {
				const key = `${chainId}-${nftType}-${tokenId}`;
				transferringNftOwnership[key] = true;
			} else if (
				type === 'itsaNftAddressSubscription' ||
				type === 'itsaNftAddressUnsubscription' ||
				type === 'itsaNftSubscriptionsReset' ||
				type === 'itsaNftAllAddressesSubscription'
			) {
				const key = `${chainId}-${nftType}-${nftId}`;
				nftUnlockedWallets[key] = true;
			}
		}
		if (pt.tokenimpact) {
			if (addressMatch(pt.tokenimpact, wrappedTokenAddress)) {
				coinImpact = true;
			}
			impactedTokens[pt.tokenimpact.toLowerCase()] = true;
			if (type === 'tokenapproval') {
				tokensApprovals[pt.tokenimpact.toLowerCase()] = true;
			}
		}
		if (pt.tokenimpact2) {
			if (addressMatch(pt.tokenimpact2, wrappedTokenAddress)) {
				coinImpact = true;
			}
			impactedTokens[pt.tokenimpact2.toLowerCase()] = true;
		}
	});
	newData.forEach(token => {
		token.hasPendingTransaction = !!impactedTokens[token.address];
		token.isBeingApproved = !!tokensApprovals[token.address];
	});
	newData[0].hasPendingTransaction = coinImpact;
	newData[0].hasPendingEnergiMasternodeCollateralChange =
		energiMasternodeCollateralChange;
	newData[0].isEnergiMasternodeAnnouncing = energiMasternodeAnnouncing;
	newData[0].isEnergiMasternodeDenouncing = energiMasternodeDenouncing;
	newData[0].isGoingToGetFullAccess = gettingSubscriptionOrTrial;
	newData[0].isChangingBoundSubscription = boundSubscription;
	newData[0].isChangingBoundWalletList = boundWalletList;
	newData[0].isTransferringNftOwnership = transferringNftOwnership;
	newData[0].isChangingNftUnlockedWallets = nftUnlockedWallets;
};

const useTokenListNetwork = () => {
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [chainId, setChainId] = useState();
	const [address, setAddress] = useState();
	const { sound } = useContext(soundCtx);
	const { muted } = useContext(togglemuteCtx);
	const { minRetainedWei } = useContext(gaspriceCtx);
	const { customTokens } = useContext(customtokensCtx);
	const pendingtransactions = useContext(pendingtransactionsCtx);
	const { blockheight } = useContext(blockheightCtx);
	const { listinfo } = useContext(listinfoCtx);
	const { allTokens } = useContext(maxtokensCtx);
	const dexTokensCallbackRef = useRef();
	const coinInfoCallbackRef = useRef();
	const tokensCallbackRef = useRef();
	const chainAddressKeyRef = useRef();
	const blocksoundRef = useRef();
	const minRetainedWeiRef = useRef(BN_ZERO);
	const soundRef = useRef(sound);
	const dataRef = useRef(data);
	const customTokensRef = useRef(customTokens);
	const mutedRef = useRef(muted);
	const blockheightRef = useRef(blockheight);
	const pendingtransactionsRef = useRef(pendingtransactions);
	const running = useRef(false);

	const onUpdate = async () => {
		if (!running.current) {
			return;
		}
		const newData = dexTokens.tokens.map(transformTokenData);
		const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId];
		const useDexPrice = listinfo === 'dextokenprice';
		const getDexPrices =
			chainId && (useDexPrice || ETHEREUM_POW_CHAINIDS[chainId]);
		let currencyprices;
		// eslint-disable-next-line no-restricted-syntax
		if (getDexPrices || customTokensRef.current.length > 0) {
			const responseCurrencyprices = await axios.post(
				`${API_URL}/currencyprices`,
			);

			if (
				responseCurrencyprices.status === 200 &&
				responseCurrencyprices.data?.success
			) {
				currencyprices = responseCurrencyprices.data.data;
				if (customTokensRef.current.length > 0) {
					// eslint-disable-next-line no-restricted-syntax
					for (const ct of customTokensRef.current) {
						const dextoken = newData.find(dt =>
							addressMatch(dt.address, ct.address),
						);
						if (!dextoken) {
							const newItem = await transformCustomTokenData(
								chainId,
								address,
								currencyprices,
								ct,
							);
							newItem.sortsBefore = () => {};
							newData.push(newItem);
						}
					}
				}
			}
		}

		newData.sort(sortFn.bind(null, wrappedTokenAddress));
		if (newData[0]) {
			newData[0].coinBalance = coinInfo.balance;
			newData[0].isMasternode = coinInfo.isMasternode;
			newData[0].masternodeStatus = coinInfo.masternodeStatus;
			newData[0].masternodeVersionRequired = coinInfo.masternodeVersionRequired;
			newData[0].masternodeInfo = coinInfo.masternodeInfo;
			newData[0].collateral = coinInfo.collateral;
			newData[0].masternodeNextPayoutBlock = coinInfo.masternodeNextPayoutBlock;

			// now match all tokens (tokens with tokenbalance):
			tokens.tokens.forEach(t => {
				const matchedToken = newData.find(dexT =>
					addressMatch(dexT.address, t.address),
				);
				if (matchedToken) {
					matchedToken.balance = toBN(t.balance);
				}
			});

			// now check if the coin is below its critical amount:
			newData[0].belowCriticalBalance = spendCoinWei => {
				if (!spendCoinWei) {
					spendCoinWei = BN_ZERO;
				}
				return newData[0].coinBalance
					.sub(spendCoinWei)
					.lt(minRetainedWeiRef.current);
			};
		}
		const balanceChanged = !isEqual(
			withoutUSD(dataRef.current),
			withoutUSD(newData),
		);
		if (getDexPrices) {
			await combineDexPrices(
				chainId,
				newData,
				currencyprices,
				blockheightRef.current,
			);
		}
		combinePendingTransactions(
			chainId,
			newData,
			pendingtransactionsRef.current,
			wrappedTokenAddress,
		);
		setData(newData);

		const loading = dexTokens.loading || coinInfo.loading || tokens.loading;
		setIsLoading(dexTokens.loading || coinInfo.loading || tokens.loading);

		const error = dexTokens.error || coinInfo.error || tokens.error;
		setError(error);
		if (balanceChanged) {
			if (!loading && !error) {
				// do not play sound when it is the first time or when it is loading or errored
				const chainAddressKey = `${chainId}|${address}`;
				const firstBalanceCreation =
					chainAddressKey !== chainAddressKeyRef.current;
				chainAddressKeyRef.current = chainAddressKey;
				if (!firstBalanceCreation && !mutedRef.current) {
					const blockheight = await getBlockHeight(chainId);
					// prevent making multiple sounds on the same block, due to multiple callbacks
					if (blocksoundRef.current !== blockheight) {
						blocksoundRef.current = blockheight;
						playSound(soundRef.current);
					}
				}
			}
		}
	};

	const changeMaxTokens = newAllTokens => {
		let limit;
		if (!newAllTokens) {
			limit = LIMIT_TOKENS;
		}
		dexTokens.updateLimit(limit);
	};

	const stopNetworkActivity = hardStop => {
		if (coinInfoCallbackRef.current) {
			coinInfo.stop(coinInfoCallbackRef.current);
		}
		if (tokensCallbackRef.current) {
			tokens.stop(tokensCallbackRef.current);
		}
		if (dexTokensCallbackRef.current) {
			tokens.stop(dexTokensCallbackRef.current);
		}
		coinInfoCallbackRef.current = null;
		tokensCallbackRef.current = null;
		dexTokensCallbackRef.current = null;
		if (hardStop) {
			setData([]);
			setChainId(null);
			setAddress(null);
		}
	};

	const stop = () => {
		running.current = false;
		stopNetworkActivity(true);
	};

	const start = (newChainId, newAddress) => {
		running.current = true;
		setChainId(newChainId);
		setAddress(newAddress);
	};

	const startNetworkActivity = async () => {
		if (!address || !chainId) {
			stop();
			return;
		}
		if (dexTokensCallbackRef.current) {
			dexTokens.stop(dexTokensCallbackRef.current);
		}
		dexTokensCallbackRef.current = onUpdate;
		let limit;
		if (!allTokens) {
			limit = LIMIT_TOKENS;
		}
		dexTokens.start(chainId, dexTokensCallbackRef.current, limit);
		if (coinInfoCallbackRef.current) {
			coinInfo.stop(coinInfoCallbackRef.current);
		}
		if (tokensCallbackRef.current) {
			tokens.stop(tokensCallbackRef.current);
		}
		coinInfoCallbackRef.current = onUpdate;
		tokensCallbackRef.current = onUpdate;
		await coinInfo.start(chainId, address, coinInfoCallbackRef.current);
		await dexTokens.isReady();
		tokens.start(chainId, address, tokensCallbackRef.current);
	};

	useEffect(() => {
		blockheightRef.current = blockheight;
	}, [blockheight]);

	useEffect(() => {
		minRetainedWeiRef.current = minRetainedWei;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minRetainedWei]);

	useEffect(() => {
		soundRef.current = sound;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sound]);

	useEffect(() => {
		dataRef.current = data;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		customTokensRef.current = customTokens;
		onUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customTokens]);

	useEffect(() => {
		onUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listinfo]);

	useEffect(() => {
		mutedRef.current = muted;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [muted]);

	useEffect(() => {
		stopNetworkActivity();
		if (chainId && address) {
			startNetworkActivity();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address]);

	useEffect(() => {
		changeMaxTokens(allTokens);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allTokens]);

	/* Temporately disabling: we will need to reactivate this code
    useEffect(() => {
		const newData = cloneDeep(data);
		const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId];
		pendingtransactionsRef.current = pendingtransactions;
		combinePendingTransactions(
			chainId,
			newData,
			pendingtransactions,
			wrappedTokenAddress,
		);
		setData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pendingtransactions]);
    */

	const setAddressFn = newAddress => {
		setAddress(newAddress);
	};

	const setChainIdFn = newChainId => {
		setChainId(newChainId);
	};

	return {
		setAddress: setAddressFn,
		setChainId: setChainIdFn,
		start,
		stop,
		data: !isLoading && data && address ? data : [],
		error,
		isLoading,
	};
};

export default useTokenListNetwork;
