import React from 'react';
import PropTypes from 'prop-types';
import PrimaryLayout from 'components/layout/PrimaryLayout';
import SecondaryLayout from 'components/layout/SecondaryLayout';

const IndexLayout = ({ children, ...props }) => {
	const { pageContext } = props;
	const primaryLayout = pageContext?.layout === 'primary';
	const Layout = primaryLayout ? PrimaryLayout : SecondaryLayout;

	return <Layout {...props}>{children}</Layout>;
};

IndexLayout.defaultProps = {
	pageContext: {
		layout: 'primary',
	},
};

IndexLayout.propTypes = {
	pageContext: PropTypes.shape({
		layout: PropTypes.string,
	}),
	children: PropTypes.node.isRequired,
};

export default IndexLayout;
