/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getLatestSystemMessage = async () => {
	let latestSystemMessage = '';
	try {
		const response = await axios.post(`${API_URL}/systemmessages`);
		if (response.status === 200 && response.data?.success) {
			latestSystemMessage = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		latestSystemMessage = '';
	}
	return latestSystemMessage;
};

export default getLatestSystemMessage;
