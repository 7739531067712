import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import 'styles/main.css';

// Need to suppress Brave from messaging "Crypto Wallet":
import { noCryptoWalletMsg } from '@itsa.io/web3utils';

// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = ({ location }) => {
	if (process.env.NODE_ENV !== 'production') {
		return null;
	}
	const pagePath = location
		? location.pathname + location.search + location.hash
		: undefined;
	setTimeout(() => {
		if (typeof window.gtag === 'function') {
			window.gtag('event', 'page_view', { page_path: pagePath });
		}
	}, 100);
	return true;
};

noCryptoWalletMsg(); // no Brave messages, yeah!
