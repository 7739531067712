import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Button,
	Link,
	IconButton,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@itsa.io/ui';
import { useIntl, cryptowalletCtx, formatBN, toBN } from '@itsa.io/web3utils';
import subscriptionCtx from 'context/subscription';
import {
	subscribe,
	trial,
	enoughGasForTrial,
	enoughFundsToSubscribe,
} from 'utils/smartcontracts/itsa-subscription';
import darkmodeCtx from 'context/darkmode';
import currencyCtx from 'context/currency';
import maincoinpricesCtx from 'context/maincoinprices';
import gaspriceCtx from 'context/gasprice';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import tokenListCtx from 'context/tokenlist';
import securedsendtxCtx from 'context/securedsendtx';
import useAlert from 'hooks/useAlert';
import useConfirm from 'hooks/useConfirm';
import GasSlider from 'components/common/GasSlider';
import {
	// CheckCircle as CheckCircleIcon,
	DonutLarge as DonutLargeIcon,
	// Brightness4 as Brightness4Icon,
	// Notifications as NotificationsIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
	SwapHorizontalCircle as SwapHorizontalCircleIcon,
	// MonetizationOn as MonetizationOnIcon,
	VpnKey as VpnKeyIcon,
	// TabletAndroid as TabletAndroidIcon,
	// Lock as LockIcon,
	// Public as PublicIcon,
	Close as CloseIcon,
} from '@material-ui/icons';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';
import Opensea from 'assets/images/opensea.svg';
import useStyles from 'styles/pages/PromoPage';
import {
	NETWORK_NAMES,
	BN_ZERO,
	// ITSA_SUBSCRIPTION_CHAINS,
	ITSA_SUBSCRIPTION_SC_ADDRESSES,
	CHAIN_SYMBOLS,
	SUBSCRIPTIONS_ENABLED,
	TRIALS_ENABLED,
	GAS_PERCENTAGES,
	SILVER_NFT_URL,
	GOLD_NFT_URL,
	PLATINUM_NFT_URL,
} from 'config/constants';

const DAYS = {
	30: '30',
	90: '90',
	365: '365',
	9999: '9999',
};

const getInitialNetwork = chainId => {
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId]) {
		return chainId;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[1]) {
		return 1;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[56]) {
		return 56;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[39797]) {
		return 39797;
	}
	return 1;
};

const PromoPage = ({ initialChainId, handleClose }) => {
	const { t } = useIntl();
	const { chainId, address, hardwareWallet, switchToNetwork } =
		useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const subscription = useContext(subscriptionCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { darkmode } = useContext(darkmodeCtx);
	const { currency } = useContext(currencyCtx);
	const mainCoinPrices = useContext(maincoinpricesCtx);
	const { data: tokenList } = useContext(tokenListCtx);
	const classes = useStyles();
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const alert = useAlert();
	const confirm = useConfirm();
	const isMounted = useRef(false);
	// const [checked, setCheck] = useState(false);
	const [selectedNetwork, setSelectNetwork] = useState(
		getInitialNetwork(initialChainId),
	);
	const [selectedMonthlyValue, setSelectedMonthlyValue] = useState('30');
	const { chainSubscriptions, canGetTrial, canGetSubscription } = subscription;
	const count = chainSubscriptions
		? chainSubscriptions[39797]?.maxChildSubscriptions
		: 0;
	const balance = tokenList[0] ? tokenList[0].coinBalance : BN_ZERO;

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSelectNetwork(getInitialNetwork(initialChainId));
	}, [initialChainId]);

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const getPriceContent = () => {
		let dailyFee;
		let fee;
		let feeFiat;
		let feeFiatCurrency;
		let feeFiatClose;
		if (subscription?.chainSubscriptions) {
			const chainSubscription = chainSubscriptions[selectedNetwork];
			if (chainSubscription) {
				dailyFee = chainSubscription.dailyFee;
			}
		}
		if (dailyFee) {
			fee = `${formatBN(dailyFee.mul(toBN(selectedMonthlyValue)), {
				assetDigits: 18,
				minDigits: 2,
			})} ${CHAIN_SYMBOLS[selectedNetwork]}`;
			const coinPrices = mainCoinPrices[selectedNetwork];

			if (coinPrices) {
				const amount = parseFloat(
					formatBN(dailyFee.mul(toBN(selectedMonthlyValue)), {
						assetDigits: 18,
						minDigits: 2,
					}).replaceAll(',', '.'),
				);
				const priceCents = Math.round(
					100 * amount * coinPrices[currency.toLowerCase()],
				).toString();
				const price = formatBN(toBN(priceCents), {
					assetDigits: 2,
					minDigits: 2,
					decimals: 2,
				});
				feeFiat = `(${t('page.promopage.about')} ${price}`;
				feeFiatCurrency = currency;
				feeFiatClose = ')';
			}
		}
		return (
			<div className={classes.priceWrap}>
				<span className={classes.price}>{fee}</span>{' '}
				<span className={classes.pricefiat}>
					{feeFiat}
					<span className={classes.currency}>{feeFiatCurrency}</span>
					{feeFiatClose}
				</span>
			</div>
		);
	};

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	const header = <div className={classes.walletLogoName}>{logo}</div>;

	const handleSubscribe = async (nrOfDays = '30') => {
		let tx;
		let revertToChainId;

		try {
			// convert a string to a number
			nrOfDays = parseInt(nrOfDays, 10);
			const enoughFunds = await enoughFundsToSubscribe(
				chainId,
				nrOfDays,
				balance,
			);
			if (!enoughFunds) {
				alert(
					t('page.subscriptionpage.not_enough_funds_to_subscribe'),
					'error',
				);
				return;
			}
			setSendButtonEnabled(false);
			if (selectedNetwork !== chainId) {
				if (hardwareWallet) {
					const confirmed = await confirm(
						`${t('page.promopage.switch_network')} ${
							NETWORK_NAMES[selectedNetwork]
						}?`,
						'info',
					);
					if (!confirmed) {
						if (isMounted.current) {
							setSendButtonEnabled(true);
						}
						return;
					}
					revertToChainId = chainId;
				}
				await switchToNetwork(selectedNetwork);
			}
			// "networkswitch has got its own catch: we don;t want a message when it gets cancelled"
			// the trial will have a catch:
			try {
				// note that whenever switchToNetwork is called, chainId is not yet updated to selectedNetwork: so we need to use selectedNetwork
				tx = await subscribe(
					selectedNetwork,
					nrOfDays,
					address,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
				handleClose();
			} catch (err) {
				let newError = err;
				if (typeof newError.message === 'string') {
					// this is weird: err.message can be a message (String) that looks like this:
					// "JSON-RPC error.... { code: xxx, message: ''}"
					// so we need to extract the error and errorcode manually:
					const indexCurlyBracket = newError.message.indexOf('{');
					if (indexCurlyBracket !== -1) {
						newError = newError.message.substring(indexCurlyBracket);
						try {
							newError = JSON.parse(newError);
						} catch (err2) {
							newError = err; // revert
							// eslint-disable-next-line no-console
							console.error(err2);
							alert(err2.message, 'error');
						}
					}
				}
				if (isMounted.current) {
					setSendButtonEnabled(true);
				}
				alert(err.message, 'error');
			}
		} catch (err) {
			if (isMounted.current) {
				setSendButtonEnabled(true);
			}
		}
		if (revertToChainId) {
			try {
				if (isMounted.current) {
					await switchToNetwork(revertToChainId);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}
		// eslint-disable-next-line consistent-return
		return tx;
	};

	// handleStarTrial is calling the SmartContract and activates a trial for this wallet
	// NOTE: first check if the user on this address can get a Trial: `subscriptions.canGetTrial`
	// eslint-disable-next-line no-unused-vars
	const handleStartTrial = async () => {
		let tx;
		let revertToChainId;

		if (!enoughGasForTrial(balance)) {
			alert(t('page.subscriptionpage.not_enough_funds_for_trial'), 'error');
			return;
		}

		setSendButtonEnabled(false);
		try {
			if (selectedNetwork !== chainId) {
				if (hardwareWallet) {
					const confirmed = await confirm(
						`${t('page.promopage.switch_network')} ${
							NETWORK_NAMES[selectedNetwork]
						}?`,
						'info',
					);
					if (!confirmed) {
						setSendButtonEnabled(true);
						return;
					}
					revertToChainId = chainId;
				}
				await switchToNetwork(selectedNetwork);
			}
			// "networkswitch has got its own catch: we don;t want a message when it gets cancelled"
			// the trial will have a catch:
			try {
				// note that whenever switchToNetwork is called, chainId is not yet updated to selectedNetwork: so we need to use selectedNetwork
				tx = await trial(
					selectedNetwork,
					address,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
				handleClose();
			} catch (err) {
				let newError = err;
				if (typeof newError.message === 'string') {
					// this is weird: err.message can be a message (String) that looks like this:
					// "JSON-RPC error.... { code: xxx, message: ''}"
					// so we need to extract the error and errorcode manually:
					const indexCurlyBracket = newError.message.indexOf('{');
					if (indexCurlyBracket !== -1) {
						newError = newError.message.substring(indexCurlyBracket);
						try {
							newError = JSON.parse(newError);
						} catch (err2) {
							newError = err; // revert
							// eslint-disable-next-line no-console
							console.error(err2);
							alert(err2.message, 'error');
						}
					}
				}
				setSendButtonEnabled(true);
				alert(err.message, 'error');
			}
		} catch (err) {
			setSendButtonEnabled(true);
		}
		if (revertToChainId) {
			try {
				await switchToNetwork(revertToChainId);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}
		// eslint-disable-next-line consistent-return
		return tx;
	};

	// const handleSwitchChange = event => {
	// 	setCheck(event.target.checked);
	// };

	const handleRadioChange = event => {
		setSelectedMonthlyValue(event.target.value);
	};

	// const handleNetwork = id => {
	// 	setSelectNetwork(id);
	// };

	// if (checked) {
	// 	priceContent = (
	// 		<div className={classes.priceWrap}>
	// 			<span className={classes.price}>
	// 				{t('page.promopage.lifetimen_price')}
	// 			</span>{' '}
	// 			<span className={classes.period}>
	// 				{t('page.promopage.lifetime_period')}
	// 			</span>
	// 			<div className={classes.noFees}>
	// 				{t('page.promopage.lifetime_nofess')}
	// 			</div>
	// 		</div>
	// 	);
	// }

	// const networkIconItems = Object.keys(ITSA_SUBSCRIPTION_CHAINS).map(
	// 	chainid => {
	// 		const name = NETWORK_NAMES[chainid];
	// 		const icon = NETWORK_ICONS[chainid];
	// 		const value = parseInt(chainid, 10);

	// 		return (
	// 			<IconButton
	// 				className={clsx(classes.buttonIcon, {
	// 					[classes.buttonIconActive]: selectedNetwork === value,
	// 				})}
	// 				disabled={
	// 					!sendButtonEnabled || !ITSA_SUBSCRIPTION_SC_ADDRESSES[chainid]
	// 				}
	// 				onClick={() => handleNetwork(value)}
	// 				key={chainid}
	// 			>
	// 				<TokenImg
	// 					className={classes.icon}
	// 					alt={name}
	// 					width="40"
	// 					height="40"
	// 					src={icon}
	// 				>
	// 					<TokenImg className={classes.iconImgUnknown} alt={name} src="">
	// 						?
	// 					</TokenImg>
	// 				</TokenImg>
	// 			</IconButton>
	// 		);
	// 	},
	// );

	const chainSubscription = chainSubscriptions[chainId];
	const trialButtonText = `${t('page.promopage.start')} ${
		chainSubscriptions ? chainSubscription?.trialPeriod || '' : ''
	}-${t('page.promopage.day')} ${t('page.promopage.free_trial')}`;

	const trialButton = (
		<Button
			className={clsx(classes.percentageButton)}
			disabled={!sendButtonEnabled || !canGetTrial || !TRIALS_ENABLED}
			onClick={handleStartTrial}
			size="large"
			variant="outlined"
			color="primary"
		>
			{trialButtonText}
		</Button>
	);

	const unlimitedButton = (
		<Button
			className={clsx(classes.percentageButton, classes.memberButton)}
			disabled={
				!sendButtonEnabled || !canGetSubscription || !SUBSCRIPTIONS_ENABLED
			}
			onClick={() => handleSubscribe(selectedMonthlyValue)}
			size="large"
			variant="outlined"
			color="primary"
		>
			{t('page.promopage.unlimited_button')}
		</Button>
	);

	const closeIcon = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			disabled={!sendButtonEnabled}
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	const titleContent = (
		<h4 className={classes.title}>
			{t('page.promopage.title_1')}{' '}
			<span className={classes.underline}>{t('page.promopage.title_2')}</span>
		</h4>
	);

	// const textContent = (
	// 	<p className={classes.promoText}>{t('page.promopage.promo_text')}</p>
	// );

	const radioButtonsContent = (
		<FormControl className={classes.radioControl} disabled={!sendButtonEnabled}>
			<RadioGroup row name="row-radio-buttons-group">
				<FormControlLabel
					value={DAYS[30]}
					control={
						<Radio
							color="default"
							checked={selectedMonthlyValue === DAYS[30]}
							onChange={handleRadioChange}
						/>
					}
					label={t('page.promopage.radio_label_30_days')}
				/>
				<FormControlLabel
					value={DAYS[90]}
					control={
						<Radio
							color="default"
							checked={selectedMonthlyValue === DAYS[90]}
							onChange={handleRadioChange}
						/>
					}
					label={t('page.promopage.radio_label_90_days')}
				/>
				<FormControlLabel
					value={DAYS[365]}
					control={
						<Radio
							color="default"
							checked={selectedMonthlyValue === DAYS[365]}
							onChange={handleRadioChange}
						/>
					}
					label={t('page.promopage.radio_label_365_days')}
				/>
				<FormControlLabel
					value={DAYS[9999]}
					control={
						<Radio
							color="default"
							checked={selectedMonthlyValue === DAYS[9999]}
							onChange={handleRadioChange}
						/>
					}
					label="Lifetime"
				/>
			</RadioGroup>
		</FormControl>
	);

	// const switchButtonContent = (
	// 	<div className={classes.switchWrap}>
	// 		<div className={classes.switchType}>Monthly</div>
	// 		<Switch
	// 			className={classes.switch}
	// 			name="plan"
	// 			checked={checked}
	// 			onChange={handleSwitchChange}
	// 			inputProps={{ 'aria-label': 'Switch plan' }}
	// 		/>
	// 		<div className={classes.switchType}>{t('page.promopage.lifetime')}</div>
	// 	</div>
	// );

	const featuresContent = (
		<div className={classes.promoFeatures}>
			{/* <h4 className={clsx(classes.promoFeaturesTitle)}>
				{t('page.promopage.promo_main_feature_title')}
			</h4> */}
			<p className={classes.promoFeatureText}>
				{t('page.promopage.promo_main_feature_text')}
			</p>
			<ul className={classes.promoFeatureList}>
				<li className={classes.promoFeatureItem}>
					<div className={classes.iconWrap}>
						<SwapHorizontalCircleIcon className={classes.checkCircleIcon} />
					</div>
					<div>
						<h5 className={classes.promoFeatureTitle}>
							{t('page.promopage.promo_feature_title1')}
						</h5>
						<p className={classes.promoFeaturetText}>
							{t('page.promopage.promo_feature_text1')}
						</p>
					</div>
				</li>
				<li className={classes.promoFeatureItem}>
					<div className={classes.iconWrap}>
						<VpnKeyIcon className={classes.checkCircleIcon} />
					</div>
					<div>
						<h5 className={classes.promoFeatureTitle}>
							{t('page.promopage.promo_feature_title2')}
						</h5>
						<p className={classes.promoFeaturetText}>
							{t('page.promopage.promo_feature_text2')}
						</p>
					</div>
				</li>
				<li className={classes.promoFeatureItem}>
					<div className={classes.iconWrap}>
						<AccountBalanceWalletIcon className={classes.checkCircleIcon} />
					</div>
					<div>
						<h5 className={classes.promoFeatureTitle}>
							{t('page.promopage.promo_feature_title3', { values: { count } })}
						</h5>
						<p className={classes.promoFeaturetText}>
							{t('page.promopage.promo_feature_text3', { values: { count } })}
						</p>
					</div>
				</li>
				<li className={classes.promoFeatureItem}>
					<div className={classes.iconWrap}>
						<DonutLargeIcon className={classes.checkCircleIcon} />
					</div>
					<div>
						<h5 className={classes.promoFeatureTitle}>
							{t('page.promopage.promo_feature_title4')}
						</h5>
						<p className={classes.promoFeaturetText}>
							{t('page.promopage.promo_feature_text4')}
						</p>
					</div>
				</li>
			</ul>
		</div>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider
				className={classes.gaspriceSlider}
				disabled={!sendButtonEnabled}
				value={gaspriceIndex}
				onChange={changeGaspriceIndex}
			/>
		);
	}

	const openSeaContent = (
		<div>
			<Opensea className={classes.openseaIcon} />
			<p className={classes.nftTitle}>Get an NFT</p>
		</div>
	);

	let buttonContent = (
		<div className={classes.buttonVerticalGroup}>
			{trialButton}
			{unlimitedButton}
		</div>
	);

	let priceContent = <div className={classes.price}>{getPriceContent()}</div>;
	if (selectedMonthlyValue === DAYS[9999]) {
		buttonContent = (
			<div className={classes.buttonVerticalGroup}>
				<Button
					component={Link}
					className={classes.buttonLink}
					variant="outlined"
					color="primary"
					size="large"
					href={SILVER_NFT_URL}
					target="_blank"
					rel="noreferrer noopener"
				>
					Silver
				</Button>
				<Button
					component={Link}
					className={classes.buttonLink}
					variant="outlined"
					color="primary"
					size="large"
					href={GOLD_NFT_URL}
					target="_blank"
					rel="noreferrer noopener"
				>
					Gold
				</Button>
				<Button
					component={Link}
					className={classes.buttonLink}
					variant="outlined"
					color="primary"
					size="large"
					href={PLATINUM_NFT_URL}
					target="_blank"
					rel="noreferrer noopener"
				>
					Platinum
				</Button>
			</div>
		);
		priceContent = <div className={classes.nftWrapper}>{openSeaContent}</div>;
	}

	return (
		<div className={clsx(classes.root)}>
			{header}
			{closeIcon}
			{titleContent}
			{/* {textContent} */}
			{/* <div>{networkIconItems}</div> */}
			{radioButtonsContent}
			{/* {switchButtonContent} */}
			{priceContent}
			{buttonContent}
			{gaspriceSlider}
			{featuresContent}
		</div>
	);
};

PromoPage.defaultProps = {
	initialChainId: null,
	handleClose: null,
};

PromoPage.propTypes = {
	initialChainId: PropTypes.number,
	handleClose: PropTypes.func,
};

export default PromoPage;
