import { createWatcher } from 'utils/multicall';
import { cloneDeep } from 'lodash';
import addressMatch from 'utils/address-match';
import arrayToBatched from 'utils/array-to-batched';
import { WRAPPED_ADDRESSES, MULTICALL_ADDRESSES } from 'config/constants';
import { getWeb3ws } from 'utils/get-web3';

const WATCHERS = [];

const MAX_MULTICALL_BATCH_SIZE = 125;

const handleUpdate = async (
	chainId,
	wrappedAddress,
	tokenlist,
	cb,
	updates,
) => {
	try {
		// when the code comes here, there is an update on one or more of the watched balances
		let err;
		let hasChanged = false;
		const updateKeys = Object.keys(updates);
		updateKeys.forEach(updateKey => {
			const tokenAddress = updateKey;
			const balance = updates[updateKey];
			const matchedToken = tokenlist.find(t =>
				addressMatch(t.address, tokenAddress),
			);
			if (matchedToken && matchedToken.balance !== balance.toString()) {
				matchedToken.balance = balance.toString();
				hasChanged = true;
			}
		});
		if (err) {
			cb(err, null, chainId);
		} else if (hasChanged) {
			cb(
				null,
				cloneDeep(
					tokenlist.filter(
						token =>
							addressMatch(token.address, wrappedAddress) ||
							token.balance.toString() !== '0',
					),
				),
				chainId,
			);
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
};

const stopWatcher = () => {
	WATCHERS.forEach(watcher => {
		// Stop the watcher
		watcher.cancel();
	});
	WATCHERS.length = 0;
};

const startWatcher = async (chainId, address, allTokenlist, cb) => {
	stopWatcher();
	// console.info(" Multicall", props, typeof cb);
	if (typeof cb === 'function') {
		const web3wss = getWeb3ws(chainId);
		if (allTokenlist.length === 0) {
			// eslint-disable-next-line no-console
			console.warn('Multicall has no tokens');
		}
		const tokenlist = cloneDeep(allTokenlist);
		const list = [];

		allTokenlist.forEach(token => {
			const item = {
				target: token.address,
				call: ['balanceOf(address)(uint256)', address],
				returns: [[token.address]],
			};
			list.push(item);
		});

		const listFragments = arrayToBatched(list, MAX_MULTICALL_BATCH_SIZE);

		listFragments.forEach(listFragment => {
			WATCHERS.push(
				createWatcher(
					MULTICALL_ADDRESSES[chainId],
					listFragment,
					web3wss,
					handleUpdate.bind(
						null,
						chainId,
						WRAPPED_ADDRESSES[chainId],
						tokenlist,
						cb,
					),
				),
			);
		});
	}
};

export default {
	stopWatcher,
	startWatcher,
};
