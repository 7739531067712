import React, { useContext } from 'react';
import darkmodeCtx from 'context/darkmode';
import AuthenticationPage from 'components/pages/AuthenticationPage';
import useStyles from 'styles/components/common/WalletConnect';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';

const WalletConnect = props => {
	const classes = useStyles();
	const { darkmode } = useContext(darkmodeCtx);

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	return (
		<div className={classes.root}>
			<div className={classes.walletLogoName}>{logo}</div>
			<AuthenticationPage {...props} />
		</div>
	);
};
export default WalletConnect;
