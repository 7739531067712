/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { ENVIRONMENT, API_URL } from 'config/constants';

const getLatestVersion = async () => {
	let latestVersion;
	try {
		const uri =
			ENVIRONMENT === 'production' ? 'walletversion' : 'wallettestversion';
		const response = await axios.post(`${API_URL}/${uri}`);
		if (response.status === 200 && response.data?.success) {
			latestVersion = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		latestVersion = null;
	}
	return latestVersion;
};

export default getLatestVersion;
