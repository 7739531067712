import { useEffect, useState } from 'react';
import createStore from 'ctx-provider';
import mainCoinPrices from 'api/mainCoinPrices';

const useMainCoinPrices = () => {
	const [coinPrices, setCoinPrices] = useState({});

	const onPriceChange = prices => {
		setCoinPrices(prices);
	};

	useEffect(() => {
		mainCoinPrices.subscribe(onPriceChange);
	}, []);

	return coinPrices;
};

const store = createStore(useMainCoinPrices);

export const { Provider } = store;
export default store.ctx;
