import getSDK from 'utils/get-sdk';
import { getToken } from 'utils/smartcontracts/token';
import { getPairContract, getFactoryContract } from 'utils/get-contract';
import { IS_ENERGI_CHAINID } from 'config/constants';
import getBlockHeight from 'utils/get-blockheight';
import addressSortsBefore from 'utils/address-sorts-before';
import * as addressMatch from 'utils/address-match';

const reservesBlockCache = {};
const reservesCache = {};
const pairAddresses = {};

export const getPairAddressPromise = async (
	chainId,
	tokenAddress0,
	tokenAddress1,
) => {
	const factoryContract = getFactoryContract(chainId);
	return factoryContract.methods.getPair(tokenAddress0, tokenAddress1).call();
};

export const getPairAddress = async (chainId, tokenAddress0, tokenAddress1) => {
	let address0;
	let address1;

	if (addressSortsBefore(tokenAddress0, tokenAddress1)) {
		address0 = tokenAddress0;
		address1 = tokenAddress1;
	} else {
		address0 = tokenAddress1;
		address1 = tokenAddress0;
	}

	const key = `${chainId}|${address0}|${address1}`;
	if (!pairAddresses[key]) {
		pairAddresses[key] = getPairAddressPromise(chainId, address0, address1);
	}
	return pairAddresses[key];
};

export const getReservesPromise = async (chainId, token0, token1) => {
	let reserve0;
	let reserve1;
	let decimalAdjustedReserve0;
	let decimalAdjustedReserve1;
	try {
		const tokenAddress0 = token0.address;
		const tokenAddress1 = token1.address;

		const pairAddress = await getPairAddress(
			chainId,
			tokenAddress0,
			tokenAddress1,
		);
		const pairContract = getPairContract(chainId, pairAddress);
		const reserves = await pairContract.methods.getReserves().call();
		const sortsBefore = token0.sortsBefore(token1);

		if (sortsBefore) {
			reserve0 = reserves['0'];
			reserve1 = reserves['1'];
		} else {
			reserve0 = reserves['1'];
			reserve1 = reserves['0'];
		}

		// adjustForDecimals, start with setting them equal to reserve
		decimalAdjustedReserve0 = reserve0;
		decimalAdjustedReserve1 = reserve1;
		const difDecimalsToken0 = token0.decimals - 18;
		const difDecimalsToken1 = token1.decimals - 18;

		if (difDecimalsToken0 < 0) {
			decimalAdjustedReserve0 = reserve0.padEnd(
				reserve0.length - difDecimalsToken0,
				'0',
			);
		} else if (difDecimalsToken0 > 0) {
			decimalAdjustedReserve0 = reserve0.substring(
				0,
				reserve0.length - difDecimalsToken0,
			);
		}

		if (difDecimalsToken1 < 0) {
			decimalAdjustedReserve1 = reserve1.padEnd(
				reserve1.length - difDecimalsToken1,
				'0',
			);
		} else if (difDecimalsToken1 > 0) {
			decimalAdjustedReserve1 = reserve1.substring(
				0,
				reserve1.length - difDecimalsToken1,
			);
		}
	} catch (err) {
		reserve0 = '0';
		reserve1 = '0';
		decimalAdjustedReserve0 = '0';
		decimalAdjustedReserve1 = '0';
	}

	const reserves = {};
	reserves[token0.address] = {
		reserve: reserve0,
		decimalAdjustedReserve: decimalAdjustedReserve0,
	};
	reserves[token1.address] = {
		reserve: reserve1,
		decimalAdjustedReserve: decimalAdjustedReserve1,
	};

	return reserves;
};

export const getReserves = async (
	chainId,
	tokenOrTokenInstance0,
	tokenOrTokenInstance1,
	adjustForDecimals,
	currentBlockHeight, // when set, it can used cached reserves for this particular block
) => {
	if (!tokenOrTokenInstance0 || !tokenOrTokenInstance1) {
		return ['0', '0'];
	}
	let key;
	const token0 = !tokenOrTokenInstance0.chainId // not a Token-instance from sdk
		? getToken(chainId, tokenOrTokenInstance0)
		: tokenOrTokenInstance0;
	const token1 = !tokenOrTokenInstance1.chainId // not a Token-class from sdk
		? getToken(chainId, tokenOrTokenInstance1)
		: tokenOrTokenInstance1;
	const tokenAddress0 = token0.address;
	const tokenAddress1 = token1.address;

	if (addressMatch(tokenAddress0, tokenAddress1)) {
		return ['0', '0'];
	}

	if (!currentBlockHeight) {
		currentBlockHeight = await getBlockHeight(chainId);
	}

	const sortsBefore = token0.sortsBefore(token1);
	if (sortsBefore) {
		key = `${tokenAddress0}|${tokenAddress1}`;
	} else {
		key = `${tokenAddress1}|${tokenAddress0}`;
	}

	if (
		!reservesBlockCache[key] ||
		reservesBlockCache[key] !== currentBlockHeight
	) {
		delete reservesCache[key];
		reservesBlockCache[key] = currentBlockHeight;
	}

	if (!reservesCache[key]) {
		reservesCache[key] = getReservesPromise(chainId, token0, token1, key);
	}

	return reservesCache[key].then(reservesItem => {
		const reserves0 = reservesItem[tokenAddress0];
		const reserves1 = reservesItem[tokenAddress1];
		const reserves = adjustForDecimals
			? [reserves0.decimalAdjustedReserve, reserves1.decimalAdjustedReserve]
			: [reserves0.reserve, reserves1.reserve];
		return reserves;
	});
};

export const xgetReserves = async (
	chainId,
	tokenOrTokenInstance0,
	tokenOrTokenInstance1,
	adjustForDecimals,
	currentBlockHeight, // when set, it can used cached reserves for this particular block
) => {
	let reserve0;
	let reserve1;

	try {
		const token0 = !tokenOrTokenInstance0.chainId // not a Token-instance from sdk
			? getToken(chainId, tokenOrTokenInstance0)
			: tokenOrTokenInstance0;
		const token1 = !tokenOrTokenInstance1.chainId // not a Token-class from sdk
			? getToken(chainId, tokenOrTokenInstance1)
			: tokenOrTokenInstance1;
		const tokenAddress0 = token0.address;
		const tokenAddress1 = token1.address;

		if (!currentBlockHeight) {
			currentBlockHeight = await getBlockHeight(chainId);
		}

		const pairAddress = await getPairAddress(
			chainId,
			tokenAddress0,
			tokenAddress1,
		);
		const pairContract = getPairContract(chainId, pairAddress);
		const reserves = await pairContract.methods.getReserves().call();

		if (token0.sortsBefore(token1)) {
			reserve0 = reserves['0'];
			reserve1 = reserves['1'];
		} else {
			reserve0 = reserves['1'];
			reserve1 = reserves['0'];
		}
	} catch (err) {
		reserve0 = '0';
		reserve1 = '0';
		currentBlockHeight = 0;
	}
	return [reserve0, reserve1];
};

// eslint-disable-next-line import/prefer-default-export
export const getPair = async (
	chainId,
	tokenOrTokenInstance0,
	tokenOrTokenInstance1,
) => {
	let pair;
	try {
		const { Pair, TokenAmount } = getSDK(chainId);
		const token0 = getToken(chainId, tokenOrTokenInstance0);
		const token1 = getToken(chainId, tokenOrTokenInstance1);
		const pairAddress = await getPairAddress(
			chainId,
			token0.address,
			token1.address,
		);
		if (pairAddress !== '0x0000000000000000000000000000000000000000') {
			const [reserve0, reserve1] = await getReserves(chainId, token0, token1);
			let tokenAmount0;
			let tokenAmount1;

			if (token0.sortsBefore(token1)) {
				tokenAmount0 = new TokenAmount(token0, reserve0);
				tokenAmount1 = new TokenAmount(token1, reserve1);
			} else {
				tokenAmount0 = new TokenAmount(token1, reserve1);
				tokenAmount1 = new TokenAmount(token0, reserve0);
			}

			if (IS_ENERGI_CHAINID(chainId)) {
				// Energi sdk used 3 arguments when creating a new Pair
				pair = new Pair(pairAddress, tokenAmount0, tokenAmount1);
			} else {
				pair = new Pair(tokenAmount0, tokenAmount1);
			}
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
	return pair;
};
