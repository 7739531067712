import { cloneDeep } from 'lodash';
import aggregate from './aggregate';

const callbacks = [];

const weakMap = new WeakMap();

let blockHeightSubscription;

const doAggregate = async (web3wss, watcher) => {
	if (weakMap.has(watcher)) {
		// busy
		return;
	}
	weakMap.set(watcher, true);
	const data = await aggregate(
		web3wss,
		watcher.smartcontractAddress,
		cloneDeep(watcher.items),
	);
	if (watcher.active) {
		watcher.cb(data);
	}
	weakMap.delete(watcher);
};

const aggregateAll = web3wss => {
	callbacks.forEach(cb => doAggregate(web3wss, cb));
};

const trackBlockHeight = web3wss => {
	blockHeightSubscription = web3wss.eth.subscribe('newBlockHeaders', error => {
		if (!error) {
			aggregateAll(web3wss);
		}
	});
};

const unsubscribe = watcher => {
	watcher.active = false;
	const index = callbacks.indexOf(watcher);
	if (index !== -1) {
		callbacks.splice(index, 1);
		if (callbacks.length === 0 && blockHeightSubscription) {
			blockHeightSubscription.unsubscribe();
		}
	}
};

const createWatcher = (smartcontractAddress, items, web3wss, cb) => {
	if (callbacks.length === 0) {
		trackBlockHeight(web3wss);
	}
	const watcher = {
		smartcontractAddress,
		items,
		cb,
		active: true,
	};
	callbacks.push(watcher);

	// firsttime callback:
	doAggregate(web3wss, watcher);

	return {
		cancel() {
			unsubscribe(watcher);
		},
	};
};

export default createWatcher;
