import addressMatch from 'utils/address-match';

const statusses = {
	0: 'masternode.unavailable',
	1: 'masternode.inactive',
	2: 'masternode.invalid',
	3: 'masternode.offline',
	4: 'masternode.ok',
};

export const getMasternodeStatus = (
	walletAddress,
	masternodeInfo,
	masternodeList,
) => {
	if (!masternodeInfo) {
		const masternode = masternodeList.find(m =>
			addressMatch(m.owner, walletAddress),
		);
		return masternode ? 1 : 0;
	}
	let status = 1;
	if (masternodeInfo.isInvalid) {
		status = 2;
	} else if (masternodeInfo.isActive) {
		status = masternodeInfo.isAlive ? 4 : 3;
	}
	return status;
};

export const getMasternodeStatusText = masternodeStatus =>
	statusses[masternodeStatus];
