import { useState, useEffect, useRef } from 'react';
import { toBN } from '@itsa.io/web3utils';
import startMoralis from 'api/moralis-socketconnection';
import getTokenList from 'api/itsa-api-tokenlist';
import getTokenListWithBalance from 'api/itsa-api-tokenlistwithbalance';
import getMarketInfo from 'api/itsa-api-marketinfo';
import getCurrencies from 'api/itsa-api-currencyprices';
import { merge } from 'lodash';
import addressMatch from 'utils/address-match';
import addressSortsBefore from 'utils/address-sorts-before';
import getHighestGas from 'utils/get-highest-gas';
import {
	TOKEN_TEMPLATES,
	WRAPPED_ADDRESSES,
	CHAIN_SYMBOLS,
	BN_ZERO,
} from 'config/constants';

const getEmptyData = () => {
	return {
		data: [],
		error: false,
		isLoading: true,
	};
};

// eslint-disable-next-line func-names
const sortFunc = function (token1) {
	return addressSortsBefore(this.address, token1.address);
};

const sortFuncRank = (token1, token2) => {
	if (token1.isCoin && !token2.isCoin) {
		return -1;
	}
	if (!token1.isCoin && token2.isCoin) {
		return 1;
	}
	if (token1.cmcrank < token2.cmcrank) {
		return -1;
	}
	if (token1.cmcrank > token2.cmcrank) {
		return 1;
	}
	return 0;
};

const modifyData = (chainId, data, marketInfo, currencies) => {
	const currentDate = new Date();
	const timestamp = currentDate.toISOString();

	let coinIndex;
	const modifiedData = data.map((token, index) => {
		if (token.isCoin) {
			coinIndex = index;
			return {
				isCoin: true,
				coinBalance: toBN(token.balance),
				hasPendingTransaction: false,
				hasPendingEnergiMasternodeCollateralChange: false,
				isEnergiMasternodeAnnouncing: false,
				isEnergiMasternodeDenouncing: false,
				isGoingToGetFullAccess: false,
				isChangingBoundSubscription: false,
				isChangingBoundWalletList: false,
				isTransferringNftOwnership: {},
				isChangingNftUnlockedWallets: {},
				collateral: BN_ZERO,
				// eslint-disable-next-line func-names
				belowCriticalBalance(spendCoinWei, currentGasPriceBN) {
					if (!spendCoinWei) {
						spendCoinWei = BN_ZERO;
					}
					const minRetainedWei = currentGasPriceBN.mul(getHighestGas(chainId));
					return this.coinBalance.sub(spendCoinWei).lt(minRetainedWei);
				},
			};
		}
		const address = token.token_address;
		const prices = {};
		let marketcap = 0;

		// find corresponding token from tokenPrices:
		const matchingToken = marketInfo[address];
		if (matchingToken) {
			marketcap = matchingToken.usd_market_cap;
			prices.usd = matchingToken.usd;
		} else {
			prices.usd = 0;
		}
		currencies.forEach(currency => {
			prices[currency.currency.toLowerCase()] = currency.priceusd * prices.usd;
		});

		return {
			address,
			balance: toBN(token.balance),
			marketcap,
			decimals: token.decimals,
			hasPendingTransactions: false,
			isBeingApproved: false,
			image: `/tokens/${token.symbol}.svg`,
			name: token.name,
			possibleSpam: token.possible_spam,
			prices,
			sortsBefore: sortFunc,
			symbol: token.symbol,
			updated: timestamp,
			visualDecimals: 4,
		};
	});

	if (typeof coinIndex === 'number') {
		const coin = modifiedData[coinIndex];
		const wrappedTokenIndex = modifiedData.findIndex(token =>
			addressMatch(token.address, WRAPPED_ADDRESSES[chainId]),
		);

		let wrappedToken;
		if (wrappedTokenIndex) {
			wrappedToken = modifiedData[wrappedTokenIndex];
			modifiedData.splice(wrappedTokenIndex, 1);
		} else {
			wrappedToken = TOKEN_TEMPLATES[chainId];
		}
		merge(coin, wrappedToken);
		coin.image = `/tokens/${CHAIN_SYMBOLS[chainId]}.svg`;
	}

	return modifiedData;
};

const useTokenListMoralis = () => {
	const [moralisData, setMoralisData] = useState(getEmptyData());
	const moralisDataRef = useRef();
	const moralisHandle = useRef();
	const running = useRef(false);

	useEffect(() => {
		moralisDataRef.current = moralisData;
	}, [moralisData]);

	const handleData = async (type, data) => {
		if (!running.current) {
			return;
		}
		const chainId = moralisHandle.current?.getChainId();
		const address = moralisHandle.current?.getAddress();
		if (chainId && address) {
			if (type === 'update') {
				data = await getTokenListWithBalance(chainId, address);
			}
			if (type === 'new' || type === 'update') {
				const tokenaddresses = data
					.filter(t => !t.isCoin)
					.map(t => t.token_address);
				if (!tokenaddresses.includes(WRAPPED_ADDRESSES[chainId])) {
					tokenaddresses.unshift(WRAPPED_ADDRESSES[chainId]);
				}
				const promises = await Promise.all([
					getMarketInfo(chainId, tokenaddresses),
					getCurrencies(),
					getTokenList(chainId),
				]);
				const marketInfo = promises[0];
				const currencies = promises[1];
				const allDexTokens = promises[2];

				data = modifyData(chainId, data, marketInfo, currencies);
				data.forEach(token => {
					const matchedToken = allDexTokens.find(
						t =>
							addressMatch(t.address, token.address) ||
							(t.isCoin && token.isCoin) ||
							(token.isCoin &&
								addressMatch(WRAPPED_ADDRESSES[chainId], t.address)),
					);
					if (matchedToken) {
						merge(matchedToken, token);
					} else {
						allDexTokens.push(token);
					}
				});

				allDexTokens.sort(sortFuncRank);

				setMoralisData({
					data: allDexTokens,
					error: false,
					isLoading: false,
				});
			}
		}
	};

	const handleError = error => {
		setMoralisData({
			data: [],
			error,
			isLoading: false,
		});
	};

	const stopMoralis = hardStop => {
		running.current = false;
		if (moralisHandle.current) {
			moralisHandle.current.cancel();
		}
		if (hardStop) {
			moralisHandle.current = null;
		}
	};

	const start = (chainId, account) => {
		if (running.current) {
			stopMoralis();
		}
		running.current = true; // because stopMoralis had set it to false
		moralisHandle.current = startMoralis(
			chainId,
			account,
			handleData,
			handleError,
		);
	};

	const stop = () => {
		stopMoralis(true);
		setMoralisData(getEmptyData());
	};

	return {
		start,
		stop,
		isRunning: !!moralisHandle.current,
		setAddress: moralisHandle.current?.setAddress,
		setChainId: moralisHandle.current?.setChainId,
		data: moralisData.data,
		error: moralisData.error,
		isLoading: moralisData.isLoading,
	};
};

export default useTokenListMoralis;
